<template>
    <div class="auth">
        <Login/>
    </div>
</template>

<script>

import Login from "@/components/Auth/Login.vue";

export default {
    components: {
        Login,
    },
    setup() {

        return {

        }
    },

}
</script>

<style lang="scss" scoped>
@import "@/styles/views/auth.scss";
</style>
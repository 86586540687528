<template>
    <v-expansion-panels
        multiple
        v-model="expanded_nav[0]"
        class="box-expansion-panels box-expansion-panels-secondary"
    >
        <v-expansion-panel v-for="parent in data_route">

            <template v-if="parent.children.some(child => child.access)">
                <v-expansion-panel-title class="title-panel">{{ parent.name }}</v-expansion-panel-title>
                <v-expansion-panel-text v-if="parent.children.length>0">
                    <v-row>
                        <template v-for="children in parent.children">
                            <v-col cols="12" lg="4" v-if="children.active && children.access">
                                <router-link :to="children.path">
                                    <div class="dashboard-box p-3">
                                        <div class="title">{{ children.name }}</div>
                                    </div>
                                </router-link>
                            </v-col>
                        </template>
                    </v-row>
                </v-expansion-panel-text>

            </template>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
export default {
    name: "ExpandedNavFram",
    props: {
        data_route: Object,
        expanded_nav: Object,
    }
}
</script>

<template>
    <template v-if="loading">
        <Loading/>
    </template>
    <template v-if="!loading">
        <v-container>
            <v-row>
                <v-col cols="12">
                    <v-text-field
                        class="text-field-secondary"
                        v-model="search"
                        placeholder="¿Qué dashboard quieres buscar?"
                        @keyup.enter="searchDashboard"
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="loading_small">
                <v-col cols="12" class="text-center align-self-center">
                    <img :src="require('@/assets/img/loading.gif')"
                         alt="Loading" class="loading-gif">
                </v-col>
            </v-row>
            <div v-if="!loading_small">
                <span v-if="all_dashboard.length===0 && !not_dashboard" class="error-message">No se han encontrado datos en la búsqueda seleccionada</span>
                <v-row v-else>
                    <v-expansion-panels multiple v-model="expanded" class="box-expansion-panels">
                        <template v-for="(dashboards, key) in all_dashboard"
                                  :key="key">
                            <v-col cols="12" lg="6">
                                <v-expansion-panel class="title-panel" :title=key>
                                    <v-expansion-panel-text>
                                        <v-row>
                                            <template v-for="content_dashboard in dashboards"
                                                      :key=" dashboards.title">
                                                <v-col cols="12" lg="6">
                                                    <DashboardTable :content_dashboard="content_dashboard"
                                                                    v-if="!loading"></DashboardTable>
                                                </v-col>
                                            </template>
                                        </v-row>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-col>
                        </template>
                    </v-expansion-panels>
                </v-row>
            </div>
            <v-row>
                <v-col cols="12">
                    <ExpandedNavFram :data_route="data_route" :expanded_nav="expanded_nav"></ExpandedNavFram>
                </v-col>
            </v-row>
        </v-container>
    </template>
</template>

<script>

import {onBeforeMount, ref} from "vue";
import DashboardTable from "@/components/Dashboard/DashboardTable.vue";
import {getAllDashboardByUserWS} from "@/utils/dashboards";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import {systemRoute} from "@/router/routes_home";
import ExpandedNavFram from "@/components/Navigation/ExpandedNavFrame.vue";

export default {
    name: "DashboardListView",
    components: {
        ExpandedNavFram,
        Loading,
        DashboardTable,
    },

    setup() {
        let dashboards = ref([])
        let group_id = 14
        let loading = ref(false);
        let search = ref();
        let loading_small = ref(false);
        let all_dashboard = ref([])
        let read_all_dashboard;
        let expanded = ref([]);
        let expanded_nav = ref([0]);
        let not_dashboard = ref(false)
        let data_route = ref()

        onBeforeMount(async () => {
            await getAllDashboardByUser()
            data_route.value = systemRoute()
        })

        async function getAllDashboardByUser() {
            loading.value = true;
            not_dashboard.value = false
            let response = await getAllDashboardByUserWS()
            if (response){
                if (response['result']) {
                    if (variableIsDefined(response.data)){
                        read_all_dashboard = response.data
                        for (let num_dashboard = 0; num_dashboard < Object.keys(read_all_dashboard).length; num_dashboard++) {
                            expanded.value.push(num_dashboard)
                        }
                        all_dashboard.value = read_all_dashboard
                    }
                    else{
                        not_dashboard.value = true
                    }

                } else {
                    showNotification('error', 'No se han podido cargar los dashboard: ' + response['errors'])
                }
                loading.value = false
            }else{
                not_dashboard.value = true
            }



        }

        async function searchDashboard() {
            loading_small.value = true;
            let result = {};
            let dashboard_filter = [];
            let name_department;
            Object.entries(read_all_dashboard).forEach(department => {
                dashboard_filter = []
                name_department = department[0]
                department[1].forEach(dashboard => {
                    if (dashboard.title.toLowerCase().includes(search.value.toLowerCase())) {
                        dashboard_filter.push(dashboard)
                    }
                    if (dashboard_filter.length > 0) {
                        result[name_department] = dashboard_filter
                    }

                })
            })
            all_dashboard.value = result;
            await sleep(1000)
            loading_small.value = false;
        }


        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        return {
            searchDashboard,

            loading_small,
            dashboards,
            group_id,
            loading,
            search,
            all_dashboard,
            expanded,
            expanded_nav,
            not_dashboard,
            data_route,
        }
    }
}

</script>
<style lang="scss">
@import "@/styles/views/dashboard-list.scss";
@import "@/styles/components/dashboard-table.scss";
</style>


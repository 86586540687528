import {useDate} from "vuetify";

export function useCalendar() {
    const date_composable = useDate()

    //A PARTIR DE UNA FECHA EN FORMATO OBTIENE UN OBJETO CON SU AÑO, MES Y DIA
    const getDateComponents = (date, splitted_by_slash = false) => {
        let year
        let month
        let day
        let split_date

        if (splitted_by_slash){
            split_date = date.split('/')
            year = split_date[2]
            month = split_date[1]
            day = split_date[0]
        }else {
            split_date = date_composable.format(date, 'keyboardDate').split('/');

            year = split_date[2]
            month = split_date[0]
            day = split_date[1]
        }

        return {
            year,
            month,
            day,
        }
    }

    //DEVUELVE UN STRING EN FORMATO dd/mm/yyyy A PARTIR DE UNA FECHA
    function formatDefaultDateView(date, {split_by_slash = false,format = 'dd/mm/yyyy'} = {}) {
        const {day, month, year} = getDateComponents(date,split_by_slash)

        return format.replace('dd', day).replace('mm', month).replace('yyyy', year)
    }

    // COMPARA LAS DOS FECHAS PARA COMPROBAR SI LA PRIMERA ESTÁ ANTES QUE LA SEGUNDA
    // DEBEN VENIR CON FORMATO dd/mm/yyyy
    function comprobateDateIsBefore(date, comparing_date) {
        const [init_day, init_month, init_year] = date.split('/')
        const [final_day, final_month, final_year] =  comparing_date.split('/')

        if (init_year === final_year) {
            if(init_month === final_month){
                return init_day <= final_day
            }else return init_month < final_month
        } else return init_year < final_year
    }

    return {
        getDateComponents,
        comprobateDateIsBefore,
        formatDefaultDateView,
    }
}
<template>
    <BaseModalTemplate
        title="¿Quieres cambiar el nombre de este rol?"
        :open-close-dialog="displayDialog"
        body_extra_classes="modal-height-xs"
        card_extra_classes="w-75"
        :send-form="editRol"
    >
        <template #body-modal-content>
            <v-form @submit.prevent="editRol" class="form-config ">
                <v-col cols="12">
                    <v-text-field
                        v-model="rol_active.nombre"
                        @keyup.enter="editRol"
                        required
                        variant="underlined"
                        hide-details
                        class="input-error text-field-primary"
                    >
                    </v-text-field>
                </v-col>
            </v-form>
        </template>
    </BaseModalTemplate>
</template>
<script>

import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "EditRol",
    components: {BaseModalTemplate},

    props: {
        rol_active: Object,
        displayDialog: Function,
        editRol: {
            type: Function,
            required: true,
        },

    },
}
</script>
<style lang="scss">
.content-xs {
    min-height: 15vh !important;
}
</style>
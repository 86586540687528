<template>
    <span class="input-title mb-2">Filtros para las matrículas</span>
    <v-row>
        <v-col cols="12" sm="6" lg="3">
            <span class="title-text-field ms-1">Fecha Inicio *</span>
            <calendar
                ref="init_date"
                :accept_default_date="true"
                :default_date="default_date_view.today_less_7_days"
            ></calendar>
            <span v-if="form_error.init_date"
                  class="input-error msg-error">El campo no puede estar vacío</span>
            <span v-if="variableIsDefined(form_error.general_filter)"
                  class="input-error msg-error">{{ form_error.general_filter }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <span class="title-text-field ms-1">Fecha Final *</span>
            <calendar
                ref="final_date"
                :accept_default_date="true"
                :default_date="default_date_view.today"
            ></calendar>
            <span v-if="form_error.final_date"
                  class="input-error msg-error">El campo no puede estar vacío</span>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <span class="title-text-field ms-1">Email</span>
            <v-text-field
                v-model="filter_data.email"
                class="text-field-secondary"
                placeholder="Email"
                bg-color="white"
                color="transparent"
                type="email"
                hide-details
            ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <span class="title-text-field ms-1">ID Matrícula</span>
            <v-text-field
                v-model="filter_data.id_enrollment"
                class="text-field-secondary"
                placeholder="ID matrícula"
                bg-color="white"
                color="transparent"
                hide-details
            ></v-text-field>
            <span v-if="variableIsDefined(form_error.id_enrollment)"
                  class="input-error msg-error">{{ form_error.id_enrollment }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="3">
            <span class="title-text-field ms-1">Procedencia</span>
            <v-select
                class="select-secondary"
                aria-label="Select procedencia"
                :items="origin_options"
                v-model="filter_data.origin"
                hide-details
                bg-color="white"
                placeholder="Procedencia"
            >
            </v-select>
            <span v-if="variableIsDefined(form_error.origin)"
                  class="input-error msg-error">{{ form_error.origin }}</span>
        </v-col>
        <v-col cols="12" sm="6" lg="3" class="align-self-center mt-sm-3 mt-md-5">
            <v-checkbox hide-details
                        color="blue lighten-3"
                        v-model="filter_data.show_without_ffvv"
                        :label="'Matrículas sin FFVV'"
                        :true-value="1"
                        :false-value="0"
            ></v-checkbox>
        </v-col>
    </v-row>

    <v-row class="justify-content-end">
        <v-col cols="12" class="align-self-center text-end">
            <v-btn class="btn button-primary" @click="applyFilter" :disabled="disabled_btn_filter">
                BUSCAR
            </v-btn>
        </v-col>
    </v-row>
</template>

<script>
import Calendar from "@/components/Generic/Calendar.vue";
import * as Yup from "yup";
import {onBeforeMount, onBeforeUpdate, ref, watch} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {useCalendar} from "@/utils/Composables/useCalendar";
import {da} from "vuetify/locale";

export default {
    name: "FiltersGetEnrollment",
    methods: {variableIsDefined},
    components: {Calendar},
    props: {
        applyFilter: Function,
        disabled_btn_filter: Boolean,
        default_date: Object,
    },
    emits: (['validateFilter']),
    setup(props) {
        let filter_data = ref({
            origin: 'Privada'
        })
        let form_error = ref({})

        let init_date = ref(null) //COMPONENTE CALENDARIO FECHA-INICIO
        let final_date = ref(null) //COMPONENTE CALENDARIO FECHA-FINAL
        const origin_options = ref(['Cualquiera', 'Privada', 'Bonificada'])

        let correct_validate = ref(true)
        let regex_number = /^[0-9]+$/

        let default_date_view = ref({})

        const {formatDefaultDateView, comprobateDateIsBefore} = useCalendar()

        onBeforeMount(() => {
            default_date_view.value.today = formatDefaultDateView(props.default_date.final_date)
            default_date_view.value.today_less_7_days = formatDefaultDateView(props.default_date.init_date)
            filter_data.value.id_enrollment = props.default_date.id_enrollment
            filter_data.value.email = props.default_date.email
        })

        async function validateFilter() {
            correct_validate.value = true
            form_error.value = {}

            if (variableIsDefined(filter_data.value.id_enrollment)) {
                if (!regex_number.test(filter_data.value.id_enrollment)) {
                    correct_validate.value = false
                    form_error.value['id_enrollment'] = 'El ID Matrícula debe ser un número'
                }
            } else {
                delete filter_data.value.id_enrollment
            }

            filter_data.value['init_date'] = init_date.value.sendDate() ??  formatDefaultDateView(props.default_date.init_date);
            filter_data.value['final_date'] = final_date.value.sendDate() ??  formatDefaultDateView(props.default_date.final_date);

            if (correct_validate.value && !comprobateDateIsBefore(filter_data.value['init_date'], filter_data.value['final_date'])) {
                form_error.value['general_filter'] = 'La fecha de inicio no puede ser superior a la final'
                correct_validate.value = false
            }

            if (!variableIsDefined(filter_data.value.origin)) {
                form_error.value['origin'] = 'La procedencia debe tener un valor'
                correct_validate.value = false
            }

            if (!variableIsDefined(filter_data.value.email)) {
                delete filter_data.value.email
            }

            return {'validate': correct_validate.value, 'data_filters': filter_data.value}
        }

        return {
            validateFilter,

            default_date_view,
            init_date,
            final_date,
            filter_data,
            form_error,
            correct_validate,
            origin_options,
        }
    }
}
</script>


<style scoped lang="scss">
.title-text-field {
    font-size: 14px;
    font-weight: bold;
    color: $gray-medium-bold;
    display: block;
}
</style>
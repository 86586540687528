import store from "@/store";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";

export async function getDashboardWS(dashboard_id, params = {}) {
    let default_filters = null;
    //Primero recuperamos el alias del dashboard en base a su identificador:
    let response = await aicoreBackendPetition('GET', '/EmbedLooker/published_dashboards/'+ dashboard_id, {},{ext: true})
    if (response['result']){
        let url = "/EmbedLooker/get_embed_url?url=" +
            encodeURIComponent(response['data']['url']) + "&group_id=14&id_dashboard="+dashboard_id+"&id_usuario=" +
            store.getters.getUsername;
        if (variableIsDefined(response['data']['default_filters'])){
            default_filters = response['data']['default_filters'];
        }
        let params_finales = {...params, ...default_filters}
        
        if (Object.keys(params_finales).length > 0) {
            url += "&parametros=" + encodeURIComponent(JSON.stringify(params_finales))
        }
        try {
            return await aicoreBackendPetition('GET', url, {}, {ext: true});
        } catch (e) {
            showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /EmbedLooker/get_embed_url')
        }
    }
    return response;
}

export async function getAllDashboardByUserWS() {
    //let url = "/permissions/get_all_dashboard_por_usuario?username=" + store.getters.getUsername;
    let url = "/EmbedLooker/get_all_dashboard_por_usuario?username=" + store.getters.getUsername;
    try {
        return await aicoreBackendPetition('GET', url, {}, {ext: true});
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /EmbedLooker/get_all_dashboard_por_usuario')
    }

}
<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Creación automática video producto</span>
            </v-col>
        </v-row>
        <v-form @submit.prevent=null>
            <!--TITULO VIDEO-->
            <v-row>
                <v-col>
                    <v-card class="card-modal card-personalized">
                        <v-card-title>
                            <v-row>
                                <v-col cols="6">
                                    <span>TÍTULO VIDEO PRODUCTO *</span>
                                </v-col>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field
                                class="input-error text-field-secondary"
                                v-model="title_video"
                                placeholder="Inserte el título para el video..."
                                single-line
                                bg-color="white"
                                color="transparent"
                                hide-details
                                :error="error_title"
                            >

                            </v-text-field>
                            <span v-if="error_title"
                                  class="error-msg">El campo no puede estar vacío</span>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!--LOGO EMPRESA-->
            <FormCreateVideo
                title_modal="LOGO EMPRESA *"
                text_warning="Si no se especifica tiempo de inicio y fin, el logo se mostrará durante todo el video principal"
                type="Logo"
                ref="time_logos"
                text_info_url="Url del logo que se quiere mostrar en el video (el logo debe estar alojado en un CDN)"
                text_time_start="Marca de tiempo en la cuál el logo debe aparecer"
                text_time_finish="Marca del tiempo en la cuál el logo debe desaparecer"
            ></FormCreateVideo>

            <!--VIDEO INICIO -->
            <FormCreateVideo
                title_modal="VIDEO INICIAL (opcional)"
                text_warning="Si no se especifica tiempo de inicio y fin, el video se mostrará completo"
                type="video_inicio"
                ref="time_video_init"
                text_info_url="Url del video inicial (el video debe estar alojado en un CDN)"
                text_time_start="Inicio del video"
                text_time_finish="Final del video"
            ></FormCreateVideo>

            <!--VIDEO PRINCIPAL-->
            <FormCreateVideo
                title_modal="VIDEO PRINCIPAL*"
                text_warning="Si no se especifica tiempo de inicio y fin, el video se mostrará completo"
                type="video_principal"
                ref="time_video_main"
                text_info_url="Url del video principal (el video debe estar alojado en un CDN)"
                text_time_start="Inicio del video"
                text_time_finish="Final del video"
            ></FormCreateVideo>

            <!--VIDEO FINAL -->
            <FormCreateVideo
                title_modal="VIDEO FINAL*"
                text_warning="Si no se especifica tiempo de inicio y fin, el video se mostrará completo"
                type="video_final"
                ref="time_video_out"
                text_info_url="Url del video final (el video debe estar alojado en un CDN)"
                text_time_start="Inicio del video"
                text_time_finish="Final del video"
            ></FormCreateVideo>

            <!--FILTROS-->
            <CardFilter
                ref="configuration"></CardFilter>

            <v-row>
                <v-col cols="12" md="6"><span class="msg-info"> * Campos requeridos</span></v-col>
                <v-col cols="12" md="6" class="text-end my-3">
                    <v-btn @click="generateVideo" class="btn button-primary">GENERAR VIDEO</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>

    <v-dialog persistent
              v-model="open_modal">
        <ModalNewProductVideo
            :new_video="new_video"
            :displayModal="displayModal"
            :disabled_close_modal="disabled_close_modal"
            :title_video="title_video"
            :title="title_modal"
        ></ModalNewProductVideo>
    </v-dialog>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import FormCreateVideo from "@/components/ProductVideo/FormCreateVideo.vue";
import {createVideoProducto} from "@/utils/product_video";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import ModalNewProductVideo from "@/components/ProductVideo/ModalNewProductVideo.vue";
import Loading from "@/components/Generic/Loading.vue";
import CardFilter from "@/components/ProductVideo/CardFilter.vue";
import {useRoute} from "vue-router";
import {hasPermission} from "@/utils/auth";

export default {
    name: "ProductVideoView",
    components: {CardFilter, Loading, ModalNewProductVideo, FormCreateVideo},

    setup() {
        let title_video = ref("")
        let time_logos = ref(null)
        let time_video_main = ref(null)
        let time_video_out = ref(null)
        let time_video_init = ref(null)
        let configuration = ref(null)
        let error_title = ref(false)
        let open_modal = ref(false)
        let new_video = ref("")
        let disabled_close_modal = ref(false)
        let title_modal = ref('')
        let response = {}

        let logo
        let main_video
        let final_video
        let init_video
        let configuration_logo

        const route = useRoute();
        onBeforeMount(async () => {
            await hasPermission(route);
        })

        async function generateVideo() {
            response = {}
            title_modal.value = "Generando el nuevo video producto..."
            new_video.value = ""
            error_title.value = false
            logo = await time_logos.value.generateTime()
            main_video = await time_video_main.value.generateTime()
            final_video = await time_video_out.value.generateTime()
            init_video = await time_video_init.value.generateTime()
            configuration_logo = await configuration.value.generateConfiguration()

            if (logo && main_video && final_video && variableIsDefined(title_video.value) && init_video) {
                if (!variableIsDefined(init_video[1])) {
                    init_video = [null, null, null, null]
                }

                open_modal.value = true
                disabled_close_modal.value = true
                if (configuration_logo.active_background === false) {
                    configuration_logo.background_color = null
                }
                try {
                    response = await createVideoProducto(title_video.value, logo, main_video, final_video, init_video, configuration_logo)
                    if (response.result) {
                        title_modal.value = 'Nuevo video producto'
                        new_video.value = response['data']
                        disabled_close_modal.value = false

                    } else {
                        disabled_close_modal.value = false
                        open_modal.value = false
                        showNotification('error', response['errors'][0].mensaje)
                    }

                } catch (e) {
                    disabled_close_modal.value = false
                    open_modal.value = false
                    showNotification('error', 'Se ha producido un error interno')

                }

            } else {
                if (!variableIsDefined(title_video.value)) {
                    error_title.value = true
                }
                return false
            }
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }


        return {
            generateVideo,
            displayModal,

            title_video,
            time_logos,
            time_video_main,
            time_video_out,
            time_video_init,
            error_title,
            new_video,
            open_modal,
            disabled_close_modal,
            title_modal,
            configuration,
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/product-video.scss";
</style>
import store from "@/store";
import {showNotification} from "@/utils/aux_functions";
import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {escicBackendPetition} from "@/utils/escic_petition";

export async function getEnrollmentOutffvvWS(form_data) {
    let params = {
        fecha_inicial: form_data.init_date,
        fecha_final: form_data.final_date,
        email: form_data.email,
        id_matricula: form_data.id_enrollment,
        solo_matriculas_sin_ffvv: form_data.show_without_ffvv,
        usuario_logueado: store.getters.getUsername,
    }

    if (form_data.origin.toLowerCase() !== 'cualquiera'){
        params['procedencia'] = form_data.origin
    }

    try {
        return await aicoreBackendPetition('GET', '/matriculas/get_matriculas', params, {content_type: "application/json;",ext: true})

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/matriculas/get_matriculas');
    }
}

export async function getAssignableTechniciansWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    try {
        return await escicBackendPetition('POST', 'getAssignableTechnicians', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getAssignableTechnicians');
    }
}

export async function getSalesForceActivesWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    try {
        return await escicBackendPetition('POST', 'getSalesForceActives', {}, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getSalesForceActives');
    }
}

export async function updateEnrollmentWS(form_data) {
    let params = {
        'id_matricula': form_data.id_enrollment,
        'id_fuerza_ventas': form_data.id_fuerza_venta,
        'id_tecnico_que_matricula': form_data.id_inno,
        'comentarios': form_data.comments,
        'usuario': store.getters.getUsername,

    }
    /*
    if (form_data.id_fuerza_venta != null){
        params.id_fuerza_ventas = form_data.id_fuerza_venta
    }
    if (form_data.id_inno != null){
        params.id_tecnico_que_matricula = form_data.id_inno
    }
    if (form_data.comments != null){
        params.comentarios = form_data.comments
    }
    */
    if (process.env.NODE_ENV === 'development'){
        params.environment ='DEVELOPMENT'
    }
    try {
        return await aicoreBackendPetition('GET', '/matriculas/set_matricula_fuerza_venta', params, {content_type: "application/json;",ext: true})

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + '/matriculas/set_matricula_fuerza_venta');
    }
}
<template>
    <v-container class="container">
        <template v-if="loading">
            <Loading/>
        </template>

        <template v-else>
            <v-row class="row">
                <v-col cols="12">
                    <v-text-field
                        class="text-field-secondary"
                        v-model="search"
                        density="compact"
                        placeholder="¿Qué quieres buscar?"
                        @keyup.enter="searchRol"
                        append-inner-icon="mdi-magnify"
                        single-line
                        bg-color="white"
                        color="transparent"
                        hide-details
                    ></v-text-field>
                </v-col>
            </v-row>

            <!--  Maquetación PC -->
            <!--            <template class="d-none d-lg-block">-->
            <!--                <v-row class="row">-->
            <!--                    <div class="col-8">-->
            <!--                        <div class="title-list">Lista de grupos</div>-->
            <!--                        <div v-if="loading_small" class="loading-gif-xs">-->
            <!--                            <img :src="require('@/assets/img/loading.gif')"-->
            <!--                                 alt="Loading" class="loading-gif-xs">-->
            <!--                        </div>-->

            <!--                        <div v-if="!loading_small">-->
            <!--                            <span v-if="groups_ldap.length===0" class="error-message">No se han encontrado datos en la búsqueda seleccionada</span>-->
            <!--                            <v-list v-else class="container-groups-fix">-->
            <!--                                <v-list-item-->
            <!--                                    class="list-groups"-->
            <!--                                    v-for="group in groups_ldap"-->
            <!--                                    :key="group.id_grupo_ldap"-->
            <!--                                    :title="group.nombre_grupo"-->
            <!--                                    :value="group.nombre_grupo"-->
            <!--                                    active-color="blue darken-1"-->
            <!--                                    @click="getAllRolesGroupLDAP(group)">-->
            <!--                                    <i class="fa-solid fa-chevron-right"></i>-->
            <!--                                </v-list-item>-->
            <!--                            </v-list>-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                    <div class="col-4 sidebar" v-if="title_group_selected">-->
            <!--                        <div class="title-list">Lista de roles</div>-->
            <!--                        <div class="container-roles">-->
            <!--                            <div class="title-groups-selected">{{ title_group_selected }}</div>-->
            <!--                            <v-checkbox hide-details-->
            <!--                                        v-for="rol_group in roles_group_selected"-->
            <!--                                        color="blue lighten-3"-->
            <!--                                        v-model="rol_group.activo"-->
            <!--                                        :label="rol_group.nombre"-->
            <!--                                        :true-value="1"-->
            <!--                                        :false-value="0"-->
            <!--                            ></v-checkbox>-->
            <!--                        </div>-->
            <!--                        <v-btn-->
            <!--                            class="save-roles-group bg-custom-gradient"-->
            <!--                            variant="flat"-->
            <!--                            @click="saveListRol"-->
            <!--                        > Guardar-->
            <!--                        </v-btn>-->
            <!--                    </div>-->
            <!--                </v-row>-->
            <!--            </template>-->

            <template class="d-none d-lg-block">
                <v-row>
                    <v-col cols="12" lg="8">
                        <div class="title-list">Lista de grupos</div>
                        <div v-if="loading_small" class="loading-gif-xs">
                            <img :src="require('@/assets/img/loading.gif')"
                                 alt="Loading" class="loading-gif-xs">
                        </div>

                        <div v-if="!loading_small">
                            <span v-if="groups_ldap.length===0" class="error-message">No se han encontrado datos en la búsqueda seleccionada</span>
                            <v-list v-else class="container-groups-fix">
                                <v-list-item
                                    class="list-groups"
                                    v-for="group in groups_ldap"
                                    :key="group.id_grupo_ldap"
                                    :title="group.nombre_grupo"
                                    :value="group.nombre_grupo"
                                    color="blue darken-1"
                                    @click="getAllRolesGroupLDAP(group)">
                                    <i class="fa-solid fa-chevron-right"></i>
                                </v-list-item>
                            </v-list>
                        </div>
                    </v-col>
                    <v-col cols="4" class="sidebar" v-if="title_group_selected">
                        <div class="title-list">Lista de roles</div>
                        <v-card class="list_roles">
                            <v-card-title class="roles-card-title">
                                <span> {{ title_group_selected }}</span>
                            </v-card-title>
                            <v-divider class="m-0"></v-divider>
                            <v-card-text class="container-roles personalize-scroll">
                                <v-checkbox hide-details
                                            v-for="rol_group in roles_group_selected"
                                            color="blue lighten-3"
                                            v-model="rol_group.activo"
                                            :label="rol_group.nombre"
                                            :true-value="1"
                                            :false-value="0"
                                ></v-checkbox>
                            </v-card-text>
                            <v-divider class="m-0"></v-divider>
                            <v-card-actions class="roles-card-actions">
                                <v-btn
                                    class="btn button-primary"
                                    variant="flat"
                                    @click="saveListRol"
                                > Guardar
                                </v-btn>

                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <!--Maquetación tablet -->
            <template class="d-block d-lg-none">
                <v-row class="row">
                    <v-col cols="12">
                        <div class="title-list">Lista de grupos</div>
                        <div v-if="loading_small" class="loading-gif-xs">
                            <img :src="require('@/assets/img/loading.gif')"
                                 alt="Loading" class="loading-gif-xs">
                        </div>

                        <div class="row" v-if="!loading_small">
                            <span v-if="groups_ldap.length===0" class="error-message">No se han encontrado datos con el filtro seleccionado</span>
                            <v-expansion-panels v-else>
                                <v-expansion-panel v-for="group in groups_ldap">
                                    <v-expansion-panel-title @click="getAllRolesGroupLDAP(group)">
                                        {{ group.nombre_grupo }}
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <v-checkbox hide-details
                                                    v-for="rol_group in roles_group_selected"
                                                    color="blue lighten-3"
                                                    v-model="rol_group.activo"
                                                    :label="rol_group.nombre"
                                                    :true-value="1"
                                                    :false-value="0"
                                        ></v-checkbox>
                                        <v-btn
                                            class="save-roles-group btn button-primary"
                                            variant="flat"
                                            @click="saveListRol"
                                        > Guardar
                                        </v-btn>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </template>
    </v-container>
</template>


<script>
import {onBeforeMount, ref} from "vue";
import {
    saveRolesGroupWS,
    getAllGroupsLDAPWS,
    getAllRolesGroupLDAPWS,
} from "@/utils/admin/manager_permission";
import {showNotification, sleep} from "@/utils/aux_functions";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerGroupsRolesView",
    components: {Loading},

    setup: function () {
        const route = useRoute();
        /*Variables*/
        let search = ref();
        let loading = ref();
        let loading_small = ref(false);
        let groups_ldap = ref([]);
        let roles_group_selected = ref([]);
        let title_group_selected = ref("");
        let group_selected = [];
        let groups_ldap_read;
        let displayed = false;
        let id_group_selected;

        onBeforeMount(async () => {
            await hasPermission(route);
            loading.value = true;
            const response = await getAllGroupsLDAPWS();
            if (response['result']) {
                loading.value = false;
                groups_ldap.value = response['data']
                groups_ldap_read = groups_ldap.value;

            } else {
                showNotification("error", "Ha ocurrido un error al obtener los dashboards.")
            }
            loading.value = false
        });


        async function getAllRolesGroupLDAP(grupo) {
            if (!displayed || id_group_selected !== grupo.id_grupo_ldap) {
                id_group_selected = grupo.id_grupo_ldap
                group_selected = grupo;
                title_group_selected.value = grupo.nombre_grupo;
                const response = await getAllRolesGroupLDAPWS(grupo.id_grupo_ldap);
                if (response['result']) {
                    roles_group_selected.value = response['data']

                } else {
                    showNotification("error", "Ha ocurrido un error al obtener los dashboards.")
                }
            } else {
                id_group_selected = null
            }
            displayed = !displayed
        }

        async function saveListRol() {
            let roles_finales = [];
            roles_group_selected.value.forEach(rol => {
                let rol_object = {}
                rol_object.id_rol = rol.id_rol;
                rol_object.activo = rol.activo;
                roles_finales.push(rol_object)
            })
            let response = await saveRolesGroupWS(parseInt(group_selected.id_grupo_ldap), roles_finales)
            if (response['result']) {
                showNotification("success", "Se han modificado los roles correctamente")
                await getAllRolesGroupLDAP(group_selected);
            } else {
                showNotification("error", "Ha ocurrido un error al guardar.")
                await getAllRolesGroupLDAP(group_selected);
            }
        }

        async function searchRol() {
            loading_small.value = true
            let result = [];
            groups_ldap_read.forEach(grupo => {
                if (grupo['nombre_grupo'].toLowerCase().includes(search.value.toLowerCase())) {
                    result.push(grupo)
                }
            })
            await sleep(1000)
            groups_ldap.value = result
            loading_small.value = false
        }

        return {
            searchRol,
            getAllRolesGroupLDAP,
            saveListRol,
            search,
            loading,
            loading_small,
            roles_group_selected,
            groups_ldap,
            title_group_selected,
        }
    },
}


</script>


<style lang="scss">
@import "@/styles/views/manager-groups-roles.scss";
</style>
<template>
    <v-container class="container-manager">
        <template v-if="loading">
            <Loading/>
        </template>
        <template v-if="!loading">
            <v-row>
                <v-col>
                    <v-text-field
                            class="text-field-secondary"
                            v-model="search"
                            density="compact"
                            placeholder="¿Qué quieres buscar?"
                            @keyup.enter="searchRol"
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" s="12" xs="12" md="12" lg="7" class="rol-container">
                    <div class="rol-title">Lista de roles</div>
                    <template v-if="loading_small">
                        <img :src="require('@/assets/img/loading.gif')"
                             alt="Loading" class="loading-gif-xs">
                    </template>
                    <template v-if="!loading_small">
                        <span v-if="roles.length===0" class="error-message">No se han encontrado datos en la búsqueda seleccionada</span>
                        <v-table class="table-rol personalize-scroll" v-else>
                            <tbody>
                            <tr v-for="(rol, index) in roles" @click="captureRol(rol.id_rol,rol.nombre)">
                                <td class="rol" :class="[rol.id_rol === rol_active.id ? 'rol-active' : '']">
                                    {{ rol.nombre }} <i class="fa-solid fa-chevron-right"></i></td>
                            </tr>
                            </tbody>
                        </v-table>
                    </template>
                </v-col>
                <v-col cols="12"  s="12" xs="12" md="12" lg="5" class="rol-sidebar">
                    <div class="rol-title">Menú de ajustes</div>
                    <!-- editar section -->
                    <template v-if="rol_active.id">
                        <div class="container-show-rol">{{ rol_active.nombre }}</div>
                        <div class="container-edit">
                            <p>¿Quieres editar este rol?</p>
                            <v-btn class="btn button-bg-primary-color" @click="displayDialog">
                                Editar
                            </v-btn>
                        </div>
                    </template>
                    <v-form action="" class="text-center form-add" @submit.prevent="addRol">
                        <v-text-field
                                v-model="data_new_rol.nombre_rol"
                                @keyup.enter="addRol"
                                class="text-field-secondary"
                                placeholder="+ Nuevo Rol"
                                hide-details
                                bg-color="white"
                                color="transparent"
                        >
                        </v-text-field>
                        <v-btn type="submit" class="btn button-primary btn-add">Añadir</v-btn>
                    </v-form>
                </v-col>
            </v-row>
        </template>
    </v-container>
    <v-dialog v-model="dialog">
        <EditRol
            :displayDialog="displayDialog"
            :rol_active="rol_active"
            :edit-rol="editRol"
        ></EditRol>
    </v-dialog>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getAllRolesWS, addRolWS, saveRolWS} from "@/utils/admin/manager_permission";
import {showNotification, sleep, variableIsDefined} from "@/utils/aux_functions";
import {hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import Loading from "@/components/Generic/Loading.vue";
import EditRol from "@/components/Roles/EditRol.vue";


export default {
    name: "ManagerRolesView",
    components: {EditRol, Loading},
    setup: function () {
        /*Variables*/
        let roles = ref([]);
        let data_new_rol = ref({});
        let loading = ref();
        let rol_active = ref({});
        let search = ref();
        let loading_small = ref(false);
        let original_roles;
        const route = useRoute();

        let dialog = ref(false);

        onBeforeMount(async () => {
            await hasPermission(route);
            await getAllRoles();
        })

        /* para coger todos los roles */
        async function getAllRoles() {
            loading.value = true;

            const response = await getAllRolesWS();
            if (response['result']) {
                original_roles = response['data'];
                roles.value = original_roles
            } else {
            }
            loading.value = false;
        }

        /* para subir nuevo rol */
        async function addRol() {
            if (variableIsDefined(data_new_rol.value.nombre_rol)) {
                const response = await addRolWS(data_new_rol.value.nombre_rol);
                roles.value = await getAllRoles();
                showNotification("success", "El rol se ha añadido correctamente")
                data_new_rol.value = {}

            } else {
                showNotification("error", "Se ha producido un error al añadir el rol.")
            }
        }

        /* para capturar rol pinchado en variable */
        function captureRol(id_rol, nombre_rol) {
            rol_active.value.id = id_rol;
            rol_active.value.nombre = nombre_rol;
        }

        /* editar rol */
        async function editRol() {
            if (rol_active.value.nombre !== '') {
                const response = await saveRolWS(rol_active.value.id, rol_active.value.nombre);
                if (response['result']) {
                    roles.value = getAllRoles();
                    showNotification("success", "El rol se ha editado correctamente.")
                } else {
                    showNotification("error", "Se ha producido un error al editar el rol.")
                }
                rol_active.value = {}
            }

        }

        /* buscador de roles */
        async function searchRol() {
            loading_small.value = true
            let result = [];
            original_roles.forEach(rol => {
                if (rol['nombre'].toLowerCase().includes(search.value.toLowerCase())) {
                    result.push(rol)
                }
            })
            await sleep(1000)
            roles.value = result;
            loading_small.value = false
        }

        function displayDialog() {
            dialog.value = !dialog.value;
        }

        return {
            search,
            roles,
            rol_active,
            data_new_rol,
            loading,
            loading_small,
            dialog,
            
            addRol,
            editRol,
            captureRol,
            searchRol,
            displayDialog,

        }

    },
}


</script>

<style lang="scss">
@import "@/styles/views/manager-roles.scss";
</style>


<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="9">
        <h1 class="mb-5 title">Gestión tareas de AITRENDS</h1>
      </v-col>
    </v-row>
    <v-row align="center" cols="12">
      <div class="input-group mb-3" style="justify-content:center;">
        <span class="input-group-text">Seleccionar Rol</span>
        <select v-model="selectedRol" class='btn button-primary w-50' id="rolesID">
          <option disabled value="">Seleccione un rol</option>
          <option class="text-dark" v-for="rol in idRoles" :key="rol">{{ rol['nombre'] }}</option>
        </select>
      </div>
    </v-row>
  </v-container>
  <div v-if="!initialRequest" >
    <Loading/>
  </div>
  <v-container v-else>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="9">
        <h2 class="mb-5 title">Tareas Prioritarias</h2>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selectedTasks"
      :headers="tasksColumns"
      :items="priorityTasksData"
      item-value="name"
      items-per-page="10"
      class="elevation-1 mb-8"
      return-object
      show-select
    >
        <template v-slot:item.data="{ value }">
            {{ getFormattedValues(value)  }}
        </template>
    </v-data-table>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="9">
        <h2 class="mb-5 title">Tareas Restantes</h2>
      </v-col>
    </v-row>
    <v-data-table
      v-model="selectedTasks"
      :headers="tasksColumns"
      :items="tasksData"
      item-value="name"
      items-per-page="10"
      class="elevation-1"
      return-object
      show-select
    >
        <template v-slot:item.data="{ value }">
            {{ getFormattedValues(value)  }}
        </template>
    </v-data-table>
    <v-row cols="12">
      <v-col v-if="selectedTasks.length == 1 && !variableIsDefined(selectedTasks[0]['url'])">
        <v-btn elevation="4" size="x-large" class="btn button-primary w-100 mb-8 mt-8" @click="setDisplayUrlDialog">Asignar URL</v-btn>
      </v-col>
      <v-col v-if="selectedTasks.length > 0">
        <v-btn elevation="4" size="x-large" class="btn button-primary w-100 mb-8 mt-8" @click="closeTasks">Cerrar Tarea</v-btn>
      </v-col>
    </v-row>
  </v-container>
  <v-dialog v-model="displayUrlDialog">
  <AssignUrlDialog
    :urlType="urlType"
    :displayDialog="toggleDisplayUrlDialog"
    :callbackFunction="assignUrl"
  />
  </v-dialog>
</template>

<script>
import {computed, onBeforeMount, watch, ref} from "vue";
import {useStore} from "vuex";
import {getAicoreToken} from "@/utils/auth";
import axios from "axios";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import AssignUrlDialog from "@/components/SearchEngine/AssignUrlDialog.vue";

export default {
  name: "TasksAITrends",
  methods: {variableIsDefined},
  components: {Loading, AssignUrlDialog},
  setup() {
    const store = useStore();
    const userName = computed(() => store.getters.getUsername).value
    const userGroup = store.getters.getUserGroup.split(',');

    let initialRequest = ref(false);
    let selected
    let idRoles = ref([]);
    let selectedRol = ref();
    let idRol = ref(null);
    watch(selectedRol, async() => {
      idRol.value = idRoles.value.find(item => item.nombre === selectedRol.value).id_rol;
      loadTasks();
    })
    let selectedTasks = ref([]);
    let priorityTasksData = ref([]);
    let tasksData = ref([]);
    let tasksColumns = [
        {
          'title': 'Keywords',
          'align': 'left',
          'key': 'data'
        },
      {
        'title': 'Rol',
        'align': 'left',
        'key': 'rol' },
        {
          'title': 'Portal',
          'align': 'left',
          'key': 'url_portal'
        },
        {
          'title': 'Url',
          'align': 'left',
          'key': 'url'
        },
        {
          'title': 'Idioma',
          'align': 'left',
          'key': 'idioma'
        },
        {
          'title': 'Fecha Creación',
          'align': 'left',
          'key': 'fecha_creacion'
        },
        {
          'title': 'Username',
          'align': 'left',
          'key': 'username'
        },
        {
          'title': 'Prioridad',
          'align': 'center',
          'key': 'prioridad'
        }
    ]


    let displayUrlDialog = ref(false);
    let urlType = ref("blogs");
    const toggleDisplayUrlDialog = () => {
      displayUrlDialog.value = !displayUrlDialog.value
    }
	const setDisplayUrlDialog = () => {
	  if(selectedTasks.value.length != 1){ return; }
      urlType.value = selectedTasks.value[0]['id_rol'] == 1 ? 'courses' : 'blogs';
	  toggleDisplayUrlDialog()
	}


    async function getToken() {
      // Esta función nos permite obtener el token de autentificación de AICORE
      let tokenAicore = null;
      try {
        tokenAicore = await getAicoreToken(false);
        tokenAicore = tokenAicore['access_token'];
      } catch (e){
        showNotification('error', 'Error al autentificarse con el servidor de aicore');
        return null;
      }
      return tokenAicore;
    }

    onBeforeMount(async () => {
      initialRequest.value = false;

      let tokenAicore = await getToken();
      let failed = false;
      await axios({
        method: 'POST',
        url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/listar_roles_grupos",
        data: {
          "include_grupo": userGroup
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenAicore,
          "accept": 'application/json'
        },
      }).then(
      function (response) {
        if (response['data'] && response['data']['result'] == true) {
          idRoles.value = response['data']['data'];
          const index = idRoles.value.findIndex(item => item.id_rol === 4);
          if(index !== -1){
            selectedRol.value = idRoles.value[index]['nombre'];
          }else{
            selectedRol.value = idRoles.value[0]['nombre'];
          }
        } else {
          showNotification('error', 'No hemos podido detectar el rol al que perteneces ' + response['errors'])
          failed = true;
        }
      })
      .catch(function (error) {
        showNotification('error', 'Error al hacer la petición para obtener su rol al servidor. ' + error.message)
      });
      if(!failed){
        await loadTasks();
      }
      initialRequest.value = true;
    })

    async function loadTasks(){
      if(!variableIsDefined(idRol.value)){return;}
      initialRequest.value = false;
      let tokenAicore = await getToken();
      tasksData.value = [];
      priorityTasksData.value = [];
      selectedTasks.value = [];
      let url_filter = ""
      if (idRol.value != 4){
        url_filter = "&username=" + userName + "&id_rol="  + idRol.value
      }
      await axios({
        method: 'GET',
        url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/listar_tareas?id_estado=1&limit=25" + url_filter,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenAicore,
          "accept": 'application/json'
        },
      }).then(
      function (response) {
        if (response['data'] && response['data']['result'] == true) {
          priorityTasksData.value = [];
          tasksData.value = [];
          response['data']['data'].forEach(item =>{
            if (item['prioritaria'] === 1){
              priorityTasksData.value.push(item);
            }else{
              tasksData.value.push(item);
            }
          });
        } else {
          showNotification('error', 'No hemos podido obtener correctamente las tareas del servidor - Error: ' + response['data']['errors'])
        }
      })
      .catch(function (error) {
        showNotification('error', 'Error al hacer la petición para listar las tareas. ' + error.message)
      });
      initialRequest.value = true;
    }

    async function closeTasks(){
      if (selectedTasks.value.length == 0){ return; }
      initialRequest.value = false;
      let tokenAicore = await getToken();
      selectedTasks.value.forEach(item => {
        let url = item['url'];
        if (!variableIsDefined(url)){
          showNotification('error', 'No hemos cerrado la tarea: ' + item['id_tendencia'] + ' porque le falta asociar una url manualmente.');
          return;
        }
        let identifier = {'id_url': item['id_url']}
        if (!variableIsDefined(item['id_url'])){
          identifier = {
            'url': item['url']
          }
        }
        axios({
          method: 'POST',
          url: process.env.VUE_APP_AICORE_URL_API_NLP + "/Trends/cambiar_tarea",
          data: {
            'id_tendencia': item['id_tendencia'],
            'id_rol': item['id_rol'],
            'id_estado': 3,
            'username': item['username'],
            'id_portal': item['id_portal'],
            'id_idioma': item['id_idioma'],
            ...identifier,
          },
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + tokenAicore,
            "accept": 'application/json'
          },
        }).then(
        function (response) {
          if (response['data'] && response['data']['result'] == true) {
            showNotification('success', 'Cerrado la tarea de la tendencia: ' + item['id_tendencia']);
          } else {
            showNotification('error', 'No hemos podido cerrar la tarea: ' + item['id_tendencia'] + ' - ' + response['data']['errors'])
          }
        })
        .catch(function (error) {
          showNotification('error', 'Error al hacer la petición para cambiar el estado de la tarea' + error.message)
        });
      });
      await loadTasks();
      initialRequest.value = true;
    }

    function assignUrl(url, id_url){
      if (selectedTasks.value.length != 1){ return; }
      if (!variableIsDefined(url)){ return; }
      if (!variableIsDefined(id_url)){ return; }
      let id_tendencia = selectedTasks.value[0]['id_tendencia']
      const index = tasksData.value.findIndex(item => item.id_tendencia === id_tendencia);
      if (index !== -1){
        tasksData.value[index].url = url;
        tasksData.value[index].id_url = id_url;
      }
      const priorityIndex = priorityTasksData.value.findIndex(item => item.id_tendencia === id_tendencia);
      if (priorityIndex !== -1){
        priorityTasksData.value[priorityIndex].url = url;
        priorityTasksData.value[priorityIndex].id_url = id_url;
      }
      selectedTasks.value = [];
    }

    function getFormattedValues(jsonString) {
      return JSON.parse(jsonString).map(obj => obj.keyword).join(', ');
    }

    return {
      userGroup,
      userName,
      idRoles,
      selectedRol,
      idRol,
      initialRequest,
      selectedTasks,
      tasksColumns,
      priorityTasksData,
      tasksData,

      closeTasks,
      loadTasks,
      assignUrl,
      displayUrlDialog,
      toggleDisplayUrlDialog,
      setDisplayUrlDialog,
      urlType,
      getFormattedValues,
    }
  }
}
</script>

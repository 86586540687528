<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Generación de HTML de traducciones</span>
            </v-col>
            <v-col cols="12" sm="4">
                <span class="input-title">Idiomas disponibles</span>
                <v-select
                    class="select-secondary"
                    :items="available_languages"
                    v-model="selected_language"
                    @update:modelValue="loadTypeElements"
                    item-title="nombre"
                    item-value="id_idioma"
                    hide-details
                    bg-color="white"
                    placeholder="Idioma"
                    no-data-text="No hay datos disponibles"
                    :disabled="loading"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <span class="input-title">Tipos de elementos</span>
                <v-select
                    class="select-secondary"
                    :items="available_type_elements"
                    v-model="unit_type"
                    @update:modelValue="loadCodesByType"
                    hide-details
                    bg-color="white"
                    placeholder="Tipos de elementos"
                    no-data-text="No hay datos disponibles"
                    :disabled="loading || !variableIsDefined(selected_language)"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="4">
                <span class="input-title">Elementos disponibles</span>
                <v-autocomplete
                    class="select-secondary"
                    :items="available_translated_elements"
                    item-title="display_text"
                    item-value="code"
                    :custom-filter="autocompleteCustomFilter"
                    v-model="unit_code"
                    @update:modelValue="loadElementChildren"
                    hide-details
                    bg-color="white"
                    placeholder="Elementos disponibles"
                    no-data-text="No hay datos disponibles"
                    :disabled="loading || !variableIsDefined(unit_type)"
                >
                </v-autocomplete>
            </v-col>

            <template v-if="show_btn_and_select">
                <v-col cols="12" sm="4">
                    <span class="input-title">Elementos hijos</span>
                    <v-autocomplete
                        class="select-secondary"
                        :items="list_element_found_children"
                        :custom-filter="autocompleteCustomFilter"
                        v-model="selected_child"
                        @update:modelValue="loadSingleElementContent"
                        item-title="display_text"
                        return-object
                        hide-details
                        bg-color="white"
                        placeholder="Elementos disponibles"
                        no-data-text="No hay datos disponibles"
                        :disabled="loading || !variableIsDefined(unit_type)"
                    >
                        <template v-slot:selection="{props, item }">
                            <span>{{item.value }}</span>
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="4" class="align-self-center mt-10">
                    <v-btn
                        class="btn button-primary"
                        @click="loadAllElementsContent"
                    >
                        MOSTRAR TODOS
                    </v-btn>
                </v-col>
            </template>

            <template v-for="content in tranlasted_html_content">
                <v-col cols="12">
                    <span class="input-title">{{content['unit_type']}}{{content['unit_code']}}</span>
                </v-col>
                <v-col cols="12">
                    <div
                        class="html-translated-container"
                        v-html="content['data']"
                    ></div>
                </v-col>
            </template>
            <template v-if="loading">
                <Loading/>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import {onBeforeMount, onMounted, ref} from "vue";
import {manageWSResponses, variableIsDefined} from "@/utils/aux_functions";
import {
    getBaseLanguagesWS, getElementChildWS,
    getHTMLContentWS, loadElementsTypeWS,
    translatedContentByTypeWS
} from "@/utils/html_traduction";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "HTMLTraductionView",
    components: {Loading},
    methods: {variableIsDefined},
    setup(){
        const loading = ref(false)

        /*Elementos disponibles con hijos*/
        const available_type_elements = ref()
        const unit_type = ref()

        /*Elementos traducidos que coinciden con el tipo "unit_type"*/
        const available_translated_elements = ref()
        const unit_code = ref()

        /*Elementos hijo del elemento obtenido a partir del "unit_type" y "unit_code" seleccionado*/
        const unit_type_code_list = ref()
        const list_element_found_children = ref([])
        const selected_child = ref()

        /*ARRAY CON EL HTML QUE SE VA A MOSTRAR*/
        const tranlasted_html_content = ref([])

        const available_languages = ref([])
        const selected_language = ref()

        const show_btn_and_select = ref(false)

        onBeforeMount(async ()=> {
            await loadAvailableLanguages()
        })

        /*Carga los lenguajes base de aiseo*/
        const loadAvailableLanguages = async () => {
            loading.value = true

            const response = await manageWSResponses(getBaseLanguagesWS)
            if (response){
                Object.entries(response['data']).map(
                    language =>
                        available_languages.value.push({nombre: language[0], id_idioma: language[1]})
                )
            }

            loading.value = false
        }

        /*Carga los distintos tipos que pueden tener los elementos [HTM, AFO, EVA ...]*/
        const loadTypeElements = async () => {
            loading.value = true
            clearSelectsAndHTMLData()

            const response = await manageWSResponses(loadElementsTypeWS, [selected_language.value])
            response && (available_type_elements.value = response['data'])

            loading.value = false
        }


        /*
        * Carga los distintos tipos de elementos y su contenido
        * Del objeto obtenido aquí se podrá sacar los contenidos
        * cuando se quieran ver los HTML de forma individual
        *
        * */
        const loadCodesByType = async () => {
            loading.value = true
            unit_code.value = null
            tranlasted_html_content.value = []
            show_btn_and_select.value = false
            list_element_found_children.value = []
            selected_child.value = null

            const response = await manageWSResponses(translatedContentByTypeWS, [selected_language.value, unit_type.value])

            if (response){
                available_translated_elements.value = response['data'].map((element) => { return {code:element, display_text: `${unit_type.value}${element}` } })
            }

            loading.value = false
        }

        /*Carga los hijos de un elemento*/
        const loadElementChildren = async () => {
            if (variableIsDefined(unit_code.value)){
                loading.value = true
                show_btn_and_select.value = false
                list_element_found_children.value = []
                selected_child.value = null
                tranlasted_html_content.value = []

                const response = await manageWSResponses(getElementChildWS, [selected_language.value, unit_type.value, unit_code.value])

                if (response){
                    unit_type_code_list.value = response['data']
                    getFoundChildren(unit_type_code_list.value['founds'], unit_code.value)
                }

                loading.value = false
            }
        }

        /*OBTIENE UN ARRAY CON EL TIPO Y EL ID DE TODOS LOS HIJOS VÁLIDOS QUE TENGA EL ELEMENTO*/
        const getFoundChildren = (found, parent_id) => {
            Object.entries(found).forEach((entry) => {
                if (entry[1]['count'] > 0){
                    Object.entries(entry[1]['codes']).map((code_entry) => {
                        if (variableIsDefined(code_entry[1]) && (entry[0] !== 'UDI' && entry[0] !== 'MOD')){
                            list_element_found_children.value.push(
                                {type: entry[0], code: code_entry[0], data: code_entry[1], display_text: `${entry[0]}${code_entry[0]}`}
                            )
                        }
                    })
                }
            })

            if (list_element_found_children.value.length === 1 && list_element_found_children.value[0]['code'] === parent_id){
                tranlasted_html_content.value.push(
                    {
                        data: list_element_found_children.value[0]['data'], 
                        unit_code: list_element_found_children.value[0]['code'], 
                        unit_type: list_element_found_children.value[0]['type']
                    })
            }else{
                show_btn_and_select.value = true
            }
        }

        /*CARGA EL CONTENIDO DE UN ELEMENTO SELECCIONADO*/
        const loadSingleElementContent = async () => {
            if (variableIsDefined(selected_child.value)){
                loading.value = true
                tranlasted_html_content.value = []
                const response = await manageWSResponses(getHTMLContentWS, [selected_language.value, selected_child.value['type'], selected_child.value['code']])

                if (response){
                    tranlasted_html_content.value.push({data: response['data'], unit_code: selected_child.value['code'], unit_type: selected_child.value['type']})
                }

                loading.value = false
            }
        }

        /*MUESTRA EL CONTENIDO DE TODOS LOS ELEMENTOS HIJOS*/
        const loadAllElementsContent = () => {
            tranlasted_html_content.value = []
            list_element_found_children.value.map(
                child =>
                    tranlasted_html_content.value.push({data: child['data'], unit_code: child['code'], unit_type: child['type']})
            )
        }

        const clearSelectsAndHTMLData = () => {
            show_btn_and_select.value = false
            list_element_found_children.value = []
            selected_child.value = null
            tranlasted_html_content.value = []
            unit_code.value = null
            unit_type.value = null
        }

        const autocompleteCustomFilter = (itemTitle, queryText, item) => {
            if (!queryText) return true

            let text_to_search = ""

            if (variableIsDefined(item.value['type'])){
                text_to_search = `${item.value['type']}${item.value['code']}`.toLowerCase()
            }else{
                text_to_search = `${unit_type.value}${item.value}`.toLowerCase()
            }

            return text_to_search.indexOf(queryText.toLowerCase()) > -1
        }

        return{
            autocompleteCustomFilter,
            loadTypeElements,
            loadCodesByType,
            loadElementChildren,
            loadSingleElementContent,
            loadAllElementsContent,

            unit_code,
            loading,
            available_languages,
            available_translated_elements,
            selected_language,
            available_type_elements,
            unit_type,
            tranlasted_html_content,
            show_btn_and_select,
            list_element_found_children,
            selected_child,
        }
    }
}
</script>

<style lang="scss">

.html-translated-container{
    background-color: $white;
    border: 1px solid $primary-color-1;
    border-radius: 8px;
    padding: 10px;
}

</style>
<template>
        <v-container>
            <v-row>
                <v-col cols="12" class="section-title">
                    <span class="main-title-page">Incongruencias en facturación</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="5" md="4">
                    <span>Fecha de inicio</span>
                    <calendar
                        ref="start_date_calendar"
                        :disabled_calendar="loading_table"
                    ></calendar>
                    <span v-if="form_error.start" class="input-error msg-error">{{ form_error.msg }}</span>
                </v-col>
                <v-col cols="12" sm="5" md="4">
                    <span>Fecha de finalización</span>
                    <calendar
                        ref="final_date_calendar"
                        :disabled_calendar="loading_table"
                    ></calendar>
                    <span v-if="form_error.final" class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12" sm="2" md="4" class="align-self-center text-center text-md-start mt-sm-5">
                    <v-btn class="btn button-primary" :disabled="show_table" @click="searchDates">Filtrar</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!see_table">
                <v-col>
                    <span class="info-msg">Debe introducir una fecha de inicio y de fin para mostrar la tabla</span>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col cols="12" class="text-center" v-if="loading_table">
                    <img :src="require('@/assets/img/loading.gif')"
                         alt="Loading Gif" class="loading-table">
                </v-col>
                <v-col cols="12" class="mb-5" v-else>
                    <v-table v-if="invoices_details.length>0" class="table-inconsistencies"
                             fixed-header>
                        <thead>
                        <tr class="table-header">
                            <th class="text-left font-weight-bold"> Razón Social</th>
                            <th class="text-left font-weight-bold"> Importe Innotutor</th>
                            <th class="text-left font-weight-bold"> Importe Aicore</th>
                        </tr>
                        </thead>
                        <tbody class="body-table">
                        <tr
                            v-for="invoice in invoices_details"
                            :key="invoice.razon_social"
                            :class="{'different-amount': invoice.importes_innotutor !== invoice.importes_aicore}">
                            <td>{{ invoice.razon_social }}</td>
                            <td>{{ invoice.importes_innotutor }}</td>
                            <td>{{ invoice.importes_aicore }}</td>
                        </tr>
                        </tbody>
                    </v-table>
                    <span v-else class="error-message">{{ msg_info }}</span>
                </v-col>
            </v-row>
        </v-container>
</template>

<script>
import {ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Calendar from "@/components/Generic/Calendar.vue";
import {getBillsWS} from "@/utils/inconsistencies";
import {useCalendar} from "@/utils/Composables/useCalendar";

export default {
    name: "InconsistenciesView",
    components: {Calendar, Loading},
    setup() {
        let see_table = ref(false) //Muestra la tabla de incongruencias
        let show_table = ref(false) //Indica al botón "FILTRAR" cuando ponerse disabled

        let loading_table = ref(false)

        let disabled_calendar = ref(false) // No hace nada?
        let start_date_calendar = ref(null) // Ref del calendario de fecha inicio
        let final_date_calendar = ref(null) // Ref del calendario de fecha fin

        let invoices_details = ref([])
        let form_error = ref({})
        let msg_info = ref('')

        const {formatDefaultDateView, comprobateDateIsBefore} = useCalendar()

        function validateDates() {
            let date_start = start_date_calendar.value.date_calendar
            let date_final = final_date_calendar.value.date_calendar
            form_error.value = {}

            if (variableIsDefined(date_start) && date_start !=='dd/mm/aaaa' && variableIsDefined(date_final) && date_final !=='dd/mm/aaaa') {
                if (comprobateDateIsBefore(date_start, date_final)) {
                    see_table.value = true
                    return true
                } else {
                    form_error.value.start = true;
                    form_error.value.msg = 'El campo de inicio no puede ser mayor al de finalización';
                    return false
                }
            } else {
                if (!variableIsDefined(date_start) || date_start ==='dd/mm/aaaa') {
                    form_error.value.start = true;
                }
                if (!variableIsDefined(date_final) || date_final ==='dd/mm/aaaa') {
                    form_error.value.final = true;
                }
                form_error.value.msg = 'El campo no puede estar vacío';
                return false
            }

        }

        async function searchDates() {
            show_table.value = true
            if (validateDates()) {
                await getAllDates()
            }
            show_table.value = false
        }

        async function getAllDates() {
            loading_table.value = true
            invoices_details.value = []

            const formatted_start_date_calendar = formatDefaultDateView(start_date_calendar.value.date_calendar, {split_by_slash: true, format: 'yyyy-mm-dd'})
            const formatted_final_date_calendar = formatDefaultDateView(final_date_calendar.value.date_calendar, {split_by_slash: true, format: 'yyyy-mm-dd'})

            let response = await getBillsWS(formatted_start_date_calendar, formatted_final_date_calendar);

            if (response['result']) {
                invoices_details.value = response['data']
                msg_info.value='No hay datos para las fechas introducidas'
            } else {
                msg_info.value = ""
                showNotification('error', response['errors'])
            }

            loading_table.value = false
        }


        return {
            searchDates,

            disabled_calendar,
            final_date_calendar,
            form_error,
            invoices_details,
            loading_table,
            msg_info,
            see_table,
            show_table,
            start_date_calendar,

        }
    }
}
</script>


<style lang="scss">
@import "@/styles/general";

.info-msg {
    font-style: italic;
    color: $gray-medium-bold;
}

.loading-table {
    width: 15vw;
}

.table-inconsistencies {
    max-height: 500px;
    border: 1px solid #dcdee2;
    border-radius: 2px;

    .different-amount {
        background: rgb(252 182 182 / 53%);
    }

    .table-header {
        color: $gray-medium-bold;
        text-transform: uppercase;
    }

}

.v-table__wrapper {
    @extend .personalize-scroll;
}

</style>
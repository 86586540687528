<template>
    <div class="single-value-chart p-3">
        <p class="single-value">{{formated_value}}</p>
        <p>{{title}}</p>
    </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getObjectValue} from "@/utils/looker_utils";

export default {
    name: "SingleValueChart",
    props: {
        single_value: Object,
        title: String,
    },
    setup(props) {
        const formated_value = ref(0)

        onBeforeMount(() => {
            if (getObjectValue(props.single_value)[0] !== null){
                formated_value.value = getObjectValue(props.single_value)[0]
            }
        })

        const formatData = (data) => {
            if (props.title.toLowerCase().includes('importe') || props.title.toLowerCase().includes('precio')){
                formated_value.value = `${data.toFixed(2)}€`
            }else if(getNumberOfDecimals(data)){
                formated_value.value = data.toFixed(2)
            }else{
                formated_value.value = data
            }
        }

        const getNumberOfDecimals = (number) => {
            if (typeof number === 'number'){
                let string_number = number.toString()
                let decimal_dot = string_number.indexOf('.')

                return decimal_dot === -1 ? 0 : string_number.substring(decimal_dot + 1).length
            }
        }

        return{
            formated_value
        }
    }
}
</script>

<style lang="scss">

    .single-value {
        font-size: 42px;
    }

    .single-value-chart {
        text-align: center;
        border: 1px solid $primary-color-1;
        background-color: $white;
        color: #333;
        height: 100%;
    }

    @media (max-width: 1024px) {
        .single-value {
            font-size: 36px;
        }
    }

    @media (max-width: 900px) {
        .single-value {
            font-size: 48px;
        }
    }

    @media (max-width: 500px) {
        .single-value {
            font-size: 32px;
        }
    }

</style>
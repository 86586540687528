<template>
    <v-col cols="12" class="text-end">
        <v-btn
            class="btn button-primary mb-2"
            :href="download_url"
            :download="table_title"
        >
            Descargar gráfica
        </v-btn>

        <GChart
            :type="chart_type"
            :data="null"
            @ready="onChartReady"
        />
    </v-col>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {GChart} from "vue-google-charts";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "BaseChart",
    components:{GChart},
    props:{
        chart_type: String,
        table_title: String,
        axis_title: Object,
        json_table: JSON,
    },
    setup(props){
        const download_url = ref()
        const options = {
            title : props.table_title,
            height: 500,
            legend: { position: 'bottom'},
            vAxis: {title: props.axis_title.vertical},
            hAxis: {title: props.axis_title.horizontal},
            bar: { groupWidth: '75%' },
            isStacked: true,
            backgroundColor: {stroke: '#66B3E5', strokeWidth: 2},
            focusTarget: 'category',
            fontName: 'Nunito',
            tooltip:{trigger: 'selection'},
            pieSliceText : 'value'
        };
        
        const formatted_json_table = JSON.parse(JSON.stringify(props.json_table).replace(/€/g, ''))
        let formated_data_array

        onBeforeMount(() => {
            if (props.chart_type === 'ColumnChart'){
                formatJSONArray()
            }else if (props.chart_type === 'PieChart'){

            }

        })

        const formatJSONArray = () => {
            formated_data_array = formatted_json_table.map((element) => {
                return getObjectValue(element)
            })

            formated_data_array.forEach((element, first_index) => {
                element.forEach((second_level_element, second_index) => {
                    if (second_index !== 0){
                        if ((typeof formated_data_array[first_index][second_index] === 'string')){
                            formated_data_array[first_index][second_index] = formated_data_array[first_index][second_index].replace('.', '')
                        }
                        if ((typeof formated_data_array[first_index][second_index] === 'string')){
                            formated_data_array[first_index][second_index] = formated_data_array[first_index][second_index].replace(',', '.')
                        }
                        formated_data_array[first_index][second_index] = parseFloat(formated_data_array[first_index][second_index])
                    }
                })
            })
        }

        /*Obtiene un array con todos las claves que son nodos hoja*/
        const getObjectKeyNames = (object) => {
            let row_names = []

            for (const [key, value] of Object.entries(object)){
                if (typeof value !== 'object' || (value == null)){
                    let type
                    //SI ES null SE LE ASIGNA TIPO number EN LA TABLA
                    if (typeof value === 'object'){
                        type = 'number'
                    }else{
                        //SI TIENE LETRAS O UN - SE LE ASIGNA TIPO STRING EN CASO CONTRARIO NULL
                        if (/[a-zA-Z-]/.test(value)){
                            type = 'string'
                        }else{
                            type = 'number'
                        }
                    }

                    row_names.push([type, key === 'null' ? '∅' : key])
                }else{
                    row_names.push(...getObjectKeyNames(value))
                }
            }
            return row_names
        }

        /*Obtiene un array con todos los valores de cada objeto*/
        const getObjectValue = (object) => {
            let row_values = []
            if (variableIsDefined(object)){
                for (const [key, value] of Object.entries(object)){
                    if (typeof value !== 'object' || (value === null)){
                        if (value === null){
                            row_values.push(0.00)
                        }else{
                            row_values.push(value)
                        }
                    }else{
                        row_values.push(...getObjectValue(value))
                    }
                }
            }

            return row_values
        }

        const onChartReady = async (chart, google) => {
            if (props.chart_type === 'ColumnChart'){
                await drawBarChart(chart, google)
            }else if (props.chart_type === 'PieChart'){
                await drawPieChart(chart, google)
            }
        }

        const drawPieChart = async (chart, google) => {
            formatJSONArray()

            let array_for_data = [['', ''],]
            let element_modifier = ""

            if (JSON.stringify(props.json_table).indexOf('€') > -1) element_modifier = '€'

            formated_data_array.map(element => {
                array_for_data.push([element[0], {v: element[1], f: `${element[1]}${element_modifier}`}])
            })

            let data = google.visualization.arrayToDataTable(array_for_data);

            await chart.draw(data, options)
            download_url.value = chart.getImageURI()
        }

        const drawBarChart = async (chart, google) => {
            try {
                let row_names = []
                let data = new google.visualization.DataTable()

                row_names = getObjectKeyNames(formatted_json_table[0])

                row_names.map((row) => {
                    data.addColumn(row[0], row[1])
                })

                formated_data_array.map((element) => {
                    data.addRow([...element])
                })

                let view = new google.visualization.DataView(data);
                let columns = Array.from({ length: data.getNumberOfColumns() }, (value, index) => index)

                columns.push({
                    role: 'annotation',
                    type: 'number',
                    calc: function (dt, row){
                        let sum = 0
                        for( let i = 1; i < dt.getNumberOfColumns(); i++){
                            sum += dt.getValue(row,i)
                        }
                        return sum
                    }
                })
                view.setColumns(columns)

                await chart.draw(view, options)
                download_url.value = chart.getImageURI()
            }catch (e) {
                console.log(e)
            }
        }

        return{
            download_url,
            onChartReady,
        }
    }
}
</script>

<style scoped>

</style>
<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12" class="text-center" v-if="loading">
                <Loading/>
            </v-col>
            <v-col cols="12" v-else>
                <template v-if="url !== 'null'">
                    <iframe v-if="error_msg === 'null'" height="100%" width="100%" class="full"
                            :src="url"></iframe>
                    <p class="text-danger fw-bold" v-else>No tienes acceso a este panel</p>
                </template>
                <template v-else>
                    <p class="text-danger fw-bold">Acceso incorrecto al dashboard</p>
                </template>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {useRoute, useRouter} from "vue-router";
import {onBeforeMount, ref} from "vue";
import {getDashboardWS} from "@/utils/dashboards";
import {showNotification} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "DashboardView",
    components: {Loading},

    setup() {
        const route = useRoute();
        let dashboard_id = parseInt(route.params.id);

        let error_msg = ref('null');
        let url = ref("null");
        let loading = ref(true);

        onBeforeMount(async () => {
            if (!dashboard_id){
                loading.value = false;
                showNotification('error','NO hemos encontrado el dashboard al que intentas acceder. Contacte con el administrador');
            }else{
                await getDashboard();
            }
        })

        const getDashboard = async () => {
            const response = await getDashboardWS(dashboard_id, route.query);
            if (response['result']) {
                url.value = response['data'].replace(/['"]+/g, '');
            } else {
                showNotification('error', response['errors'])
            }
            loading.value = false;
        }

        return {
            loading,
            url,
            error_msg
        }

    }
}
</script>

<style scoped lang="scss">

.full {
    background: $background;
    position: absolute;
    left: auto;
    right: auto;
    width: 100%;
    height: 100%;
    border: none;
    overflow: auto;
    margin-top: -3vh;
}
</style>
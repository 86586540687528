<template>
    <div class="fullscreen centered">
        <span class="error-404">404</span>
        <span class="error-text">Oops! No hemos encontrado lo que buscas</span>
    </div>
</template>

<script>
export default {
    name: "PageNotFoundView"
}
</script>
<style scoped lang="scss">
@import "@/styles/views/page-not-found.scss";
</style>
<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Importación de nóminas</span>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6">
                <span class="title-input">Importe la nómina 'A3'</span>
                <v-file-input
                    v-model="form_data.a3_payroll"
                    hide-details
                    class="text-field-secondary"
                    bg-color="white"
                    color="transparent"
                    :class="{error :form_error.a3_payroll}"
                    accept=".xlsx, .xls"
                    @click:clear='clearInput("a3_payroll")'
                ></v-file-input>
                <span v-if="form_error.a3_payroll"
                      class="input-error msg-error">{{ form_error.a3_payroll }}</span>
            </v-col>

            <v-col cols="12" md="6">
                <span class="title-input">Importe la nómina 'UDAVINCI'</span>
                <v-file-input
                    v-model="form_data.udavinci_payroll"
                    hide-details
                    class="text-field-secondary"
                    bg-color="white"
                    color="transparent"
                    accept=".xlsx, .xls"
                    :class="{error :form_error.udavinci_payroll}"
                    @click:clear='clearInput("udavinci_payroll")'
                ></v-file-input>
                <span v-if="form_error.udavinci_payroll"
                      class="input-error msg-error">{{ form_error.udavinci_payroll }}</span>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" class="text-end">
                <v-btn class="btn button-primary" @click="load_workforce_costsWS" :disabled="state_loading"> Enviar
                    <v-progress-circular
                        indeterminate
                        v-if="state_loading"
                        color="blue-darken-2"
                        :size="18"
                        :width="2"
                        class="ms-2"
                    ></v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import {onBeforeMount, ref} from "vue";
import {getAicoreToken, hasPermission} from "@/utils/auth";
import {useRoute} from "vue-router";
import * as Yup from "yup";
import axios from "axios";
import {showNotification} from "@/utils/aux_functions";

export default {
    name: "ImportPayroll",
    setup() {
        const route = useRoute();
        let form_data = ref({})
        let form_error = ref({})
        let state_loading = ref(false)

        onBeforeMount(async () => {
            await hasPermission(route);
        })

        async function validate() {
            form_error.value = {}
            const schemaForm = Yup.object().shape({

                a3_payroll: Yup.array()
                    .nullable()
                    .required('El campo es obligatorio')
                    .test(
                        'a3_payroll',
                        'El archivo debe ser de tipo .xlsx o .xls',
                        (value) => {
                            // Validar si el tipo de archivo está permitido OpenXML-Microsoft Excel +2007, Microsoft Excel más antiguos
                            return !value || !value[0] || ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(value[0].type);
                        }),

                udavinci_payroll: Yup.array()
                    .nullable()
                    .required('El campo es obligatorio')
                    .test(
                        'udavinci_payroll',
                        'El archivo debe ser de tipo .xlsx o .xls',
                        (value) => {
                            // Validar si el tipo de archivo está permitido OpenXML-Microsoft Excel +2007, Microsoft Excel más antiguos
                            return !value || !value[0] || ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(value[0].type);
                        })
            })
            try {
                await schemaForm.validate(form_data.value, {abortEarly: false})
                return true
            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                })
                return false
            }
        }

        async function load_workforce_costsWS() {
            if (await validate()) {
                state_loading.value = true
                try {
                    let response = await getAicoreToken(false);
                    let formData = new FormData();
                    formData.append("A3_Nominas", form_data.value.a3_payroll[0], 'a3_nominas');
                    formData.append("UDAVINCI_Nominas", form_data.value.udavinci_payroll[0], 'udavinci_nominas');
                    axios.post("https://aicorews.euroinnova.edu.es/api/RRHH/load_workforce_costs",
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': 'Bearer ' + response['access_token'],
                                "accept": 'application/json'
                            },
                        }
                    ).then(function (response) {
                        if (response['data']) {
                            showNotification('success', 'Nómina importada correctamente')
                        } else {
                            showNotification('error', 'Error al importar la nómina.')
                        }
                    }).catch(function (error) {
                        showNotification('error', 'Error al importar la nómina. ' + ' ' + error.message)
                    });
                } catch (e) {
                    showNotification('error', 'Error al hacer la petición')

                }
                state_loading.value = false
            }
        }

        function clearInput(input) {
            form_data.value[input] = null
        }


        return {
            load_workforce_costsWS,
            clearInput,

            form_data,
            form_error,
            state_loading,

        }
    }
}
</script>


<style scoped lang="scss">
.title-input {
    font-size: 18px;
}
</style>
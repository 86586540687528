<template>
    <v-container class="general-container">
        <div class="container-loading" v-if="loading">
            <img class="loading-gif" :src="require('@/assets/img/loading.gif')"
                 alt="Loading">
        </div>

        <v-row v-if="!loading">
            <v-col cols="12">
                <span class="title-id-sitemap">ID SITEMAP: {{ id_sitemap }}</span>
            </v-col>
            <v-col cols="12">
                <v-text-field
                    class="text-field-secondary"
                    v-model="search_value"
                    placeholder="¿Qué quieres buscar?"
                    append-inner-icon="mdi-magnify"
                    single-line
                    bg-color="white"
                    hide-details
                    no-data-text="No hay datos disponibles"
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <v-col cols="12">
            <Vue3EasyDataTable
                class="row-table"
                :headers="columns_table_url"
                :items="data_urls"
                buttons-pagination
                :rows-per-page="12"
                :search-field="search_field"
                :search-value="search_value"
                show-index
                rows-per-page-message="Elementos por página: "
                rows-of-page-separator-message="de"
                empty-message="No hay datos disponibles"
            >
                <template #item-iconoCopiar="{url}">
                    <img :src="require(`@/assets/icons/copy.svg`)" alt="Copiar" class="icon-clipboard"
                         title="Copiar"
                         id="copy" @click="copyClipboard(url)">
                </template>
                <template #item-url="{url}">
                    <a class="column-url" id="url"
                       :href="url">{{ url }} </a>

                </template>
            </Vue3EasyDataTable>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


import {computed, onBeforeMount, ref} from "vue";
import {useRoute} from "vue-router";
import {getUrlsSitemapsWS} from "@/utils/manager_sitemap";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import {showNotification} from "@/utils/aux_functions";

export default {
    name: "UrlData",
    components: {
        Vue3EasyDataTable
    },
    setup() {
        const search_field = ref(["id_sitemap_url", "codigo_respuesta_http", "redirecciona_a", "fecha"]);
        const search_value = ref("");
        const loading = ref(true);
        const route = useRoute()
        const columns_table_url = ref([
            {text: 'ID SITEMAP URL', value: 'id_sitemap_url', sortable: true},
            {text: 'URL', value: 'url', sortable: true},
            {text: '', value: 'iconoCopiar'},
            {text: 'HTTP CODE', value: 'codigo_respuesta_http', sortable: true},
            {text: 'REDIRECCIÓN', value: 'redirecciona_a', sortable: true},
            {text: 'FECHA', value: 'fecha', sortable: true},
        ]);
        let data_urls = ref([]);
        let id_sitemap = ref(0);


        onBeforeMount(async () => {
            id_sitemap.value = route.query.id
            const respuesta = await getUrlsSitemapsWS(id_sitemap.value)
            data_urls.value = respuesta;
            loading.value = false
        })

        function copyClipboard(url) {
            navigator.clipboard.writeText(url);
            showNotification("success", "Enlace copiado")
        }

        return {
            copyClipboard,
            columns_table_url,
            data_urls,
            loading,
            search_field,
            search_value,
            id_sitemap

        }
    }
};

</script>

<style lang="scss">
@import '@/styles/components/sitemaps-data.scss';
.title-id-sitemap{
    color: $gray-darker;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}

.column-url{
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    align-items: center;
    color: $primary-color-1;
    &:hover{
        color: $primary-color-1;
    }
}

</style>

<template>
    <v-container>
        <template v-if="general_loading">
            <Loading></Loading>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="12" class="section-title">
                    <span class="main-title-page">Asignación fuerza de ventas a matrículas</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <FiltersGetEnrollment
                        ref="emit_filter"
                        :applyFilter="applyFilter"
                        :disabled_btn_filter="disabled_btn_filter"
                        :default_date="filter_data"
                    ></FiltersGetEnrollment>
                </v-col>
            </v-row>
            <v-row v-if="show_table">
                <v-col cols="12">
                    <Vue3EasyDataTable
                        table-class-name="primary-table"
                        theme-color="#66B3E5"
                        :headers="columns_table"
                        :items="items_enrollments"
                        :loading="loading_table"
                        buttons-pagination
                        :rows-per-page="10"
                        rows-per-page-message="Elementos por página: "
                        rows-of-page-separator-message="de"
                        empty-message="No hay datos disponibles"
                    >
                        <template #item-id_matricula="{id_matricula}">
                            <a :href="'https://innotutor.com/ProgramasFormacion/MatriculaVisualizar.aspx?matriculaId=' + id_matricula"
                               target="_blank" title="Enlace a Innotutor">
                                <span class="field_with_link">{{ id_matricula }}</span>
                            </a>
                        </template>

                        <template #item-link_escic="{link_escic}">
                            <div v-if="getIdHiloEscic(link_escic)!==null">
                                <a :href="link_escic"
                                   target="_blank" title="Enlace a Escic">
                                <span class="field_with_link">
                                    {{ getIdHiloEscic(link_escic) }}
                                </span>
                                </a>
                            </div>
                            <div v-else class="link-is-null">Sin Hilo</div>
                        </template>

                        <template #item-fecha_creacion_matricula="{fecha_creacion_matricula}">
                            {{
                                formatDates(fecha_creacion_matricula, 'table')
                            }}
                        </template>

                        <template
                            #item-edit_data="{id_matricula, fuerza_de_ventas_actual, id_fuerza_venta,  tecnico_que_matricula, id_tecnico_que_matricula, comentarios, editable}">
                            <div v-if="editable===1">
                                <img :src="require(`@/assets/icons/file-edit.svg`)" alt="Modificar matrícula"
                                     title="Modificar Matricula" class="edit-enrollment"
                                     @click="editEnrollment(id_matricula, id_fuerza_venta, tecnico_que_matricula, id_tecnico_que_matricula, comentarios)">
                            </div>
                        </template>

                    </Vue3EasyDataTable>
                </v-col>
            </v-row>
        </template>
        <v-dialog persistent
                  v-model="open_modal">
            <ModalEditEnrollment
                :displayModal="displayModal"
                :modified_data="modified_data"
                :loading_btn="loading_btn"
                :getEnrollMentOutFFVV="getEnrollmentOutffvv"
            ></ModalEditEnrollment>

        </v-dialog>
    </v-container>
</template>

<script>

import Calendar from "@/components/Generic/Calendar.vue";
import {onBeforeMount, ref} from "vue";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import Vue3EasyDataTable from "vue3-easy-data-table";
import FiltersGetEnrollment from "@/components/SalesForce/FiltersGetEnrollment.vue";
import {
    getEnrollmentOutffvvWS,
    updateEnrollmentWS
} from "@/utils/enrollment_ffvv";
import Loading from "@/components/Generic/Loading.vue";
import ModalEditEnrollment from "@/components/SalesForce/ModalEditEnrollment.vue";
import {useCalendar} from "@/utils/Composables/useCalendar";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "AssignmentFFVVToEnrollmentsView.vue",
    methods: {variableIsDefined},
    components: {ModalEditEnrollment, Loading, FiltersGetEnrollment, Vue3EasyDataTable, Calendar},

    setup() {
        const route = useRoute()
        const router = useRouter()

        let general_loading = ref(false)
        let loading_table = ref(false)
        let loading_btn = ref(false)
        let show_table = ref(false)
        let open_modal = ref(false)
        let disabled_btn_filter = ref(false)

        let filter_data = ref({})
        let today = new Date();
        let today_less_7_days = new Date();
        let init_date = ref(null)
        let final_date = ref(null)

        let emit_filter = ref(null)

        let columns_table = ref([
            {text: 'ID MATRÍCULA', value: 'id_matricula'},
            {text: 'ID HILO ESCIC', value: 'link_escic'},
            {text: 'FFVV ACTUAL', value: 'fuerza_de_ventas_actual'},
            {text: 'PROGRAMA FORMATIVO', value: 'programa_formativo'},
            {text: 'TÉCNICO ASOCIADO', value: 'tecnico_que_matricula'},
            {text: 'CREACIÓN MATRICULA', value: 'fecha_creacion_matricula'},
            {text: 'EMAIL', value: 'email'},
            {text: 'COMENTARIOS', value: 'comentarios'},
            {text: '', value: 'edit_data'},
        ])
        let items_enrollments = ref([])
        let modified_data = ref({})

        const {getDateComponents} = useCalendar()

        onBeforeMount(async () => {
            await router.isReady()
            general_loading.value = true
            today_less_7_days.setDate(today.getDate() - 7);
            filter_data.value = {
                init_date: formatDates(today_less_7_days, 'filter'),
                final_date: formatDates(today, 'filter'),
                origin: 'Privada',
            };

            if (variableIsDefined(route.query.matricula_id)){
                delete filter_data.value.init_date;
                delete filter_data.value.final_date;
                filter_data.value.id_enrollment = route.query.matricula_id
            }

            if (variableIsDefined(route.query.email)){
                delete filter_data.value.init_date;
                delete filter_data.value.final_date;
                filter_data.value.email = route.query.email
            }

            await getEnrollmentOutffvv()
            show_table.value = true
            general_loading.value = false
        })

        /*TODAS LAS MATRICULAS*/
        async function getEnrollmentOutffvv() {
            loading_table.value = true

            let response = await getEnrollmentOutffvvWS(filter_data.value)
            if (variableIsDefined(response)) {
                if (response['result']) {
                    items_enrollments.value = response['data']
                } else {
                    showNotification('error', response['errors'])
                }
            } else {
                showNotification('error', 'Error en la carga de datos')
            }

            loading_table.value = false
        }

        async function applyFilter() {
            disabled_btn_filter.value = true
            let filters = await emit_filter.value.validateFilter()
            filter_data.value = filters.data_filters

            if (filters.validate) {
                show_table.value = true
                loading_table.value = true

                filter_data.value.init_date = formatDates(filter_data.value.init_date, 'ws')
                filter_data.value.final_date = formatDates(filter_data.value.final_date, 'ws')

                await getEnrollmentOutffvv()
                loading_table.value = false
            }
            disabled_btn_filter.value = false
        }

        function displayModal() {
            open_modal.value = !open_modal.value
        }

        function formatDates(date, order) {
            let date_return
            if (variableIsDefined(date)) {
                const {day, month, year} = getDateComponents(date)
                switch (order) {
                    case 'ws':
                        const [splitted_day, splitted_month, splitted_year] = date.split('/')
                        date_return = `${splitted_year}-${splitted_month}-${splitted_day}`
                        break;
                    case 'filter':
                        date_return = `${year}-${month}-${day}`
                        break;
                    case 'table':
                        date_return = `${day}-${month}-${year}`
                        break;
                }
            }
            return date_return
        }

        function getIdHiloEscic(url) {
            const regex = /id_hilo=(\d+)/;
            const hilo = url.match(regex);
            return hilo ? hilo[1] : null;
        }


        function editEnrollment(id_enrollment, id_fuerza_venta, name_technical, id_technical, comentarios) {
            modified_data.value = {
                'id_enrollment': id_enrollment,
                'id_fuerza_venta': id_fuerza_venta,
                'id_technical': id_technical,
                'name_technical': name_technical,
                'comentarios':comentarios
            }
            displayModal()
        }

        return {
            applyFilter,
            getIdHiloEscic,
            formatDates,
            displayModal,
            editEnrollment,
            getEnrollmentOutffvv,

            general_loading,
            init_date,
            final_date,
            filter_data,
            loading_table,
            show_table,
            columns_table,
            items_enrollments,
            emit_filter,
            disabled_btn_filter,
            open_modal,
            modified_data,
            loading_btn,
        }
    }
}
</script>


<style scoped lang="scss">
.edit-enrollment {
    width: 20px;
    cursor: pointer;
}

.field_with_link {
    color: $black;

    &:hover {
        color: $primary-color-2;
    }
}

.link-is-null {
    font-style: italic;
    color: $black;
}
</style>
<template>
    <v-row>
        <v-col>
            <v-card class="card-modal card-personalized">
                <v-card-title>
                    <v-row>
                        <v-col cols="12">
                            <span> {{ title_modal }}</span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" lg="6">
                            <span class="title-small">{{ text_info_url }}</span>
                            <v-text-field class="input-error text-field-secondary"
                                          v-model="form_data.url"
                                          single-line
                                          bg-color="white"
                                          color="transparent"
                                          hide-details
                                          :error="form_error.url || form_error.extension"
                            ></v-text-field>
                            <span v-if="form_error.url"
                                  class="input-error msg-error">El campo no puede estar vacío</span>
                            <span v-else-if="form_error.extension" class="input-error msg-error">
                                La extensión del archivo no es la correcta. Valores permitidos:
                                <span v-if="type.toLowerCase()==='logo'">{{image_extension}}</span>
                                <span v-else>{{video_extension}}</span>
                            </span>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <v-row>
                                <v-col cols="12" md="6" lg="6">
                                    <span class="title-small">{{ text_time_start }}</span>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="align-self-center">
                                            <span>Horas</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.init_hour"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" class="align-self-center">
                                            <span>Minutos</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.init_min"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <span>Segundos</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.init_seg"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="6" lg="6">
                                    <span class="title-small">{{ text_time_finish }}</span>
                                    <v-row no-gutters>
                                        <v-col cols="4" class="align-self-center">
                                            <span>Horas</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.final_hour"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4" class="align-self-center">
                                            <span>Minutos</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.final_min"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                        <v-col cols="4">
                                            <span>Segundos</span>
                                            <v-autocomplete class="input-error autocomplete-secondary" bg-color="white"
                                                            auto-select-first
                                                            v-model="form_data.final_seg"
                                                            :items="item_tiempo"
                                                            hide-details
                                                            :menu-props="{ 'max-height': '200' }"
                                                            :error="msg_error_time"
                                            ></v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col>
                                    <v-row>
                                        <v-col cols="12" v-if="msg_error_time" class="error-msg text-center">
                                            <span>El tiempo de finalización no puede ser menor al de comenzar</span>
                                        </v-col>
                                        <v-col cols="12" class="msg-info" align-self="end">
                                            <span>{{ text_warning }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {ref} from "vue";
import * as Yup from "yup";
import {variableIsDefined} from "@/utils/aux_functions";
import {vi} from "vuetify/locale";

export default {
    name: "FormCreateVideo",
    computed: {
        vi() {
            return vi
        }
    },
    props: {
        title_modal: String,
        text_warning: String,
        type: String,
        text_info_url: String,
        text_time_start: String,
        text_time_finish: String,
    },
    emits: ['generateTime'],

    setup(props) {
        const item_tiempo = ref([])
        for (let i = 0; i <= 60; i++) {
            const minuto = i < 10 ? `0${i}` : `${i}`;
            item_tiempo.value.push(minuto);
        }
        let start_time = ''
        let final_time = ''

        let form_data = ref({
            url: '',
            init_hour: null,
            init_min: null,
            init_seg: null,
            final_hour: null,
            final_min: null,
            final_seg: null
        })

        let form_error = ref({})
        const schemaForm = Yup.object().shape({
            url: Yup.string().required(true),
        })

        let general_error_msg = ref(false)
        let msg_error_time = ref(false)


        /*EXTENSIONES PARA EL CDN*/
        const video_extension = ['MP4', 'AVI', 'MOV', 'MKV', 'WMV']
        const image_extension = ['JPG', 'JPEG', 'PNG', 'WEBP', 'SVG']

        async function generateTime() {
            msg_error_time.value = false
            form_error.value = {}
            general_error_msg.value = false
            try {
                let extension = form_data.value.url.split('.').pop()

                if (props.type !== 'video_inicio') {
                    await schemaForm.validate(form_data.value, {abortEarly: false})
                }

                props.type === 'Logo' ?
                    form_error.value.extension = !image_extension.some(img_ext => extension.toUpperCase() === img_ext)
                    :
                    form_error.value.extension = !video_extension.some(vid_ext => extension.toUpperCase() === vid_ext)

                if (props.type === 'video_inicio' && !variableIsDefined(form_data.value.url)) {
                    form_error.value.extension = false
                }

                start_time = (form_data.value.init_hour || '00') + ':' + (form_data.value.init_min || '00') + ':' + (form_data.value.init_seg || '00')
                final_time = (form_data.value.final_hour || '00') + ':' + (form_data.value.final_min || '00') + ':' + (form_data.value.final_seg || '00')

                if (start_time > final_time) {
                    msg_error_time.value = true
                    return false
                }

                if (form_data.value.url.split('.')[0].length === 0){
                    form_error.value.url = true
                }

                if(form_error.value.extension){
                    return false
                }else{
                    return [props.type, form_data.value.url, start_time, final_time, form_error.value.url]
                }
            } catch (e) {
                e.inner.forEach((error) => {
                    form_error.value[error.path] = error.message;
                    general_error_msg.value = true
                })
                return false
            }
        }

        return {
            generateTime,
            item_tiempo,
            form_data,
            form_error,
            general_error_msg,
            msg_error_time,
            video_extension,
            image_extension
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/product-video.scss";
</style>
<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Manuales de referencia</span>
            </v-col>
        </v-row>
        <v-row>
            <template v-for="document in document_list">
                <v-col cols="6">
                    <router-link class="link" :to="'/documentacion/' + document.href" target="_blank">
                        <img :src="require('@/assets/icons/pdf.svg')" alt="Icono pdf" style="width: 28px">
                             {{document.name}}
                    </router-link>
                </v-col>
            </template>
        </v-row>
    </v-container>
</template>

<script>

import {ref} from "vue";

export default {
    name: "DocumentsView",
    setup() {
        const document_list = ref([
            {name: 'Creación vídeo-producto', href: 'creacion_automatica_video_producto.pdf'},
            {name: 'Dashboard asesores académicos', href: 'dashboard_asesores_academicos.pdf'},
            {name: 'Fuerza ventas', href: 'objetivos_fuerzas_ventas.pdf'},
            {name: 'Generación de HTML de traducciones', href: 'html_traducciones.pdf'},
            {name: 'Generación de guiones para cursos', href: 'generacion-guiones-para-cursos.pdf'},
            {name: 'Importación de nóminas', href: 'importacion_nominas.pdf'},
            {name: 'Incongruencias en facturación', href: 'incongruencias_nominas.pdf'},
            {name: 'Asignación fuerza de ventas a matrículas', href: 'asignacion_fuerza_de_ventas_a_matriculas.pdf'},
        ])

        return {
            document_list
        }
    }
}
</script>

<style lang="scss" scoped>
.link {
    font-size: 18px;
    color: $primary-color-1;
    &:hover{
        text-decoration: underline;
        font-weight: 600;
    }
/*    color: deeppink;
    text-decoration: underline;*/
}
</style>
<template>
    <v-row no-gutters class="justify-space-around multiple-value-chart">
        <v-col cols="12">
            <span class="component-title">{{title}}</span>
        </v-col>
        <v-col cols="4" v-for="element in formatted_values">
            <p class="element-title">{{element.obj_title}}</p>
            <p class="element-value">{{element.obj_value}}</p>
        </v-col>
    </v-row>
</template>

<script>
import {onBeforeMount, ref} from "vue";

export default {
    name: "MultipleValueChart",
    props: {
        values: Object,
        title: {
            type: String,
            required: true,
        },
    },
    setup(props) {
        const formatted_values = ref([])

        onBeforeMount(() => {
            for (const [key, value] of Object.entries(props.values[0])){
                formatted_values.value.push({obj_title: key, obj_value: value})
            }
        })

        return{
            formatted_values,
        }
    }
}
</script>

<style lang="scss">
    .element-value {
        font-size: 22px;
    }

    .element-title {
        font-size: 14px;
    }

    .component-title{
        font-size: 22px;
    }

    .multiple-value-chart {
        text-align: center;
        border: 1px solid $primary-color-1;
        background-color: $white;
        color: #333;
        height: 100%;
    }
</style>
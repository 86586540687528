<template>
    <nav class="navbar navbar-inverse fixed-top personalize-scroll-sidebar" id="sidebar-wrapper" role="navigation">
        <div class="icons-colum h-100">
            <div class="top-icons">
                <!--                <router-link to="/sitemaps-data" class="icons-link ">-->
                <!--                    <img :src="require(`@/assets/icons/sitemap.svg`)" alt="sitemaps-data"-->
                <!--                         title="sitemaps-data"-->
                <!--                         id="sitemaps-data-icon"
                                             class = "icon-sidebar"
                                             >-->
                <!--                    <p class="title-icons">Sitemaps</p>-->
                <!--                </router-link>-->
<!--                <router-link to="/registrar-incidencia-matricula" class="icons-link ">
                    <img :src="require(`@/assets/icons/file-edit.svg`)" alt="Incidencias"
                         title="Incidencias matriculas"
                         id="sitemaps-data-icon"
                         class="icon-sidebar"
                    >
                    <p class="title-icons">Incidencias matrículas</p>
                </router-link>-->
            </div>
            <div class="bottom-icons">
                <router-link to="/admin" v-if="admin_access">
                    <img :src="require(`@/assets/icons/admin-settings.svg`)" alt="Gestor Administrativo"
                         title="Gestor Administrativo"
                         id="gestor_admin-icon"
                         class="icon-sidebar"
                    >
                    <p class="title-icons">Gestor Admin</p>
                </router-link>
            </div>
        </div>
    </nav>
</template>


<script>
import {onBeforeMount, ref} from "vue";
import {useStore} from "vuex";

export default {
    name: "Sidebar",

    setup() {
        let admin_access = ref(false);
        const store = useStore();
        const username = store.getters.getUsername;
        const allowed_users = store.getters.getUserGroup;

        onBeforeMount(() => {
            admin_access.value = allowed_users.includes(username);
        })

        return {
            admin_access
        }
    }
}

</script>

<style scoped lang="scss">
@import "@/styles/components/sidebar.scss";
</style>
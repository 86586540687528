<template class="card-filter">
    <v-row>
        <v-col>
            <v-card class="card-modal card-personalized">
                <v-card-title>
                    <v-row>
                        <v-col cols="12">
                            <span>CONFIGURACIÓN PARA EL LOGO EN VIDEO PRODUCTO</span>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" md="3" lg="3">
                            <span class="title-small">Posición</span>
                            <v-select
                                class="select-secondary personalize-select"
                                aria-label="Default select example"
                                :items="input_logo_position"
                                v-model="filter_data.logo_position"
                                auto-select-first
                                item-title="title"
                                item-value="valor"
                                hide-details
                                bg-color="white"
                                placeholder="Superior-Izquierda"
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="2" lg="2">
                            <span class="title-small">Resolución</span>
                            <v-select
                                class="select-secondary personalize-select"
                                auto-select-first
                                item-title="title"
                                item-value="valor"
                                placeholder="240:320"
                                hide-details
                                bg-color="white"
                                :items="item_resolution"
                                v-model="filter_data.resolution"
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="2" lg="2">
                            <span class="title-small">Tiempo transición</span>
                            <v-select
                                class="select-secondary personalize-select"
                                auto-select-first
                                hide-details
                                placeholder="0.3"
                                bg-color="white"
                                v-model="filter_data.item_transition_time"
                                :items="item_transition_time"
                                :menu-props="{ 'max-height': '200' }"
                            ></v-select>
                        </v-col>

                        <v-col cols="6" md="3" lg="2">
                            <span class="title-small">Activar para suprimir el fondo del logo</span>
                            <v-checkbox class="text-center"
                                        v-model="filter_data.active_background" color="info"
                                        label="Activar Fondo"
                                        hide-details
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="6" md="2" lg="3">
                            <span class="title-small">Indica el color a suprimir (Hex)</span>
                            <v-text-field :disabled="!filter_data.active_background"
                                          class="input-error text-field-secondary"
                                          v-model="filter_data.background_color"
                                          label="#FFFFFF00(Transparente)"
                                          single-line
                                          bg-color="white"
                                          color="transparent"
                                          hide-details
                            ></v-text-field>
                        </v-col>

                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {ref} from "vue";

export default {
    name: "CardFilter",
    emits: ['generateConfiguration'],
    setup() {
        let input_logo_position = ref([
            {
                title: 'Superior-Derecha',
                valor: 'arriba-derecha'
            },
            {
                title: 'Superior-Izquierda',
                valor: 'arriba-izquierda'
            },
            {
                title: 'Inferior-Derecha',
                valor: 'abajo-derecha'
            },
            {
                title: 'Inferior-Izquierda',
                valor: 'abajo-izquierda'
            },
        ])
        let item_transition_time = ref([]);
        for (let i = 1; i <= 9; i++) {
            item_transition_time.value.push(i / 10);
        }
        let item_resolution = ref(['120:160', '144:176', '176:220', '240:320', '480:800'])

        let filter_data = ref({
            logo_position: null,
            resolution: null,
            item_transition_time: null,
            active_background: false,
            background_color: null,
        })

        function generateConfiguration() {
            return filter_data.value
        }


        return {
            generateConfiguration,

            input_logo_position,
            item_transition_time,
            item_resolution,
            filter_data,
        }
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/product-video.scss";
</style>
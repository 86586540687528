import store from "@/store";
import {aicoreBackendPetition} from "@/utils/aicore_petition";

export async function getSitemapWS(portal_id) {
    var data = {};
    data.id_portal = portal_id

    return await aicoreBackendPetition('GET', "/GoogleSearchConsole/get_sitemaps_from_AISEO", data)
}

export async function getPortalsWS (){
    return await aicoreBackendPetition('GET', "/GoogleSearchConsole/get_portales_from_AISEO", {})
}

export async function getUrlsSitemapsWS (id_sitemap){
    return await aicoreBackendPetition('GET', "/GoogleSearchConsole/get_urls_sitemap_from_AISEO", {id_sitemap})
}
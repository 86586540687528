<template>
    <nav class="navbar navbar-expand-lg  fixed-top top-navbar">
        <div class="container-fluid container-navbar">
            <button class="navbar-toggler d-none" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                    aria-controls="offcanvasNavbar">
                <span class="navbar-toggler-icon navbar-dark "></span>
            </button>
            <router-link to="/" class="navbar-brand"><img
                :src="require('@/assets/logos/logotipo-educore.ai-negativo.svg')" alt="AICORE"
                class="d-inline-block logo-aicore"
            ></router-link>
            <div class="me-auto text-menu menu-desktop">

            </div>

            <div class="justify-content-end global-icons-end ">
                <router-link to="/documentacion">
                    <img :src="require(`@/assets/icons/doc_icon.svg`)"
                         alt="Documentación"
                         class="manager-admin-icon">
                </router-link>
                <router-link to="/admin" v-if="admin_access">
                    <img :src="require(`@/assets/icons/admin-settings-light.svg`)"
                         alt="Gestor Administrativo"
                         class="manager-admin-icon">
                </router-link>
                <a class="nav-item dropdown nav-link bg-transparent rounded-circle icons-right" id=btn-user
                   href="#"
                   role="button"
                   data-bs-toggle="dropdown" aria-expanded="false">
                    <div id="logo-user">
                        <span id="icon-user">{{ user_initial }}</span>
                    </div>

                    <ul class="dropdown-menu submenu dropdown-menu-end " id=dropdown-login>
                        <li><span class="dropdown-item-text" id="fullname">{{ username }}</span></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li>
                            <a class="dropdown-item" id="logout" @click="logout">Cerrar sesión</a>
                        </li>
                    </ul>
                </a>

            </div>


            <!-- Menu responsive-->
            <div class="offcanvas offcanvas-start bg-navbar-small d-none" tabindex="-1" id="offcanvasNavbar"
                 aria-labelledby="offcanvasNavbarLabel">
                <div class="offcanvas-header mt-5 mb-5 justify-content-start">
                    <button type="button" class="btn-close close-menu-small" data-bs-dismiss="offcanvas"
                            aria-label="Close"></button>
                </div>
                <div class="offcanvas-body menu-body">
                    <div class="me-auto text-menu">
                        <router-link to="/registrar-incidencia-matricula"
                                     class=" align-items-center">
                            <img :src="require(`@/assets/icons/file-edit.svg`)" alt="Incidencias"
                                 title="Incidencias matriculas"
                                 id="sitemaps-data-icon">
                            <span class="title-icons">Incidencias matrículas</span>
                        </router-link>
                    </div>
                    <div class="justify-content-start global-icons-end">
                        <router-link to="/admin" v-if="admin_access">
                            <img :src="require(`@/assets/icons/admin-settings-light.svg`)"
                                 alt="Gestor Administrativo"
                                 class="manager-admin-icon">
                            <span class="title-icons">Gestor Admin</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script>
import AuthView from "@/views/Auth/AuthView.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {belongGroup, getAdminGroups, logout} from "@/utils/auth";

export default {
    name: "NavBar",
    components: {AuthView},
    setup() {
        const store = useStore();
        let username = computed(() => store.getters.getUsername).value;
        let user_initial = (username.substring(0, 1).charAt(0).toUpperCase());
        let admin_access = ref(false);

        onBeforeMount(() => {
            admin_access.value = belongGroup(getAdminGroups());
        })

        return {
            logout,
            username,
            user_initial,
            admin_access,
        }
    }
}

</script>

<style lang="scss">
@import "@/styles/components/navbar.scss";
</style>
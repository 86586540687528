<template>
  <v-card class="card-modal ">
    <v-card-title class="head-modal">
      <span class="title-modal">Asignación de URL a Tarea </span>
      <v-btn class="btn-close-modal" @click="displayDialog" :ripple="false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <div class="body-modal ">
      <v-row>
        <v-col cols="12" md="11" class="ml-8 mt-2">
          <v-card
            flat
            title='Lista URLS'
          >
            <template v-slot:text>
              <v-text-field
                v-model="clientSearch"
                label="Buscar URL"
                prepend-inner-icon="mdi-magnify"
                variant="outlined"
                @:input="debounceFilter(() => {serverSearch = $event.target.value})"
                hide-details
                single-line
              ></v-text-field>
            </template>
            <v-data-table-server
              v-model="assignedUrl"
              class="elevation-1 mb-2"
              :items-length="serverItemsLength"
              loading-text="Cargando...por favor espere"
              :headers="serverHeaders"
              :items="serverItems"
              :search="serverSearch"
              :loading="loading"
              return-object
              show-select
              select-strategy="single"
              @update:options="getURLsByPortal"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-card-actions class="footer-modal">
        <v-row>
          <v-col cols="12">
            <div class="btn-search-form">
              <v-btn
                @click="sendForm"
                :loading="disabledBtnSave"
                class="btn button-primary"
                type="submit"
                hide-details
                typeof="submit"
              >
                Asignar Url
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {initSweetAlert} from "@/utils/sweetalert";
import {getURLByPortalWS} from "@/utils/courses";
import {showNotification, variableIsDefined} from "@/utils/aux_functions";
import {systemRoute} from "@/router/routes_home";

export default {
  name: "AssignUrlDialog",
  methods: {variableIsDefined, getURLByPortalWS},
  props: {
    urlType: String,
    displayDialog: Function,
    callbackFunction: Function,
  },
  setup(props) {
    let assignedUrl = ref([]);
    let disabledBtnSave = ref();

    let serverOptions = ref({
      page: 1,
      rowsPerPage: 10,
    });

    let loading = ref(true);
	let clientSearch = ref("");
    let serverSearch = ref("");
    let serverItemsLength = ref(0);
    let serverItems = ref([]);
    const serverHeaders = [
      {title: 'URL', value: 'url', sortable: true},
      {title: 'FECHA CREACIÓN', value: 'fecha_creacion', sortable: true},
      {title: 'FECHA ACTUALIZACIÓN', value: 'fecha_actualizacion', sortable: true},
    ];

    function createDebounce() {
      let timeout = null;
      return function (fnc, delayMs) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          fnc();
        }, delayMs || 300);
      };
    }


    onBeforeMount(async () => {
      initSweetAlert()
    })

    const sendForm = async () => {
      if(assignedUrl.value.length != 1){return;}
      disabledBtnSave.value = true;
      props.callbackFunction(assignedUrl.value[0]['url'], assignedUrl.value[0]['id_url']);
      props.displayDialog();
      disabledBtnSave.value = false;
    }

    async function getURLsByPortal() {
      loading.value = true;
      let orderBy = 'fecha_creacion';
      let orderDirection = 'desc';
      let filters = {
        'url_search': serverSearch.value,
        'url_search_type': 'contains',
        'state_urls': 1,
        'renderables': 1,
      }
      let response = await getURLByPortalWS(props.urlType, serverOptions.value.page, serverOptions.value.rowsPerPage, filters, orderDirection, orderBy);
      if (response['data'] && response['result']) {
        serverItems.value = response['data'].data;
        serverItemsLength.value = response['data'].total;
      } else {
        showNotification("error", response['errors'])
      }
      loading.value = false
    }

    return {
      assignedUrl,
      disabledBtnSave,
      sendForm,
      getURLsByPortal,
      serverOptions,
      loading,
	  clientSearch,
      serverSearch,
      serverItemsLength,
      serverItems,
      serverHeaders,
      debounceFilter: createDebounce(),
    }
  }
}
</script>

<style lang="scss" scoped>
.course-list-container {
  display: flex;
  justify-content: space-between;
}

.boxes-areas {
  .box_area_assign {
    box-shadow: none;
    height: auto;
    width: fit-content;
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: $primary-color-1;
    border: 1px solid $primary-color-1;

    .text-area {
      padding: 3px 15px;
    }

    .icons-close {
      filter: brightness(0) saturate(100%) invert(90%) sepia(17%) saturate(3681%) hue-rotate(321deg) brightness(96%) contrast(98%);
    }

    .icons-loading {
      display: none;
    }

  }
}

.list-card {
  box-shadow: none;
  border: 1px solid $primary-color-1;
  max-height: 40vh;
  overflow: scroll;
}
</style>


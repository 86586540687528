<template>
    <template v-if="loading">
        <Loading/>
    </template>
    <template v-if="!loading">
        <v-container>
            <v-row>
                <v-col cols="12" class="section-title">
                    <span class="main-title-page">Objetivos fuerza ventas</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="5" md="4" lg="3" class="align-self-center">
                    <span><b>Consultar objetivos del grupo:</b></span>
                </v-col>
                <v-col cols="12" sm="7" md="8" lg="9">
                    <v-autocomplete
                        v-model="table_filters['id_grupo_objetivos']"
                        @update:modelValue="getGroupObjectives"
                        :items="objective_groups"
                        item-title="nombre"
                        item-value="id_grupo_objetivos"
                        class="autocomplete-secondary"
                        bg-color="white"
                        placeholder="Seleccione un grupo"
                        no-data-text="No hay datos disponibles"
                        hide-details
                    >
                    </v-autocomplete>
                </v-col>
            </v-row>

            <template v-if="variableIsDefined(table_filters['id_grupo_objetivos'])">
                <!--           FILTROS-->
                <v-row>
                    <v-col>
                        <v-expansion-panels>
                            <v-expansion-panel class="expansion-personalized">
                                <v-expansion-panel-title class="title-expansion-panel">Opciones de filtrado
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>
                                    <v-row>
                                        <v-col cols="12" sm="4" md="3">
                                            <v-select
                                                v-model="table_filters['month']"
                                                :items="[{name: 'MES', id: null}, ...months]"
                                                item-title="name"
                                                item-value="id"
                                                class="select-secondary"
                                                bg-color="white"
                                                placeholder="Seleccione un mes"
                                                no-data-text="No hay datos disponibles"
                                                hide-details
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="3">
                                            <v-select
                                                v-model="table_filters['year']"
                                                :items="[{name: 'AÑO', id: null}, ...years]"
                                                item-title="name"
                                                item-value="id"
                                                class="select-secondary"
                                                bg-color="white"
                                                placeholder="Seleccione un año"
                                                no-data-text="No hay datos disponibles"
                                                hide-details
                                            >
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="4" md="6"
                                               class="text-center text-sm-start align-self-center">
                                            <v-btn
                                                class="btn button-bg-primary-color"
                                                @click="searchWithFilters"
                                                :loading="table_loading"
                                            >
                                                Filtrar
                                            </v-btn>
                                            <img :src="require('@/assets/icons/drop-filter.svg')" alt="Eliminar filtros"
                                                 class="delete-filter ms-4" @click="clearFilters"></v-col>
                                    </v-row>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>

                <!--            FIN FILTROS-->

                <!--                CREAR UN NUEVO OBJETIVO-->

                <v-row>
                    <v-col cols="12">
                        <span class="input-title">Añadir objetivo al grupo</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                            v-model="new_objective_data['month']"
                            :items="months"
                            item-title="name"
                            item-value="id"
                            class="input-error select-secondary"
                            bg-color="white"
                            placeholder="Seleccione un mes"
                            no-data-text="No hay datos disponibles"
                            hide-details
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-select
                            v-model="new_objective_data['year']"
                            :items="years"
                            item-title="name"
                            item-value="id"
                            class="input-error select-secondary"
                            bg-color="white"
                            placeholder="Seleccione un año"
                            no-data-text="No hay datos disponibles"
                            hide-details
                        >
                        </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-text-field
                            v-model="new_objective_data['objective']"
                            class="input-error text-field-secondary"
                            :error="new_objective_errors['objective']"
                            placeholder="Objetivo"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hint="Solo se almacenan dos decimales, sin redondeo. Formato: xxx.xx"
                        >
                        </v-text-field>
                        <span v-if="new_objective_errors['objective']"
                              class="input-error msg-error text-end">{{ error_objetive }}</span>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="text-center text-sm-start mt-md-2">
                        <v-btn
                            class="btn button-bg-primary-color"
                            @click="createNewObjective"
                            :loading="table_loading"
                        >Crear objetivo
                        </v-btn>
                    </v-col>
                </v-row>
                <!--              FIN----  CREAR UN NUEVO OBJETIVO-->
                <v-row>
                    <v-col>
                        <Vue3EasyDataTable
                            table-class-name="primary-table"
                            theme-color="#66B3E5"
                            buttons-pagination
                            :headers="headers"
                            :items="table_items"
                            v-model:server-options="server_options"
                            :server-items-length="server_items_length"
                            :loading="table_loading"
                            must-sort
                            multi-sort
                            rows-per-page-message="Elementos por página: "
                            rows-of-page-separator-message="de"
                            empty-message="No hay datos disponibles"
                        >
                            <template #item-mes="{mes}">
                                {{ getMonthName(mes).toUpperCase() }}
                            </template>
                            
                            <template #item-edit_object="{created_by_user, id_objetivo, mes, anio, objetivo}">
                                <template v-if="created_by_user===username || belongGroup(getAdminGroups())">
                                <img :src=" require('@/assets/icons/pencil-edit.svg')"
                                     alt="Editar objetivo"
                                     class="pencil-edit"
                                     title="Editar objetivo"
                                     @click="selectedObjectiveData(created_by_user, id_objetivo, mes, anio, objetivo)"
                                >
                                </template>
                            </template>
                        </Vue3EasyDataTable>
                    </v-col>
                </v-row>
                <v-dialog v-model="open_modal_edit_objective">
                    <ModalEditObjective
                        :selected_objective="selected_objective"
                        :displayDialogEditObjective="displayDialogEditObjective"
                        :validateObjective="validateObjective"
                        :getGroupObjectives="getGroupObjectives"
                    ></ModalEditObjective>
                </v-dialog>
            </template>
            <template v-else>
                <v-row>
                    <v-col>
                        <span>Seleccione un grupo para continuar</span>
                    </v-col>
                </v-row>
            </template>

        </v-container>
    </template>
</template>

<script>
import Vue3EasyDataTable from "vue3-easy-data-table";
import {computed, onBeforeMount, ref, watch} from "vue";
import {createObjectiveWS, getAllObjectiveGroupsWS, getObjectivesByGroupWS} from "@/utils/objetivos_ffvv";
import {getMonthName, showNotification, variableIsDefined} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import {useRoute} from "vue-router";
import {belongGroup, getAdminGroups, hasPermission} from "@/utils/auth";
import {useStore} from "vuex";
import ModalEditObjective from "@/components/SalesForce/ModalEditObjective.vue";

export default {
    name: "ObjectiveForcesSalesView",
    methods: {getAdminGroups, belongGroup, variableIsDefined, getMonthName},
    components: {ModalEditObjective, Loading, Vue3EasyDataTable},
    setup() {
        const route = useRoute();
        const store = useStore();
        let username = computed(() => store.getters.getUsername);

        const headers = ref([
            {text: 'AÑO', value: 'anio', sortable: true},
            {text: 'MES', value: 'mes', sortable: true},
            {text: 'OBJETIVO', value: 'objetivo', sortable: true},
            {text: '', value: 'edit_object'},

        ])
        const server_options = ref({
            page: 1,
            rowsPerPage: 10,
            sortBy: 'anio',
            sortType: 'asc'
        });
        const server_items_length = ref(0)
        const table_items = ref([])
        const objective_groups = ref([])
        const loading = ref(false)
        const table_loading = ref(false)
        const table_filters = ref({})
        const new_objective_data = ref({})
        const new_objective_errors = ref({})
        const months = ref()
        const years = ref()

        const today = new Date();
        let error_objetive = ref('')
        let selected_objective = ref({})
        let open_modal_edit_objective = ref(false)
        let correct = true


        onBeforeMount(async () => {
            loading.value = true
            await hasPermission(route);
            await fillObjectiveGroups()
            fillMonthYearFilters()
            await getDateToday()
            loading.value = false

        })

        watch(server_options, async () => {
            if (variableIsDefined(table_filters.value['id_grupo_objetivos'])) {
                await getGroupObjectives()
            }
        }, {deep: true});

        async function getDateToday() {
            const options_date = {day: 'numeric', month: 'numeric', year: 'numeric'};
            const [day, month, year] = today.toLocaleDateString('es-ES', options_date).split('/');
            new_objective_data.value['month'] = {
                name: getMonthName(month).toUpperCase(),
                id: parseInt(month)
            };
            new_objective_data.value['year'] = {
                name: year,
                id: parseInt(year)
            };
        }

        /*
        *
        * Rellena los selectores para los filtros de año y mes
        *
        * */
        const fillMonthYearFilters = () => {
            let year_today = new Date().getFullYear();
            let max_year = year_today + 2;
            months.value = [...Array(12)].map((item, index) => {
                return {name: getMonthName(index + 1).toUpperCase(), id: index + 1}
            })
            years.value = [...Array(year_today - 2019 + 1)].map((item, index) => {
                return {name: max_year - index, id: max_year - index};
            });
        }

        /*
        *
        * OBTIENE TODOS LOS GRUPOS QUE HAY
        * DISPONIBLES A LOS QUE AÑADIR OBJETIVOS
        * DE FFVV O PARA CONSULTAR SUS OBJETIVOS
        *
        * */
        const fillObjectiveGroups = async () => {
            let response = await getAllObjectiveGroupsWS()
            if (variableIsDefined(response)) {
                if (response['result']) {
                    objective_groups.value = response['data']['groups']
                } else {
                    showNotification('error', response['errors'])
                }
            }

        }

        /*
        *
        * OBTIENE EL LISTADO DE LOS OBJETIVOS
        * DE FFVV DE UN GRUPO SELECCIONADO
        * SE LLAMA CADA VEZ QUE SE SELECCIONA UN GRUPO
        * O CADA VEZ QUE SE APLICA UN FILTRO
        *
        * */
        const getGroupObjectives = async () => {
            if (variableIsDefined(table_filters.value['id_grupo_objetivos'])) {
                table_loading.value = true
                let order_by = ''
                let order_direction = ''
                if (variableIsDefined(server_options.value.sortBy) && variableIsDefined(server_options.value.sortType)) {
                    order_by = server_options.value.sortBy;
                    order_direction = server_options.value.sortType;
                }
                const response = await getObjectivesByGroupWS(server_options.value.page, server_options.value.rowsPerPage, table_filters.value, order_direction, order_by)
                if (response['result']) {
                    table_items.value = response['data']['data']
                    server_items_length.value = response['data'].total
                } else {
                    showNotification('error', response['errors'])
                }
                table_loading.value = false
            }
        }


        /*
        *
        * AÑADE UN NUEVO OBJETIVO
        * AL GRUPO SELECCIONADO
        *
        * */
        const createNewObjective = async () => {
            table_loading.value = true
            if (await validateFormCreation()) {
                const response = await createObjectiveWS(table_filters.value['id_grupo_objetivos'], new_objective_data.value)
                if (response['result']) {
                    showNotification('success', 'Se ha creado correctamente el nuevo objetivo')
                    await getGroupObjectives()
                    new_objective_data.value['objective'] = ''
                } else {
                    showNotification('error', response['errors'])
                }
            }
            table_loading.value = false
        }

        async function searchWithFilters() {
            server_options.value.page = 1;
            await getGroupObjectives();
        }

        const clearFilters = () => {
            table_filters.value['month'] = null
            table_filters.value['year'] = null
        }

        const validateFormCreation = async () => {
            correct = true
            new_objective_errors.value = {}
            let {month, year, objective} = new_objective_data.value

            if (typeof month === 'object') {
                new_objective_data.value['month'] = month['id']
            }

            if (typeof year === 'object') {
                new_objective_data.value['year'] = year['id']
            }

            let validate = await validateObjective(objective)
            correct = validate.correct
            new_objective_data.value['objective'] = validate.objective
            new_objective_errors.value['objective'] = validate.show_error
            error_objetive.value = validate.txt_error

            if (correct) {
                table_items.value.forEach((objetive) => {
                    if (objetive.anio === new_objective_data.value['year'] && objetive.mes === new_objective_data.value['month']) {
                        new_objective_errors.value['repeated_date'] = true
                        correct = false
                    }
                })
            }
            return correct
        }

        async function validateObjective(objective) {
            const regex_only_number = /^\d*[\.,]?\d*$/g
            if (variableIsDefined(objective)) {
                if (regex_only_number.test(objective)) {
                    objective = createDecimalNumber(objective)
                    if (objective.length > 11) {
                        return {
                            'correct': false,
                            'show_error': true,
                            'txt_error': 'La cifra no puede ser superior a 99.999.999,99',
                            'objective': objective
                        }
                    } else {
                        return {
                            'correct': true,
                            'show_error': false,
                            'txt_error': '',
                            'objective': objective
                        }
                    }
                } else {
                    return {
                        'correct': false,
                        'show_error': true,
                        'txt_error': 'El objetivo debe ser un número',
                        'objective': objective
                    }
                }
            } else {
                return {
                    'correct': false,
                    'show_error': true,
                    'txt_error': 'El campo no puede estar vacío',
                    'objective': objective
                }
            }
        }

        function createDecimalNumber(number) {
            let formate_objective = number.replace(/,/g, ".")
            const fragment = formate_objective.split('.');
            if (fragment.length === 1) {
                formate_objective += '.00';
            } else {
                formate_objective = fragment[0] + '.' + (fragment[1] + '00').substring(0, 2);
            }
            return formate_objective;
        }

        function displayDialogEditObjective() {
            open_modal_edit_objective.value = !open_modal_edit_objective.value
        }

        function selectedObjectiveData(created_by_user, id_objective, month, year, old_objective) {
            selected_objective.value = {
                'created_by_user': created_by_user,
                'id_objective': id_objective,
                'month': month,
                'year': year,
                'old_objective': old_objective
            }
            displayDialogEditObjective()

        }


        return {
            createNewObjective,
            getGroupObjectives,
            clearFilters,
            searchWithFilters,
            validateObjective,
            displayDialogEditObjective,
            selectedObjectiveData,

            headers,
            loading,
            months,
            new_objective_data,
            new_objective_errors,
            error_objetive,
            objective_groups,
            server_items_length,
            server_options,
            table_filters,
            table_items,
            table_loading,
            years,
            username,
            open_modal_edit_objective,
            selected_objective,
        }
    }
}
</script>

<style lang="scss">
.expansion-personalized {
    border: 1px solid $primary-color-1;

    .title-expansion-panel {
        font-size: 16px;
        min-height: 45px !important;
    }

    .v-expansion-panel__shadow {
        box-shadow: none;
    }

    .v-expansion-panel-title--active {
        background: $primary-color-1;
        opacity: 1;
        color: $white !important;
        font-weight: bold;
    }


}

.delete-filter {
    filter: brightness(0) saturate(100%) invert(61%) sepia(76%) saturate(5574%) hue-rotate(325deg) brightness(97%) contrast(95%);
    width: 30px;
    height: auto;
    cursor: pointer;

    &:hover {
        filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(4560%) hue-rotate(334deg) brightness(81%) contrast(101%);
    }
}

.pencil-edit {
    width: 20px;
    cursor: pointer;
}
</style>
import store from "@/store";
import {aicoreBackendPetition} from "@/utils/aicore_petition";

export async function getAllRolesWS() {
    return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_roles", {},{ext: true});
}

export async function addRolWS(rol_name) {
    var data = {};
    data.nombre = rol_name
    data.username = store.getters.getUsername

    return await aicoreBackendPetition('POST', "/EmbedLooker/add_rol", data,{ext: true});
}

export async function saveRolWS(id_rol, nombre_rol) {
    var data = {};
    data.id_rol = id_rol
    data.nombre = nombre_rol
    data.username = store.getters.getUsername

    return await aicoreBackendPetition('POST', "/EmbedLooker/save_rol", data,{ext: true});

}

export async function getAllDashboardsWS() {
    return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_dashboards", {},{ext: true});
}

export async function getAllRolesDashboardsWS(id_dashboard) {
    var data = {}
    data.id_dashboard = id_dashboard

    return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_roles_dashboard", data,{ext: true});

}

export async function saveRolesDashboardWS(id_dashboard, roles) {
    let data = {}
    data.id_dashboard = id_dashboard
    data.roles = roles
    data.username = store.getters.getUsername
    data = JSON.stringify(data)

    return await aicoreBackendPetition('POST', "/EmbedLooker/save_roles_dashboard", data, {content_type: "application/json;",ext: true})
}


export async function getAllGroupsLDAPWS() {
    return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_grupos_ldap", {},{ext: true});
}

export async function getAllRolesGroupLDAPWS(id_grupo) {
    var data = {};
    data.id_grupo = id_grupo

    return await aicoreBackendPetition('GET', "/EmbedLooker/get_all_roles_grupos_ldap", data,{ext: true});
}

export async function saveRolesGroupWS(id_grupo, roles_activos) {
    let data = {}
    data.id_grupo = id_grupo
    data.roles = roles_activos
    data.username = store.getters.getUsername
    data = JSON.stringify(data)

    return await aicoreBackendPetition('POST', "/EmbedLooker/save_roles_grupo", data, {content_type: "application/json;",ext: true})
}



<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="text-center container-loading">
                <img :src="require('@/assets/img/loading.gif')"
                     alt="Loading Gif" class="loading-gif-m">
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "Loading"
}
</script>

<style scoped>

</style>
import axios from "axios";
import {getAicoreToken} from "@/utils/auth";

/**
 * @param type (string) => GET, POST. Obligatorio
 * @param url (string) => URL DEL WS. Obligatorio
 * @param data (array) => PARÁMETROS QUE NECESITA EL WS. Obligatorio
 * @param petition (string) => AJAX, AXIOS. Opcional
 * @param content_type (string) Opcional
 * @param ext (boolean) Opcional
 * @param nlp (boolean) Opcional
 * @returns {Promise}
 */
export async function aicoreBackendPetition(type, url, data, {petition = 'ajax', content_type = 'application/x-www-form-urlencoded; charset=UTF-8', ext = false, nlp = false} = {}) {

    var response = await getAicoreToken(ext);
    var token = response['access_token'];

    let env_url = process.env.VUE_APP_AICORE_URL_API;

    if(ext){
        //env_url = process.env.VUE_APP_AICORE_URL_API_LOOKER;
        env_url = process.env.VUE_APP_AICORE_URL_API_EXT;
    }else if (nlp){
        env_url = 'https://aicorenlp.euroinnova.edu.es/api/'
    }

    switch (petition.toLowerCase()) {
        case 'ajax':
            return $.ajax({
                type: type,
                url: env_url + url,
                data: data,
                headers: {
                    'content-type': content_type,
                    'Authorization': 'Bearer ' + token
                }
            });
        case 'axios':
            switch (type.toLowerCase()) {
                case 'post':
                    return axios.post(env_url + url,
                        data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
                case 'get':
                    return axios.get(env_url + url + data,
                        {
                            headers: {
                                'content-type': content_type,
                                'Authorization': 'Bearer ' + token
                            }
                        }
                    )
            }
    }
}


import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getBillsWS(start_date, final_date) {
    let params = {
        fecha_ini: start_date,
        fecha_fin: final_date,
    }
    try {
        return await aicoreBackendPetition('GET', `/aicorebq/revisa_que_facturacion_cuadra_por_empresas?`, params, {content_type: "application/json;",ext: true});
    }catch (e){
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'aicorebq/revisa_que_facturacion_cuadra_por_empresas');
    }
}
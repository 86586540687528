<template>
 <v-container class="general-container">
        <div class="container-loading" v-if="loading">
            <img class="loading-gif" :src="require('@/assets/img/loading.gif')"
                 alt="Loading">
        </div>
        <v-row align="center" v-if="!loading">
            <v-col cols="6">
                <v-select
                    class="select-secondary"
                    aria-label="Default select example"
                    :items="all_portales"
                    v-model="selected_portal_id"
                    item-title="url"
                    item-value="id_portal"
                    @update:modelValue="getSitemap(selected_portal_id)"
                    hide-details
                    bg-color="white"
                    no-data-text="No hay datos disponibles"
                ></v-select>
            </v-col>
            <v-col cols="6">
                <v-text-field
                    v-model="search_value"
                    placeholder="¿Qué quieres buscar?"
                    append-inner-icon="mdi-magnify"
                    class="text-field-secondary"
                    single-line
                    bg-color="white"
                    color="transparent"
                    hide-details
                ></v-text-field>
            </v-col>
        </v-row>
        <v-row v-if="!loading">
            <div class="container-loading" v-if="loading_table">
                <img class="loading-gif" :src="require('@/assets/img/loading.gif')"
                     alt="Loading">
            </div>
            <v-col cols="12">
            <Vue3EasyDataTable
                class="row-table"
                v-if="!loading_table"
                :headers="columns_table"
                :items="all_sitemaps"
                buttons-pagination
                show-index
                :rows-per-page="13"
                :search-field="search_field"
                :search-value="search_value"
                rows-per-page-message="Elementos por página: "
                rows-of-page-separator-message="de"
                empty-message="No hay datos disponibles"
            >
                <template #item-id_sitemap="{id_sitemap}">
                    <v-btn class="btn button-bg-primary-color" target="_blank" :href="'/urls-sitemap?id='+id_sitemap"
                         >Listado Urls</v-btn>
                </template>
                <template #item-path="{path}">
                    <a class="column-path" id="copy-path" target="_blank"
                       :href="'https://'+path">{{ path }} </a>

                </template>
                <template #item-last_submitted="{last_submitted}">
                    {{last_submitted.substring(0, 10)}}
                </template>
                <template #item-last_downloaded="{last_downloaded}">
                    {{last_downloaded.substring(0, 10)}}
                </template>
                <template #item-iconoCopiar="{path}">
                    <img :src="require(`@/assets/icons/copy.svg`)" alt="Copiar" class="icon-clipboard"
                         title="Copiar"
                         id="copy" @click="copyClipboard(path)">
                </template>
            </Vue3EasyDataTable>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {onBeforeMount, ref} from "vue";
import {getPortalsWS, getSitemapWS} from "@/utils/manager_sitemap";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import SitemapDataView from "@/views/Sitemap/SitemapDataView";
import {showNotification} from "@/utils/aux_functions";

export default {
    name: "SitemapsDataView",
    components: {Vue3EasyDataTable, SitemapDataView},
    setup: function () {
        let selected_portal_id = ref(65);
        let all_portales = ref([]);
        let all_sitemaps = ref([]);

        const loading = ref(true);
        const loading_table = ref(false);

        const search_field = ref(["path", "last_submitted", "is_pending", "is_sitemap_index", "last_downloaded", "warnings", "errors", "submitted", "redirections", "http_error_codes"]);
        const search_value = ref("");
        const columns_table = ref([
            {text: 'ID SITEMAP', value: 'id_sitemap', sortable: true},
            {text: 'PATH', value: 'path', sortable: true},
            {text: '', value: 'iconoCopiar'},
            {text: 'LAST SUBMITTED', value: 'last_submitted', sortable: true},
            {text: 'IS PENDING', value: 'is_pending', sortable: true},
            {text: 'IS SITEMAP INDEX', value: 'is_sitemap_index', sortable: true},
            {text: 'LAST DOWNLOADED', value: 'last_downloaded', sortable: true},
            {text: 'WARNINGS', value: 'warnings', sortable: true},
            {text: 'ERRORS', value: 'errors', sortable: true},
            {text: 'SUBMITTED', value: 'submitted', sortable: true},
            {text: 'REDIRECTIONS', value: 'redirections', sortable: true},
            {text: 'ERROR CODES', value: 'http_error_codes', sortable: true}
        ]);


        onBeforeMount(async () => {
            all_portales.value = await getPortalsWS();
            all_sitemaps.value = await getSitemapWS(selected_portal_id.value);
            loading.value = false
        })

        async function getSitemap(id_portal_seleccionado) {
            loading_table.value = true
            all_sitemaps.value = await getSitemapWS(id_portal_seleccionado);
            loading.value = false
            loading_table.value = false
        }

        function copyClipboard(path) {
            navigator.clipboard.writeText(path);
            showNotification("success", "Enlace copiado")
        }
        return {
            selected_portal_id,
            loading,
            loading_table,
            all_portales,
            all_sitemaps,
            columns_table,
            search_field,
            search_value,
            getSitemap,
            copyClipboard,
        }
    }
};
</script>

<style lang="scss">
@import '@/styles/components/sitemaps-data.scss';
</style>

<template>
    <v-col  cols="12" xs="12" sm="4" md="4" lg="4" class="col-btn-admin">
            <router-link :to=dashboard.ruta>
                <v-btn class="btn button-secondary btn-admin">
                    <v-row class="admin-btns-content">
                        <v-col >
                            <img :src="require('@/assets/icons/'+ dashboard.icono)" alt="Icono" class="icon-roles">
                        </v-col>
                        <v-col>
                            <p class="input-title">{{ dashboard.titulo }}</p>
                        </v-col>
                    </v-row>
                </v-btn>
            </router-link>
    </v-col>
</template>

<script>

export default {
    props: {
        dashboard: Object
    },
}


</script>

<style lang="scss">
@import "@/styles/components/manager-admin.scss";
</style>
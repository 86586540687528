<template>
    <notifications position="bottom right"/>
    <template v-if="username && token">
        <div id="main-app">
            <div id="page-content-wrapper">
                <NavBar/>
                <button type="button"
                        class="toggle-navbar animated is-closed btn-displayed">
                    <img :src="require(`@/assets/icons/arrow-${icon_navbar}.svg`)"
                         id="icon-open-navbar" alt="Abrir menú">
                </button>

<!--                <SideBar/>-->
<!--                <button type="button"-->
<!--                        class="toggle-sidebar animated is-closed btn-displayed-sidebar">-->
<!--                    <img :src="require(`@/assets/icons/arrow-${icon_sidebar}.svg`)"-->
<!--                         id="icon-open-sidebar" alt="Abrir menú">-->
<!--                </button>-->
            </div>
            <div class="container-main-app personalize-scroll">
                <router-view/>
            </div>
        </div>
    </template>
    <AuthView v-else></AuthView>
</template>

<script>

import AuthView from "@/views/Auth/AuthView.vue";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {logout} from "@/utils/auth";
import NavBar from "@/components/Navigation/Navbar.vue";
import SideBar from "@/components/Navigation/SideBar";
import {initSweetNotification} from "@/utils/sweetalert";


export default {
    components: {
        SideBar,
        AuthView,
        NavBar,
    },
    setup() {
        const store = useStore();
        let icon_navbar = ref("up");
        let icon_sidebar = ref("open");
        let username = computed(() => store.getters.getUsername);
        let token = computed(() => store.getters.getToken);

        onBeforeMount(() => {
            existsLoggedUser();
            initSweetNotification()
        })

        const existsLoggedUser = () => {
            let hours = 4
            let saved = localStorage.getItem('saved')
            if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
                logout()
            }
            if (username.value && token.value) {
                store.commit('setUsername', username.value.toLowerCase())
                store.commit('setAicoreToken', token.value)
            }
        }

        $(document).ready(function () {
            var triggerSidebar = $('.toggle-sidebar');
            var is_closed_sidebar = false;

            $('body').on('click', '.toggle-sidebar', function () {
                toggleSidebar();
                $('#main-app').toggleClass('toggledSidebar');
            });



            var trigger = $('.toggle-navbar');
            var is_closed = false;

            $('body').on('click', '.toggle-navbar', function () {
                toggleNavbar();
                $('#main-app').toggleClass('toggled');
            });


            function toggleNavbar() {
                if (is_closed) {
                trigger.removeClass('is-open');
                    trigger.addClass('is-closed');
                } else {
                    trigger.removeClass('is-closed');
                    trigger.addClass('is-open');
                }
                icon_navbar.value = is_closed ? "up" : "down";
                is_closed = !is_closed;
            }

            function toggleSidebar() {
                if (is_closed_sidebar) {
                    triggerSidebar.removeClass('is-open');
                    triggerSidebar.addClass('is-closed');
                } else {
                    triggerSidebar.removeClass('is-closed');
                    triggerSidebar.addClass('is-open');
                }
                icon_sidebar.value = is_closed_sidebar ? "open" : "left";
                is_closed_sidebar = !is_closed_sidebar;
            }
        });

        return {
            username,
            token,
            icon_navbar,
            icon_sidebar,
        }
    }
}
</script>

<style scoped lang="scss">
@import "styles/components/navbar";
@import "styles/components/sidebar";
@import "@/styles/components/sweetalert/notification-toast.scss";

</style>

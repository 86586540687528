import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.js"
import "@popperjs/core"
import Notifications from "@kyvg/vue3-notification"



// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {es} from "vuetify/locale";

import "@/styles/general.scss";
import "@/styles/buttons.scss";
import "@/styles/inputs.scss";
import "@/styles/modal.scss";


const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    locale: {
        messages: {es}
    },
})


const $ = require('jquery')
const jQuery = require('jquery')
window.$ = $
window.jQuery = jQuery

createApp(App).use(store).use(router).use(vuetify).use(Notifications).mount('#app')
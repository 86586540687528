<template>
    <BaseModalTemplate
        card_extra_classes="personalize-scroll modal-width-xs"
        :title="title"
        :open-close-dialog="displayModal"
        :card_disabled="disabled_close_modal"
        :hide_actions="true"
        body_extra_classes="modal-height-xs"
    >
        <template #body-modal-content>
            <template v-if="!variableIsDefined(new_video)">
                <v-row>
                    <v-col cols="12" class="text-center align-self-center">
                        <img :src="require('@/assets/img/loading.gif')"
                             alt="Loading Gif" class="loading-xxs">
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-center align-self-center">
                        <span class="msg-wait">No cierre la pantalla, el video puede tardar unos minutos en generarse...</span>
                    </v-col>
                </v-row>
            </template>
            <div v-else class="m-2">
                <v-row>
                    <v-col cols="12" class="align-self-center">
                        <span class="text-modal "><b>Título:</b> {{ title_video }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="text-modal pb-0 "><b>Enlace generado para el video:</b></v-col>
                    <v-col cols="10" class="text-start truncate-text align-self-center">
                        <span class="text-modal">{{ new_video }}</span>
                    </v-col>
                    <v-col cols="2" class="text-center align-self-center">
                        <img class="icon-copy"
                             :src="require('@/assets/icons/clipboard.svg')"
                             alt="Clipboard icon" @click="copyUrl(new_video)"/>
                    </v-col>
                </v-row>
            </div>
        </template>
    </BaseModalTemplate>
</template>

<script>
import Loading from "@/components/Generic/Loading.vue";
import {copyClipboard, variableIsDefined} from "@/utils/aux_functions";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalNewProductVideo",
    methods: {variableIsDefined},
    components: {BaseModalTemplate, Loading},
    props: {
        displayModal: Function,
        new_video: String,
        disabled_close_modal: Boolean,
        title_video: String,
        title: String
    },
    setup() {
        const copyUrl = async (valor) => {
            await copyClipboard(valor)
        }
        return {copyUrl}
    }
}
</script>


<style scoped lang="scss">
@import "@/styles/components/product-video.scss";
</style>
import {createStore} from 'vuex'

export default createStore({
    state: {
        username: '',
        aicore_token: '',
        group: '',
    },
    getters: {
        getToken(state) {
            let token = state.aicore_token
            if (token === "") {
                token = localStorage.getItem('token')
            }
            return token;
        },

        getUsername(state) {
            let username = state.username
            if (username === "" && localStorage.getItem('username')) {
                try {
                    username = atob(localStorage.getItem('username'))
                } catch (err) {
                    localStorage.clear();
                }
            }
            return username.toLowerCase();
        },

        getUserGroup(state) {
            let group = state.group
            if (group === "" && localStorage.getItem('group')) {
                try {
                    group = atob(localStorage.getItem('group'))
                } catch (err) {
                    localStorage.clear();
                }
            }
            return group;
        }
    },
    mutations: {
        setUsername(state, payload) {
            state.username = payload;
        },
        setAicoreToken(state, payload) {
            state.aicore_token = payload
        },
        setGroup(state, payload) {
            state.group = payload
        }
    },
    actions: {},
    modules: {}
})
import {showNotification} from "@/utils/aux_functions";
import {escicBackendPetition} from "@/utils/escic_petition";
import store from "@/store";

export async function getAllObjectiveGroupsWS() {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {}

    try {
        return await escicBackendPetition('POST', 'getObjectiveGroups', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getObjectiveGroups');
    }
}

export async function getObjectivesByGroupWS(page = 1, rows_per_page = 20, filters, order_direction = "", order_by = "") {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {
        year: filters['year'],
        month: filters['month'],
        id_grupo_objetivos: filters['id_grupo_objetivos'],
        page_size: rows_per_page,
        order_direction: order_direction,
        order_by: order_by,
    }

    try {
        return await escicBackendPetition('POST', 'getGroupObjectivesList?page='+page, params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'getGroupObjectivesList');
    }
}

export async function createObjectiveWS(group_id, {month, year, objective}) {
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {
        id_grupo_objetivos: group_id,
        month: month,
        year: year,
        objective: objective,
    }

    try {
        return await escicBackendPetition('POST', 'createObjective', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'createObjective');
    }
}

export async function updateObjectiveWS(id_objective, new_objective){
    let params_token = {
        usuario_actual: store.getters.getUsername,
    }

    let params = {
        id_objective: id_objective,
        new_objective: new_objective,
    }

    try {
        return await escicBackendPetition('POST', 'updateObjective', params, params_token)
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'updateObjective');
    }
}
<template>
    <BaseModalTemplate
        card_extra_classes="modal-width-xs"
        body_extra_classes="modal-height-xs"
        title="Actualizar objetivo"
        :open-close-dialog="displayDialogEditObjective"
        :loading="loading"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <p class="input-title ms-1">Año</p>
                    <v-text-field
                        v-model="selected_objective.year"
                        class="input-error text-field-primary"
                        variant="underlined"
                        hide-details
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                    <p class="input-title ms-1">Mes</p>
                    <v-text-field
                        v-model="name_month"
                        class="input-error text-field-primary"
                        variant="underlined"
                        hide-details
                        disabled
                    >
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                    <p class="input-title ms-1">Objetivo *</p>
                    <v-text-field
                        v-model="new_objective"
                        variant="underlined"
                        class="input-error text-field-primary"
                        :error="validate.show_error"
                        hint="Solo se almacenan dos decimales, sin redondeo. Formato: xxx.xx"
                    >
                    </v-text-field>
                    <span v-if="validate.show_error" class="input-error msg-error">{{
                            validate.txt_error
                        }}</span>
                </v-col>
            </v-row>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        class="btn button-secondary"
                        hide-details
                        @click="updateObjective"
                        :disabled="disabled_save">
                        GUARDAR
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {updateObjectiveWS} from "@/utils/objetivos_ffvv";
import {onBeforeMount, ref} from "vue";
import {getMonthName, showNotification} from "@/utils/aux_functions";
import Loading from "@/components/Generic/Loading.vue";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "ModalEditObjective",
    components: {BaseModalTemplate, Loading},
    props: {
        selected_objective: Object,
        displayDialogEditObjective: Function,
        validateObjective: Function,
        getGroupObjectives: Function,


    },
    setup(props) {
        let loading = ref(false)
        let name_month = ref('')
        let new_objective = ref('')
        let validate = ref({})
        let disabled_save = ref(false)

        onBeforeMount(async () => {
            loading.value = true
            name_month.value = (getMonthName(props.selected_objective.month)).toUpperCase();
            new_objective.value = props.selected_objective.old_objective
            loading.value = false
        })

        async function validateObjective() {
            validate.value = await props.validateObjective(new_objective.value)
            if (validate.value.correct) {
                new_objective.value = validate.value.objective
                if (new_objective.value === props.selected_objective.old_objective) {
                    validate.value.correct = false
                    validate.value.show_error = true
                    validate.value.txt_error = 'No se han detectado cambios'
                }
            }
        }


        async function updateObjective() {
            await validateObjective()
            if (validate.value.correct) {
                disabled_save.value = true
                let response = await updateObjectiveWS(props.selected_objective.id_objective, new_objective.value)
                if (response['result']) {
                    showNotification('success', 'Objetivo actualizado correctamente')
                    await props.getGroupObjectives()
                    await props.displayDialogEditObjective()
                } else {
                    showNotification('error', response['errors'])
                }
                disabled_save.value = false
            }
        }


        return {
            updateObjective,

            loading,
            name_month,
            new_objective,
            validate,
            disabled_save,
        }
    }
}
</script>


<style scoped lang="scss">
.loading-personalice {
    width: 10vw;
}

.personalize-modal-edit-obj {
    .content-modal {
        min-height: 17vh !important;
    }

    @media (max-width: 1024px) {
        .content-modal {
            min-height: 15vh !important;
        }
    }
}

</style>
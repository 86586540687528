<template>
    <a :href="'/dashboards/' + content_dashboard.id_dashboard">
        <div class="dashboard-box p-3">
            <div class="title">{{ content_dashboard.title }}</div>
            <div class="description">{{ content_dashboard.description }}</div>
        </div>
    </a>
</template>

<script>

export default {
    name: 'DashboardTable',
    props: {
        content_dashboard: Object
    },
}
</script>

<style lang="scss" scoped>
@import "@/styles/components/dashboard-table.scss";
</style>
<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="8" lg="9">
                <h1 class="mb-5 title">Prototipo de categorización de Incidencias</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <p> Para resolver el problema de clasificación de las incidencias de forma automática
                    utilizaremos
                    un método que consiste en calcular la similitud entre el asunto y observación de la
                    incidencia con
                    un conjunto de frases claves que se ha observado en la base de datos.
                </p>
            </v-col>
            <v-col cols="12">
                <v-form  @submit.prevent="null">
                    <v-row>
                        <v-col cols="12">
                            <span class="input-title">
                                        Introduzca el asunto de la incidencia *</span>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    type="text"
                                    v-model="subject"
                                    placeholder="Asunto de la incidencia"
                                    required
                                    variant="underlined"
                                    hide-details
                                    :error="error_name"
                                    class="input-error text-field-primary "
                                    :class="{error :error_name}"
                            >
                            </v-text-field>
                            <span v-if="error_name"
                                  class="input-error msg-error">
                                        El campo no puede estar vacío
                                    </span>
                        </v-col>
                    </v-row>
                </v-form>
                <v-row>
                    <v-col cols="12">
                        <v-btn class="btn button-primary" @click="onSubmit">Categorizar</v-btn>
                    </v-col>
                </v-row>

            </v-col>
            <v-col cols="12">
                <ul>
                    <li v-for="(categoria_sugerida,index) in categories_suggested" :key="index">
                        <span> {{ index + 1 }} - {{
                                categoria_sugerida['class']
                            }} - {{ formatNumber(categoria_sugerida['similitud']) }} </span>
                        <span class="m-3" v-if="index===0"><i class="fa-solid fa-trophy"></i></span>
                        <span class="m-3" v-else-if="index===1"> <i class="fa-solid fa-shield ml-2"></i></span>
                        <span class="m-3" v-else><i class="fa-solid fa-times"></i></span>
                    </li>
                </ul>
            </v-col>
            <v-col cols="12">
                <p>
                    Los valores de similitud estarán entre 0 y 1, representan porcentajes. La coincidencia es
                    perfecta cuando
                    vale 1(100%). Sin embargo, los valores decrecen conforme el asunto de la incidencia crece
                    por las propiedades
                    del algoritmo, pero la diferencia relativa entre las categorías sigue siendo notable.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {variableIsDefined} from "@/utils/aux_functions";

export default {
    name: "IncidencesView",
    setup() {
        const store = useStore();
        const categories_suggested = ref([
            {
                'class': 'Docencia',
                'similitud': 0.9
            },

            {
                'class': 'Soporte Técnico',
                'similitud': 0.5
            },

            {
                'class': 'Facturación y Cobro',
                'similitud': 0.2
            },

            {
                'class': 'Secretaria',
                'similitud': 0.2
            },

            {
                'class': 'Desconocido',
                'similitud': 0.2
            },
        ]);

        let subject = ref("");
        let aicore_token = computed(() => store.getters.getToken).value;
        let error_name = ref(false);

        function onSubmit() {
            error_name.value = false;
            if (variableIsDefined(subject.value)) {
                let base_url = process.env.VUE_APP_AICORE_URL_API + "/incidencias/get_live_pred?cadena_a=";

                $.ajax({
                    type: 'GET',
                    url: base_url + subject.value,
                    headers: {
                        'Authorization': 'Bearer ' + aicore_token
                    },
                    success: function (response) {
                        categories_suggested.value = response
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        console.log(xhr);
                        console.log('thrownError: ' + thrownError);
                    }
                })
            } else {
                error_name.value = true;
            }
        }

        const formatNumber = (value) => {
            return value.toFixed(8);
        }

        return {
            formatNumber,
            onSubmit,
            categories_suggested,
            subject,
            error_name,
        }
    }
}
</script>

<style lang="scss">
.title {
    width: 100%;
    text-align: center;
}

.form-label-incidence {
    font-weight: bold;
}
</style>
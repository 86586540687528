import {aicoreBackendPetition} from "@/utils/aicore_petition";
import {showNotification} from "@/utils/aux_functions";

export async function getAdhocDashboardsWS() {
    try {
        return await aicoreBackendPetition('GET', "/LookerAdhoc/adhoc_dashboards", {}, {ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/adhoc_dashboards')

    }
}

export async function getAdhocDashboardLooksWS(dashboard_id) {
    try {
        return await aicoreBackendPetition('GET', "/LookerAdhoc/adhoc_dashboard_looks/{dashboard_id}?adhoc_dashboard_id="+dashboard_id, {}, {ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/adhoc_dashboard_looks')

    }
}
export async function runAdhocLookWS(look_id, filters = {}) {
    try {
        return await aicoreBackendPetition('POST', "/LookerAdhoc/run_adhoc_look/"+look_id, JSON.stringify(filters), { content_type: 'application/json', ext: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/run_adhoc_look')
        console.log(e)
    }
}

export function getDateFilterNames(id_looker) {
    switch (id_looker) {
        case 1:
        case 3:
        case 14:
        case 15:
        case 16:
        case 17:
        case 19:
            return 'matriculas.date_filter'
        case 5:
        case 8:
            return null
        case 6:
        case 7:
        case 13:
        case 18:
        case 22:
            return 'matriculas.fecha_date'
        case 31:
        case 32:
        case 9:
            return 'objetivos_ventas.date'
        case 25:
        case 26:
        case 27:
            return 'llamadas.fecha_llamada_date'
    }
}

/*Obtiene un array con todos las claves que son nodos hoja*/
export const getObjectKeyNames = (object) => {
    let row_names = []
    try {
        for (const [key, value] of Object.entries(object)){
            if (typeof value !== 'object' || (value == null)){
                if(!row_names.some(element => element === key)){
                    row_names.push(key)
                }
            }else{
                row_names.push(...getObjectKeyNames(value).filter(data => !row_names.some(row => row === data)))
            }
        }
        return row_names
    }catch (e){
        console.log(e)
    }
}

/*Obtiene un array con todos los valores de cada objeto*/
export const getObjectValue = (object) => {
    let row_values = []

    for (const [key, value] of Object.entries(object)){
        if (typeof value !== 'object' || (value == null)){
            row_values.push(value)
        }else{
            row_values.push(...getObjectValue(value))
        }
    }
    return row_values
}


//OBTIENE LA COMPARATIVA DE DATOS CON RESPECTO AL AÑO ANTERIOR
export async function getComparativeLastYearWS(nickname_tecnico_innotutor,fecha_inicial,fecha_final) {
    try {
        const filters = {nickname_tecnico_innotutor,fecha_inicial, fecha_final}
        return await aicoreBackendPetition('GET', "/gamificacion/get_comparative_last_year", filters, {
            content_type: 'application/json',
            ext: true
        })
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + ' /LookerAdhoc/run_adhoc_look')
        console.log(e)
    }
}


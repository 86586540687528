<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="section-title">
                <h2 class="main-title-page">Registro de incidencias de matrículas directas</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" lg="3" >
                <v-btn @click="openForm"
                       size="large"
                       class="btn button-primary"
                >Registrar incidencia
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import {onBeforeMount, onMounted} from "vue";

export default {
    name: "IncidencesEnrollmentsView",
    setup: function () {
        onBeforeMount(async () => {
            await getForm();
        });

        async function getForm() {
            $.ajax({
                url: "https://innotic.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1jmxwi/b/8/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=es-ES&collectorId=7bc38d2c",
                type: "get",
                cache: true,
                dataType: "script"
            });
        }

        function openForm() {
            $('#atlwdg-trigger').click();
        }

        return {
            openForm
        }
    }
}
</script>

<style lang="scss">
.atlwdg-trigger.atlwdg-TOP {
    display: none !important;
}
</style>
<template>
    <v-card class="card-calendar">
        <v-row>
            <v-col  xs="8" sm="7" lg="8" class="align-self-center">
                <span class="ms-2">{{ date_calendar }}</span>
            </v-col>
            <v-col  xs="2" sm="5" lg="4" class="text-end align-self-center">
                <v-menu location="end" :v-model="false" :close-on-content-click="false" :disabled="disabled_calendar">
                    <template v-slot:activator="{ props }">
                        <img src="../../assets/icons/calendar.svg" alt="Calendario"
                             class="calendar-icon"
                             v-bind="props">
                    </template>
                    <v-locale-provider locale="es">
                        <v-date-picker
                            v-model="date_selected"
                            color="blue-lighten-1"
                            :max="today"
                            hide-header
                        ></v-date-picker>
                    </v-locale-provider>
                </v-menu>
                <v-icon alt="Eliminar filtros"
                        class="delete-filter me-2" @click="deleteFilters">mdi-delete</v-icon>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
import {onBeforeMount, onBeforeUpdate, ref, watch} from "vue";
import {useDate} from "vuetify";
import {variableIsDefined} from "@/utils/aux_functions";
import {useCalendar} from "@/utils/Composables/useCalendar";

export default {
    name: "Calendar",
    props: {
        disabled_calendar: {
            type: Boolean,
            default: false,
        },
        block_dates: {
            type: Boolean,
            default: true,
        },
        accept_default_date: {
            type: Boolean,
            default: false,
        },
        default_date: String
    },
    setup(props) {
        const date_selected = ref() //Fecha seleccionada actualmente
        const date_calendar = ref('dd/mm/aaaa') //Lo que se muestra en el input del calendario
        const today = ref("") //Fecha máxima que permite seleccionar el calendario si el prop block_dates = true

        const {getDateComponents, formatDefaultDateView} = useCalendar()

        onBeforeMount(() => {
            if (props.accept_default_date){
                const {day, month, year} = getDateComponents(props.default_date, true)
                date_calendar.value = props.default_date
                date_selected.value = new Date(year,month-1,day)
            }
            if (props.block_dates) {
                const {day,year,month} = getDateComponents(new Date())
                today.value = `${month}/${day}/${year}`
            }
        })

        onBeforeUpdate(()=>{
            if (props.accept_default_date){
                date_calendar.value = props.default_date
            }
        })

        //ACTUALIZA EL VALOR DEL INPUT CADA VEZ QUE SE SELECCIONA UNA FECHA
        watch(date_selected, () => {
            sendDate()
        })

        function sendDate() {
            if (variableIsDefined(date_selected.value)){
                return (date_calendar.value = formatDefaultDateView(date_selected.value))
            }
        }

        const deleteFilters = () => {
            if (!props.disabled_calendar){
                date_selected.value = null
                date_calendar.value = 'dd/mm/aaaa'
            }
        }

        return {
            sendDate,
            deleteFilters,

            date_selected,
            date_calendar,
            today,
        }
    }
}
</script>


<style scoped lang="scss">

.card-calendar {
    padding: 0 10px;
    display: flex;
    height: 56px;
    box-shadow: none;
    border: 1px solid $gray-light;
    border-radius: 10px;

    .calendar-icon {
        filter: $filter-primary-color-1;
        width: 30px;
        &:hover {
            cursor: pointer;
            filter: $filter-primary-color-2;
        }
    }
}

.v-sheet.v-theme--light.v-picker.v-date-picker.v-date-picker--month {
    border-radius: 10px;
    border: 1px solid $gray-medium;
}

</style>
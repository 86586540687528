<template>
    <BaseModalTemplate
        :title="course_active.titulo"
        :open-close-dialog="showInfoModal"
        card_extra_classes="w-75"
    >
        <template #body-modal-content>
            <div v-if="course_active.certificaciones && course_active.certificaciones.length > 0">
                <strong>Certificado por:</strong>
                <ul v-for="item in course_active.certificaciones">
                    <li>{{ item }}</li>
                </ul>
            </div>
            <div v-if="course_active.homologaciones && course_active.homologaciones.length > 0">
                <strong>Homologado por:</strong>
                <ul v-for="item in course_active.homologaciones">
                    <li>{{ item }}</li>
                </ul>
            </div>
        </template>
        <template #actions-modal>
            <v-row>
                <v-col cols="12">
                    <v-btn
                        class="btn button-secondary"
                        hide-details
                        @click="showInfoModal">
                        Cancelar
                    </v-btn>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>
<script>

import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";

export default {
    name: "CertificationModal",
    components: {BaseModalTemplate},

    props: {
        course_active: Object,
        showInfoModal: Function,

    },


    setup(props) {


        return {}
    },
}
</script>
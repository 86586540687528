<template>
    <h3>{{title}}</h3>
    <div :id="table_id" class="col-table-json personalize-scroll">

    </div>
</template>

<script>
import {onBeforeMount, onMounted, ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import md5 from "md5";
import {getObjectKeyNames, getObjectValue} from "@/utils/looker_utils";

export default {
    name: "LargeTablePivoted",
    props: {
        json_table_chart: Array,
        title: String,
    },
    setup(props){
        const table_chart = props.json_table_chart
        const map_country = new Map()
        const table_id  = ref()

        onBeforeMount(()=> {
            table_id.value = md5(props.title)
        })

        onMounted(() => {
            createTableFromJSON()
        })

        const createTableFromJSON = () => {
            const $table = $('#'+table_id.value)

            table_chart.forEach((table_element) => {
                let index = 0
                const final_obj = {}

                let country_key = Object.keys(table_element).filter(key => typeof table_element[key] !== 'object' || table_element[key] === null)[0]
                let country = table_element[country_key]
                delete table_element[country_key]

                let object_value_0 = getObjectValue(table_element)

                let obj_primary_keys = getObjectKeyNames(table_element)
                let obj_secundary_keys = Object.keys(table_element).filter(key => typeof table_element[`${key}`] === 'object')

                obj_primary_keys.forEach(element => final_obj[element] = {})

                obj_secundary_keys.forEach((secundary_key) => {
                    Object.entries(final_obj).forEach((entry) => {
                        final_obj[`${entry[0]}`][`${secundary_key}`] = object_value_0[index]
                        index++
                    })
                })

                map_country.set(country, final_obj)
            })

            let obj_primary_keys = getObjectKeyNames(table_chart[0])
            let obj_secundary_keys = Object.keys(table_chart[0]).filter(key => typeof table_chart[0][key] === 'object')

            $table.append("<table class='json-table'>")
            $table.append("<thead>")
            $table.append("<tr>")
            $table.append(`<th class="header-json-table align-self-center"></th>`)
            $table.append(`<th style="width: 100px" class="header-json-table">&emsp; Tipo &emsp;</th>`)

            obj_primary_keys.forEach((primary_key) => {
                $table.append(`<th class="header-json-table" colspan="4"> ${ primary_key !== 'null' ? primary_key : `&empty;`}</th>`)
            })

            $table.append("</tr>")

            $table.append("<tr>")
            $table.append(`<th class="header-json-table align-self-center"></th>`)
            $table.append(`<th class="header-json-table align-self-center">Nombre del país agrupado </th>`)
            obj_primary_keys.forEach((primary_key) => {
                obj_secundary_keys.forEach((secundary_key) => {
                    $table.append(
                        `<th class="header-json-table">
                            ${secundary_key}
                        </th>`
                    )
                })
            })

            $table.append("</tr>")
            $table.append("</thead>")

            $table.append("<tbody>")
            let change_color_index = 0
            let index = 1
            for (let [key, value] of map_country.entries()){
                $table.append(`<tr class="tr-json-table">`);
                $table.append(`<td
                                class="rows-json-table"
                                style="background-color: ${change_color_index % 2 === 0 ? '#f2f2f2' : 'white'};
                                width: 100px;"
                                >${index < table_chart.length ? index : 'Totales'}</td>`)
                index++
                $table.append(
                    `<td
                        class="rows-json-table"
                        style="background-color: ${change_color_index % 2 === 0 ? '#f2f2f2' : 'white'};
                                width: 100px;
                        "
                    >
                    ${key || ''}
                    </td>`);

                getObjectValue(value).forEach((element, index) => {
                    $table.append(
                        `<td
                            class="rows-json-table"
                            style="
                                background-color: ${change_color_index % 2 === 0 ? '#f2f2f2' : 'white'};
                                color: ${variableIsDefined(element) && (index % 4 >= 2) ? getColumnColor(element) : 'unset'}
                                "
                        >
                            ${styleTableValue(element,index)}
                        </td>`
                    );
                })
                $table.append("</tr>")
                change_color_index++
            }
            $table.append("</tbody>")
            $table.append("<tfoot>")

            $table.append("<tr>")
            $table.append(`<th class="header-json-table align-self-center"></th>`)
            $table.append(`<th class="header-json-table align-self-center">Nombre del país agrupado </th>`)
            obj_primary_keys.forEach((primary_key) => {
                obj_secundary_keys.forEach((secundary_key) => {
                    $table.append(
                        `<th class="header-json-table">
                            ${secundary_key}
                        </th>`
                    )
                })
            })
            $table.append("</tr>")
            $table.append("<tr>")
            $table.append(`<th class="header-json-table align-self-center"></th>`)
            $table.append(`<th style="width: 100px" class="header-json-table">&emsp; Tipo &emsp;</th>`)

            obj_primary_keys.forEach((primary_key) => {
                $table.append(`<th class="header-json-table" colspan="4"> ${ primary_key !== 'null' ? primary_key : `&empty;`}</th>`)
            })
            $table.append("</tr>")
            $table.append("</tfoot>")
            $table.append("</table>")
        }

        const getColumnColor = (value) => {
            if (parseFloat(value) === 0){
                return undefined
            }
            if (parseFloat(value) > 0){
                return 'green'
            }else{
                return 'red'
            }
        }

        const styleTableValue = (value, col_id) => {
            let number_to_show = ""
            switch (col_id%4){
                case 0:
                case 1:
                    return variableIsDefined(value) || value === 0 ? value : `&empty;`
                case 2:
                case 3:
                    number_to_show = ""
                    if (variableIsDefined(value)){
                        if (parseFloat(value) === 0){
                            number_to_show = '0%'
                        }else{
                            number_to_show = ` ${parseFloat(value) < 0 ? '&#9660;' : '&#9650;'}${value}`
                        }
                    }else if (value === 0){
                        number_to_show = `${value}`
                    }else {
                        number_to_show =  `&empty;`
                    }
                    return number_to_show
            }
        }

        return {
            table_id,
        }
    }
}
</script>

<style lang="scss">
.col-table-json {
    overflow: auto;
}

.header-json-table {
    padding: 25px 10px 25px 10px ;
    max-height: 70px;
    text-align: center!important;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    border: 1px solid $gray-light !important;
    background-color: $white;
}

.rows-json-table {
    text-align: center!important;
    max-width: 95px;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    border: 1px solid $gray-light !important;

    padding: 7px;
}
</style>
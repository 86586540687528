import {useNotification} from "@kyvg/vue3-notification";
import md5 from "md5";

export function removeAccents (str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function showNotification(tipo, text, timeout = 6000){
    const notification = useNotification();
    if (typeof text === "object") {
        if (text[0].title !== undefined) {
            text = text[0].title
        }
    }

    notification.notify({
        title: text,
        type: tipo,
        duration: timeout,
    });
}
export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function variableIsDefined(variable) {
    return (variable !== undefined && variable !== null && variable !== "" && variable !== 0 && variable !== [] && variable !== {})
}

export async function copyClipboard(data) {
    navigator.clipboard.writeText(data).then(
        () => {
            showNotification("success", "Dato copiado")
        },
        () => {
            showNotification("error", "No se ha podido copiar")
        },
    );

}


export function createTokenESCIC(params = {}) {

    let final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });

    return md5(process.env.VUE_APP_ESCIC_TOKEN_WS_KEY + final_token);
}

export function createTokenAISEO(params = {}) {

    let final_token = "";

    Object.entries(params).sort().forEach(([key, value]) => {
        final_token += key + '=' + value + ';'
    });
    return md5(process.env.VUE_APP_AISEO_TOKEN_WS_KEY + final_token);
}

export function b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded Unicode,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
        }));
}

/*
*
* OBTIENE EL NOMBRE DEL MES A PARTIR DE SU NÚMERO
* ADMITE VALORES ENTRE 1(ENERO) Y 12(DICIEMBRE)
* */
export const getMonthName = (month_number) => {
    if(month_number > 0 && month_number < 13){
        const date = new Date();
        date.setMonth(month_number-1)

        return date.toLocaleString('default', { month: 'long' })
    }else{
        return null
    }
}

export const manageWSResponses = async (WSFunction, params = []) =>{
    const response = await WSFunction(...params)

    if (response['result']){
        return response
    }else{
        showNotification('error', response['errors'])
        return false
    }
}


export function objectsHasSameInfo(objetoA, objetoB, strict = false) {
    // Comprobar si ambos parámetros son objetos
    if (typeof objetoA !== 'object' || typeof objetoB !== 'object') {
        return false;
    }

    // Obtener las claves de las propiedades de cada objeto
    const clavesObjetoA = Object.keys(objetoA);
    const clavesObjetoB = Object.keys(objetoB);

    // Comprobar si el número de propiedades es el mismo
    if (clavesObjetoA.length !== clavesObjetoB.length) {
        return false;
    }

    // Recorrer las claves y comparar los valores de las propiedades
    for (const clave of clavesObjetoA) {
        if (!clavesObjetoB.includes(clave)) {
            return false;
        } else {
            if (strict) {
                if (objetoA[clave] !== objetoB[clave]) {
                    return false;
                }
            } else {
                if (objetoA[clave] != objetoB[clave]) {
                    return false;
                }
            }

        }
    }

    // Si todas las comparaciones pasaron, los objetos son iguales
    return true;
}

<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <div class="section-title">
                    <h2 class="main-title-page">Cursos relacionados</h2>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="4" xs="6" xl="3"  v-for="course in list_courses">
                <v-card class="course-cards">
                    <div class="info-course">
                        <span>{{ course.tipo_de_curso }}</span>
                        <span v-if="course.creditos_ects">{{ course.creditos_ects }} ECTS</span>
                    </div>
                    <v-card-title class="course-cards-title">{{ course.titulo }}</v-card-title>
                    <v-divider class="mx-4"></v-divider>
                    <div class="course-cards-prices">
                        <span class="price-total">{{ course.precio }}€</span>
                        <span class="offer">{{ course.precio_bonificado }}€</span>
                    </div>
                    <v-row class="course-cards-link text-center">
                        <v-col>
                            <v-btn class="btn button-secondary" @click="showInfoModal(course.titulo, course.certificado_por, course.homologado_por)">
                                Certificaciones
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn class="btn button-primary" target="_blank"
                                   :href=course.url>
                                Ir a {{ course.tipo_de_curso }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <div v-if="url !== 'null'">
            <iframe v-if="error_msg === 'null'" height="100%" width="100%"
                    id="looker_embedded" name="looker_embedded" class="full" :src="url"></iframe>
            <p class="text-danger fw-bold" v-else>No tienes acceso a este panel</p>
        </div>
    </v-container>

    <v-dialog v-model="dialog" scrollable>
    <CertificationModal
        :showInfoModal="showInfoModal"
        :course_active="course_active"></CertificationModal>
    </v-dialog>
</template>

<script>
import {useRoute} from "vue-router";
import {computed, onBeforeMount, ref} from "vue";
import {useStore} from "vuex";
import {getDashboardWS} from "@/utils/dashboards";
import {showNotification} from "@/utils/aux_functions";
import {getCoursesRelatedWS} from "@/utils/courses";
import CertificationModal from "@/components/ConsultingReport/CertificationModal.vue";
import Loading from "@/components/Generic/Loading.vue";


export default {
    name: "InformeAsesoriaView",
    components: {Loading, CertificationModal},

    setup() {
        const store = useStore();
        const route = useRoute();
        let error_msg = ref('null');
        let url = ref("null");
        let list_courses = ref([]);
        let course_active = ref({});
        var dashboard_id = 67;
        var id_persona = route.params.id_persona;
        let parameters = '{"ID Persona":' + id_persona.toString() + '}';

        let dialog = ref(false);

        onBeforeMount(() => {
            getDashboard();
            getCoursesRelated();
        })

        const getCoursesRelated = async () => {
            const response = await getCoursesRelatedWS(id_persona);
            if (response['result']) {
                list_courses.value = response['data'];
            } else {
                showNotification('error', response['error'])
            }
        }

        async function getDashboard() {
            const response = await getDashboardWS(dashboard_id, parameters);
            if (response['result']) {
                url.value = response['data'].replace(/['"]+/g, '');
            } else {
                showNotification('error', response['errors'])
            }
        }

        /* Mostrar modal de certificaciones */
        async function showInfoModal(titulo, certificaciones, homologaciones) {
            dialog.value = !dialog.value;
            course_active.value.certificaciones = certificaciones;
            course_active.value.homologaciones = homologaciones;
            course_active.value.titulo = titulo;
        }

        return {
            url,
            error_msg,
            list_courses,
            course_active,
            dialog,
            showInfoModal
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/styles/views/report-user.scss";
</style>
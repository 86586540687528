<template>
    <v-container>
        <v-row align="center">
            <v-col cols="12" class="section-title">
                <span class="main-title-page">Dashboard asesores académicos</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" :sm="show_user_field ? '6': '12'" class="align-self-center text-end text-md-center">
                <v-select
                        class="select-secondary"
                        placeholder="Seleccione un dashboard"
                        :items="available_dashboards"
                        v-model="selected_dashboard"
                        item-title="title"
                        item-value="id_adhoc_dashboard"
                        hide-details
                        bg-color="white"
                        no-data-text="No hay datos disponibles"
                        :disabled="loading_looks"
                ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="5" class="align-self-center text-end text-md-center" v-if="show_user_field">
                <v-text-field
                        placeholder="Nombre de usuario"
                        v-model.trim="filter_username"
                        class="text-field-secondary"
                        bg-color="white"
                        :disabled="loading_looks"
                        hide-details
                ></v-text-field>
            </v-col>

            <template v-if="variableIsDefined(selected_dashboard)">
                <v-col cols="12" sm="4">
                    <span>Fecha de inicio</span>
                    <calendar ref="start_date" :block_dates="true" :accept_default_date="false"
                              :disabled_calendar="loading_looks"></calendar>
                    <span v-if="form_error.start" class="input-error msg-error">{{ form_error.msg }}</span>
                </v-col>
                <v-col cols="12" sm="4">
                    <span>Fecha de finalización</span>
                    <calendar ref="final_date" :block_dates="true" :accept_default_date="false"
                              :disabled_calendar="loading_looks"></calendar>
                    <span v-if="form_error.final" class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12" sm="3" class="align-self-center text-center text-md-start mt-sm-5">
                    <v-btn class="btn button-primary" @click="fillLooksWithDateFilter" :loading="loading_looks">Cargar
                        Lookers
                    </v-btn>
                </v-col>
            </template>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" v-if="variableIsDefined(sales_data)">
                <div class="gamification-message text-center">
                    <span v-if="sales_data.variation > 0">
                        {{ `¡Genial!, has conseguido un ${sales_data.variation}% más de ventas que el año pasado. ¡Enhorabuena!` }}
                    </span>
                    <iframe class="lottie-celebration" src="https://lottie.host/embed/ebcb3dd9-27c3-4290-b633-a06878f72b11/wGpCvQKQ7O.json"></iframe>
                </div>
            </v-col>
            <v-col cols="12" v-if="loading_looks && variableIsDefined(available_looks)" class="text-center">
                <p class="input-title"> Cargando gráficas {{ loaded_looks }} / {{ available_looks.length }}</p>
            </v-col>
            <template v-for="{result, vis_config, title} in looks_data">
                <BaseChart
                        v-if="vis_config.type === 'looker_pie' && result.length > 0"
                        :key="loop_key"
                        :chart_type="'PieChart'"
                        :json_table="result"
                        :table_title="title"
                        :axis_title="{vertical:  'Nº de matriculas',horizontal: 'Fecha'}"
                />
                <BaseChart
                        v-else-if="vis_config.type === 'looker_column_normal'  && result.length > 0"
                        :key="loop_key"
                        :chart_type="'ColumnChart'"
                        :json_table="result"
                        :table_title="title"
                        :axis_title="{vertical: title.toLowerCase().includes('ventas') ? 'Importe' : 'Nº de matriculas',horizontal: 'Fecha'}"
                />
                <!--LAS QUE SON SINGLE_VALUE PERO TRAEN DOS CAMPOS ES AMBOS EN EL MISMO RECUADRO O DOS RECUADROS DE UNO??-->
                <v-col cols="12" md="4" v-else-if="vis_config.type === 'single_value'  && result.length > 0">
                    <SingleValueChart
                            :key="loop_key"
                            :title="title"
                            :single_value="result"
                    ></SingleValueChart>
                </v-col>
                <v-col cols="12" md="6" v-else-if="vis_config.type === 'multiple_value' && result.length > 0">
                    <MultipleValueChart
                            :key="loop_key"
                            :title="title"
                            :values="result"
                    ></MultipleValueChart>
                </v-col>
                <v-col cols="12" v-else-if="vis_config.type === 'looker_grid_pivoted'  && result.length > 0">
                    <LargeTablePivoted
                            :key="'K' + loop_key"
                            :json_table_chart="result"
                            :title="title"
                    ></LargeTablePivoted>
                </v-col>
                <v-col cols="12" v-else-if="vis_config.type === 'looker_grid' && result.length > 0">
                    <GridTable
                            :key="loop_key"
                            :json_table_chart="result"
                            :title="title"
                    ></GridTable>
                </v-col>
            </template>
        </v-row>
        <template v-if="loading_looks">
            <Loading/>
        </template>

    </v-container>
</template>

<script>
import {GChart} from "vue-google-charts";
import {onBeforeMount, ref} from "vue";
import BaseChart from "@/components/Charts/BaseChart.vue";
import {manageWSResponses, showNotification, variableIsDefined} from "@/utils/aux_functions";
import {
    getAdhocDashboardLooksWS,
    getAdhocDashboardsWS,
    getComparativeLastYearWS,
    getDateFilterNames,
    runAdhocLookWS
} from "@/utils/looker_utils";
import Loading from "@/components/Generic/Loading.vue";
import LargeTablePivoted from "@/components/Charts/LargeTablePivoted.vue";
import SingleValueChart from "@/components/Charts/SingleValueChart.vue";
import GridTable from "@/components/Charts/GridTable.vue";
import Calendar from "@/components/Generic/Calendar.vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import {belongGroup, getAcademicDashboardGroups} from "@/utils/auth";
import MultipleValueChart from "@/components/Charts/MultipleValueChart.vue";
import {useCalendar} from "@/utils/Composables/useCalendar";

export default {
    name: "ChartsFromJSONView",
    methods: {variableIsDefined},
    components: {
        MultipleValueChart,
        Calendar,
        GridTable,
        SingleValueChart,
        LargeTablePivoted,
        Loading,
        BaseChart,
        GChart
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const router = useRouter()

        const available_dashboards = ref()
        const selected_dashboard = ref()

        const available_looks = ref()
        const looks_loaded = ref(false)

        const looks_data = ref([])
        const sales_data = ref()

        const loading_looks = ref(false)
        const loaded_looks = ref(0)

        const start_date = ref(null)
        const final_date = ref(null)
        const form_error = ref({})

        let date_start = ""
        let date_final = ""
        let gamification_final_date = ""
        let gamification_init_date = ""

        const filter = {'tecnicos.nickname': store.getters.getUsername}
        let filter_date = null
        const username_regex = /^[^\s\d]+\.[^\s\d]+$/
        const show_user_field = ref(false)
        const filter_username = ref()

        const loop_key = ref(0)

        const {formatDefaultDateView} = useCalendar()

        onBeforeMount(async () => {
            await router.isReady()
            await fillDashboards()
            selected_dashboard.value = 1

            show_user_field.value = belongGroup(getAcademicDashboardGroups())

            if (variableIsDefined(route.query.usuario) && show_user_field.value) {
                if (username_regex.test(route.query.usuario)) {
                    filter_username.value = route.query.usuario
                    filter['tecnicos.nickname'] = username_regex.test(route.query.usuario) ? route.query.usuario : store.getters.getUsername
                }
            }
        })

        //Obtiene los distintos Dashboard disponibles para el selector
        const fillDashboards = async () => {
            const response = await getAdhocDashboardsWS()

            if (response['result']) {
                available_dashboards.value = response['data']
            } else {
                showNotification('error', response['errors'])
            }
        }

        //Obtiene el listado con todos los looks
        const fillLooks = async () => {
            loading_looks.value = true
            loaded_looks.value = 0
            looks_data.value = []

            await router.replace({})
            filter['tecnicos.nickname'] = store.getters.getUsername

            if (username_regex.test(filter_username.value)) {
                const query = {usuario: filter_username.value}
                await router.replace({query})

                filter['tecnicos.nickname'] = filter_username.value
            }

            sales_data.value = await loadSalesData()
            const response = await getAdhocDashboardLooksWS(selected_dashboard.value)

            if (response['result']) {
                available_looks.value = response['data']

                for (const look of available_looks.value) {
                    loaded_looks.value++
                    await runLook(look['AdhocLook'])
                }
            } else {
                showNotification('error', response['errors'])
            }
            loading_looks.value = false
        }

        //Crea el filtro de fecha y carga los datos de ventas y los looks
        const fillLooksWithDateFilter = async () => {
            filter_date = null

            if (validateDates()) {
                filter_date = `${date_start} to ${date_final}`

                await fillLooks()
            }
        }

        //LLama al ws de comparativa de ventas para obtener el valor de la variación
        const loadSalesData = async () => {
            const response = await manageWSResponses(getComparativeLastYearWS, [filter['tecnicos.nickname'], gamification_init_date, gamification_final_date])

            if (response) {
                return response['data']
            }
        }

        //Carga los datos de un looker para mostrar la gráfica correspondiente
        const runLook = async ({id_adhoc_look, title, filters}) => {
            let new_filters = {}
            let filter_name

            if (variableIsDefined(filter_date) && variableIsDefined(getDateFilterNames(id_adhoc_look))) {
                new_filters[getDateFilterNames(id_adhoc_look)] = filter_date
            }

            filter_name = Object.keys(filters).some(element => element === 'trabajadores.nickname')
                ? 'trabajadores.nickname'
                : 'tecnicos.nickname'

            new_filters[filter_name] = filter['tecnicos.nickname']

            const response = await runAdhocLookWS(id_adhoc_look, {...new_filters})

            if (response['result']) {
                looks_data.value = [...looks_data.value, {...response['data'], title}]
            } else {
                showNotification('error', response['errors'])
            }
        }

        // Comprueba que las fechas sean correctas, que la inicial sea menor que la final y las formatea correctamente
        // Inicializa el valor de las fechas para la gamificación
        // Añade un día más a la fecha final para los loker unicamente
        const validateDates = () => {
            date_start = start_date.value.date_calendar
            date_final = final_date.value.date_calendar
            form_error.value = {}

            if (variableIsDefined(date_start) && variableIsDefined(date_final) && date_start !== 'dd/mm/aaaa' && date_final !== 'dd/mm/aaaa') {
                //Deja como fecha final para la gamificación la seleccionada por el usuario en vez de añadirle un día
                gamification_final_date = formatDefaultDateView(date_final, {
                    split_by_slash: true,
                    format: 'yyyy-mm-dd'
                })
                gamification_init_date = formatDefaultDateView(date_start, {split_by_slash: true, format: 'yyyy-mm-dd'})

                const date_plus_one = new Date(gamification_final_date)
                date_plus_one.setDate(date_plus_one.getDate() + 1)

                date_start = formatDefaultDateView(date_start, {split_by_slash: true, format: 'yyyy/mm/dd'})
                date_final = formatDefaultDateView(date_plus_one, {format: 'yyyy/mm/dd'})

                if (new Date(date_start) < new Date(date_final)) {
                    return true
                } else {
                    form_error.value.start = true;
                    form_error.value.msg = 'El campo de inicio no puede ser mayor al de finalización';
                    return false
                }
            } else {
                if (!variableIsDefined(date_start) || date_start === 'dd/mm/aaaa') {
                    form_error.value.start = true;
                }
                if (!variableIsDefined(date_final) || date_final === 'dd/mm/aaaa') {
                    form_error.value.final = true;
                }
                form_error.value.msg = 'El campo no puede estar vacío';
                return false
            }
        }

        return {
            fillLooks,
            fillLooksWithDateFilter,
            runLook,

            loop_key,
            available_dashboards,
            available_looks,
            final_date,
            filter_username,
            form_error,
            loaded_looks,
            loading_looks,
            looks_data,
            looks_loaded,
            sales_data,
            selected_dashboard,
            start_date,
            show_user_field,
        }
    }
}
</script>

<style lang="scss">

.value-charts {
  margin: 10px;
  padding: 0;
  text-align: center;
  border: 1px solid $primary-color-1;
  background-color: $white;
  color: #333;
  box-sizing: border-box;
}

.gamification-message {
    margin: 3vh 0;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    @media (max-width: 450px) {
        flex-wrap: wrap;
    }

    span {
        font-weight: bold;
        border-radius: 8px;
        color: forestgreen;
        margin-top: 3vh;
    }

    .lottie-celebration{
        height: 72px;
        width: 10%;

        @media (max-width: 450px) {
            width: 20%;
        }
    }
}
</style>
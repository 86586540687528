<template>
    <h3>{{title}}</h3>
    <div  class="col-table-json personalize-scroll">
        <table class='json-table w-100'>
            <thead>
            <tr>
                <th class="header-json-table"> Indice</th>
                <th v-for="header in obj_primary_keys" class="header-json-table">
                    {{ header !== 'null' ? header : `&empty;` }}
                </th>
            </tr>
            </thead>
            <tbody class="table-body">
                <tr v-for="(item, index) in json_table_chart" :key="index">
                    <td
                        class="rows-json-table"
                        :style="{
                            'background-color': (index % 2 === 0 ? '#f2f2f2' : 'white'),
                            'width': '100px'
                        }"
                    >{{ index }}</td>
                    <td v-for="([key,value], cell_index) in Object.entries(item)"
                        class="rows-json-table"
                        :style="
                            {
                                'background-color': (index % 2 === 0 ? '#f2f2f2' : 'white'),
                                'color': variableIsDefined(value) && obj_primary_keys[cell_index].toLowerCase().includes('variación') ? getColumnColor(value) : 'unset'
                            }"
                    >
                        <span v-html="styleTableValue(value, cell_index)"></span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import {variableIsDefined} from "@/utils/aux_functions";
import {getObjectKeyNames} from "@/utils/looker_utils";

export default {
    name: "GridTable",
    methods: {variableIsDefined},
    props: {
        json_table_chart: Array,
        title: String,
    },
    setup(props){
        const table_chart = props.json_table_chart
        let obj_primary_keys = ref()

        onBeforeMount(()=> {
            obj_primary_keys.value = getObjectKeyNames(table_chart[0])
        })

        const getColumnColor = (value) => {
            if (parseFloat(value) === 0){
                return undefined
            }
            if (parseFloat(value) > 0){
                return 'green'
            }else{
                return 'red'
            }
        }

        const styleTableValue = (value, col_id) => {
            let number_to_show = ""

            if (obj_primary_keys.value[col_id].toLowerCase() === 'link hilo'){
                const url = new URL(value)
                const params = Object.fromEntries(url.searchParams.entries());
                return `<a href="${value}" target="_blank">${params['id_hilo']}</a>`
            }else if (obj_primary_keys.value[col_id].toLowerCase().includes('variación')){
                number_to_show = ""
                if (variableIsDefined(value)){
                    if (parseFloat(value) === 0){
                        number_to_show = '0%'
                    }else{
                        number_to_show = `${parseFloat(value) < 0 ? '&#9660;' : '&#9650;'}${value}%`
                    }
                }else {
                    number_to_show =  '&empty;'
                }
                return number_to_show
            }else if(obj_primary_keys.value[col_id].includes('%')){
                if (value !== null){
                    return value
                }else{
                    return '&empty;'
                }
            }else{
                return variableIsDefined(value) || value === 0 ? value : '&empty;'
            }
        }

        return {
            obj_primary_keys,
            styleTableValue,
            getColumnColor,
        }
    }
}
</script>

<style scoped lang="scss">
.col-table-json {
    max-height: 70vh;
    overflow: auto;
}

.header-json-table {
    padding: 25px 10px 25px 10px ;
    max-height: 70px;
    text-align: center!important;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    border: 1px solid $gray-light !important;
    background-color: $white;
}

.rows-json-table {
    text-align: center!important;
    overflow: hidden;
    white-space: normal;
    overflow-wrap: break-word;
    border: 1px solid $gray-light !important;
    padding: 7px;
}

.table-body {
    max-height: 200px;
    overflow-y: auto;
}
</style>
<template>
    <v-container class="container">
        <template v-if="loading">
            <Loading/>
        </template>
        <!--  Maquetación PC -->
        <template class="d-none d-lg-block">
            <v-row v-if="!loading">
                <v-col cols="9" class="general-dashboards-roles">
                    <div class="titles-manager-dashboard">Lista de dashboards</div>

                    <template v-if="loading_small">
                        <img :src="require('@/assets/img/loading.gif')"
                             alt="Loading" class="loading-gif-xs">
                    </template>

                    <template v-if="!loading_small">
                        <span v-if="all_dashboards.length===0" class="error-message">No se han encontrado datos con el filtro seleccionado</span>
                        <v-row v-else>
                            <template v-for="dashboard in all_dashboards"
                                      :key="dashboard.id_dashboard">
                                <v-col cols="9" class="dashboards-roles justify-space-between">
                                    <v-expansion-panels multiple>
                                        <div class="w-100">
                                            <v-expansion-panel :title="dashboard.title">
                                                <v-expansion-panel-text>
                                                    <template v-for="rol in dashboard.roles">
                                                        <v-checkbox hide-details
                                                                    class="selected_dashboard"
                                                                    color="blue lighten-3"
                                                                    v-model="rol.activo"
                                                                    :label="rol.nombre"
                                                                    :true-value="1"
                                                                    :false-value="0"
                                                        ></v-checkbox>
                                                    </template>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>
                                        </div>
                                    </v-expansion-panels>
                                </v-col>
                                <v-col cols="3">
                                    <v-btn
                                            class="btn button-bg-primary-color"
                                            @click="saveRoles(dashboard)"> Guardar
                                    </v-btn>
                                </v-col>
                            </template>

                        </v-row>
                    </template>
                </v-col>

                <v-col cols="3" class="container-fixed">
                    <v-row>
                        <v-col cols="12">
                            <div class="titles-manager-dashboard">
                                Filtro por dashboard
                                <img @click="resetFilters('search')"
                                     :src="require('@/assets/icons/filter-remove.svg')"
                                     alt="Papelera" class="icon"
                                     title="Quitar Filtro"
                                >
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                                    class="text-field-secondary"
                                    v-model="title_searched"
                                    density="compact"
                                    placeholder="¿Qué dashboard buscas?"
                                    @keyup.enter="filterRoles"
                                    append-inner-icon="mdi-magnify"
                                    single-line
                                    bg-color="white"
                                    color="transparent"
                                    hide-details
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <div class="titles-manager-dashboard title-filter-rol">
                                Filtro por rol
                                <img
                                        @click="resetFilters('rol')"
                                        :src="require('@/assets/icons/filter-remove.svg')"
                                        alt="Papelera" class="icon"
                                        title="Quitar Filtro"
                                >
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <div class="container-rol-fixed personalize-scroll">
                                <v-checkbox v-for="rol in all_roles" hide-details class="rol-filter"
                                            v-model="rol_selected"
                                            color="blue lighten-3"
                                            :label="rol.nombre"
                                            :value="rol.id_rol"
                                ></v-checkbox>
                            </div>
                        </v-col>
                        <v-col cols="12">
                            <v-btn
                                    class="btn button-primary"
                                    variant="flat"
                                    @click="filterRoles">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </template>
        <!--Maquetación tablet -->
        <template class="d-block d-lg-none">
            <v-row v-if="!loading">
                <v-col cols="12">
                    <div class="titles-filters-tablet mt-2">
                        Filtro por dashboard
                        <img @click="resetFilters('search')"
                             :src="require('@/assets/icons/filter-remove.svg')"
                             alt="Papelera" class="icon me-2"
                             title="Quitar Filtro">
                    </div>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                            class="text-field-secondary"
                            v-model="title_searched"
                            density="compact"
                            placeholder="¿Qué dashboard quieres buscar?"
                            @keyup.enter="filterRoles"
                            append-inner-icon="mdi-magnify"
                            single-line
                            bg-color="white"
                            color="transparent"
                            hide-details
                    ></v-text-field>
                </v-col>

                <v-col cols="12">
                    <v-row class="container-filter-roles-tablet">
                        <v-col cols="9">
                            <div class="titles-filters-tablet">
                                Filtro por rol
                                <img @click="resetFilters('rol')"
                                     :src="require('@/assets/icons/filter-remove.svg')"
                                     alt="Papelera" class="icon"
                                     title="Quitar Filtro"
                                >
                            </div>
                            <v-expansion-panels>
                                <v-expansion-panel>
                                    <v-expansion-panel-title class="title-panel-rol">Seleccione un rol
                                    </v-expansion-panel-title>
                                    <v-expansion-panel-text>
                                        <v-checkbox v-for="rol in all_roles" hide-details
                                                    v-model="rol_selected"
                                                    color="blue lighten-3"
                                                    :label="rol.nombre"
                                                    :value="rol.id_rol"
                                        ></v-checkbox>
                                    </v-expansion-panel-text>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </v-col>
                        <v-col cols="3" class="pt-15">
                            <v-btn
                                    class="btn button-primary"
                                    variant="flat"
                                    @click="filterRoles">
                                Filtrar
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <div class="titles-manager-dashboard">Lista de dashboards</div>
                        </v-col>

                        <v-col v-if="loading_small" class="loading-gif-xs">
                            <Loading/>
                        </v-col>
                        <template v-else>
                            <v-col cols="12">
                                <span v-if="all_dashboards.length===0" class="error-message">No se han encontrado datos con el filtro seleccionado</span>
                                <div v-else class="container-all-dashboard-fixed-tablet personalize-scroll">

                                    <template v-for="dashboard in all_dashboards"
                                              :key="dashboard.id_dashboard">
                                        <v-expansion-panels multiple>
                                            <v-expansion-panel :title="dashboard.title">
                                                <v-expansion-panel-text>
                                                    <template v-for="rol in dashboard.roles">
                                                        <v-checkbox hide-details
                                                                    color="blue lighten-3"
                                                                    v-model="rol.activo"
                                                                    :label="rol.nombre"
                                                                    :true-value="1"
                                                                    :false-value="0"
                                                        ></v-checkbox>
                                                    </template>
                                                    <v-btn
                                                            class="btn button-bg-primary-color"
                                                            @click="saveRoles(dashboard)"> Guardar
                                                    </v-btn>
                                                </v-expansion-panel-text>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </template>
                                </div>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>


            </v-row>
        </template>
    </v-container>
</template>

<script>
import {getAllDashboardsWS, getAllRolesWS, saveRolesDashboardWS} from
        "@/utils/admin/manager_permission";
import {onBeforeMount, ref} from "vue";
import {removeAccents, showNotification, sleep} from "@/utils/aux_functions"
import {useRoute} from "vue-router";
import {hasPermission} from "@/utils/auth";
import Loading from "@/components/Generic/Loading.vue";

export default {
    name: "ManagerDashboardView",
    components: {Loading},
    setup: function () {
        let read_all_dashboards = [];
        let read_all_roles = [];
        let loading = ref(false);
        let all_roles = ref([]);
        let rol_selected = ref([]);
        let title_searched = ref("");
        let all_dashboards = ref([]);
        let loading_small = ref(false);
        const route = useRoute();


        onBeforeMount(async () => {
            await hasPermission(route);
            await getAllDashboards();
            await getAllRoles();
        });

        async function getAllDashboards() {
            loading.value = true;
            const response = await getAllDashboardsWS();
            if (response['result']) {
                read_all_dashboards = response['data']
                loading.value = false
                all_dashboards.value = read_all_dashboards
            } else {
                showNotification("error", "Ha ocurrido un error al obtener los dashboards.")
            }
            loading.value = false
        }

        async function getAllRoles() {
            loading.value = true;
            const response = await getAllRolesWS();
            if (response['result']) {
                read_all_roles = response['data']
                all_roles.value = read_all_roles
            } else {
                showNotification("error", "Ha ocurrido un error al obtener los roles.")
            }
            loading.value = false
        }

        async function filterRoles() {
            let dashboards_filtered = []
            let title_searched_normalized = ""
            let filter_roles_selected = rol_selected.value;
            if (filter_roles_selected.length === 0 && title_searched.value === "") {
                all_dashboards.value = read_all_dashboards
            } else {
                loading_small.value = true;
                title_searched_normalized = removeAccents(title_searched.value.toLowerCase())
                if (filter_roles_selected.length !== 0 && title_searched.value === "") {
                    read_all_dashboards.forEach(dashboard_full => {
                        dashboard_full.roles.forEach(rol_dashboard => {
                            if (filter_roles_selected.includes(rol_dashboard.id_rol) && rol_dashboard.activo === 1 && !dashboards_filtered.includes(dashboard_full)) {
                                dashboards_filtered.push(dashboard_full)
                            }
                        })
                    })
                    all_dashboards.value = dashboards_filtered
                    loading_small.value = false;
                } else if (filter_roles_selected.length === 0 && title_searched.value !== "") {
                    read_all_dashboards.forEach(dashboard_full => {
                        if (removeAccents(dashboard_full.title.toLowerCase()).includes(title_searched_normalized) && !dashboards_filtered.includes(dashboard_full)) {
                            dashboards_filtered.push(dashboard_full)
                        }
                    })
                    all_dashboards.value = dashboards_filtered

                } else {

                    read_all_dashboards.forEach(dashboard_full => {
                        if (removeAccents(dashboard_full.title.toLowerCase()).includes(title_searched_normalized)) {
                            dashboard_full.roles.forEach(rol_dashboard => {
                                if (filter_roles_selected.includes(rol_dashboard.id_rol) && rol_dashboard.activo === 1 && !dashboards_filtered.includes(dashboard_full)) {
                                    dashboards_filtered.push(dashboard_full)
                                }
                            })
                        }
                    })
                    all_dashboards.value = dashboards_filtered
                }
                await sleep(1000)
                loading_small.value = false;
            }
        }

        async function saveRoles(dashboard) {
            let roles_final = [];

            dashboard.roles.forEach(rol => {
                let rol_obj = {};
                rol_obj.id_rol = rol.id_rol;
                rol_obj.activo = rol.activo;
                roles_final.push(rol_obj);
            })
            let response = await saveRolesDashboardWS(parseInt(dashboard.id_dashboard), roles_final)

            if (response['result']) {
                showNotification("success", "Se han modificado los roles correctamente")
            } else {
                showNotification("error", "Ha ocurrido un error al guardar.")
            }
            await getAllDashboards();


        }

        function resetFilters(filtro) {
            if (filtro === 'rol') {
                rol_selected.value = [];
            } else if (filtro === 'search') {
                title_searched.value = "";
            }
            filterRoles();
        }


        return {
            all_dashboards,
            all_roles,
            rol_selected,
            title_searched,
            loading,
            loading_small,
            filterRoles,
            saveRoles,
            resetFilters,
        }
    }
}
</script>

<style lang="scss">
@import "@/styles/views/manager-dashboard.scss";
</style>
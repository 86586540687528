import {aicoreBackendPetition} from "@/utils/aicore_petition";
import store from "@/store";
import {escicBackendPetition} from "@/utils/escic_petition";
import {showNotification} from "@/utils/aux_functions";

export async function createVideoProducto(title, logo, main_video, final_video, intro_video, configuration_logo){
    let data = {
        'title_video': title,
        logo_url: logo[1],
        clip_url: main_video[1],
        outro_url: final_video[1],

        'time_logo_appears':logo[2]==='00:00:00' ? null : logo[2],
        'time_logo_disappears':logo[3]==='00:00:00' ? null : logo[3],
        'time_clip_starts': main_video[2]==='00:00:00' ? null : main_video[2],
        'time_clip_finishes': main_video[3]==='00:00:00' ? null : main_video[3],

        'time_outro_starts': final_video[2]==='00:00:00' ? null : final_video[2],
        'time_outro_finishes': final_video[3]==='00:00:00' ? null : final_video[3],

        'intro_url': intro_video[1] || null,
        'time_intro_starts': intro_video[2]==='00:00:00' ? null : intro_video[2],
        'time_intro_finishes': intro_video[3]==='00:00:00' ? null : intro_video[3],
        //
        /*CONFIGURATION LOGO*/
        'logo_position': configuration_logo.logo_position===null ? 'arriba-izquierda' : configuration_logo.logo_position,
        'logo_resolution': configuration_logo.resolution===null ? '240:320' : configuration_logo.resolution,
        'logo_background_color': configuration_logo.background_color===null ? ' 0xFFFFFF' : configuration_logo.background_color,
        'logo_removal_threshold': configuration_logo.item_transition_time===null ? '0.3' : configuration_logo.item_transition_time,
        'remove_logo_background': configuration_logo.active_background===false ? false : configuration_logo.active_background,
    }


    let url= "/EdicionVideos/create_ffmpeg_with_url"
    return await aicoreBackendPetition('GET', url, data,{content_type: "application/json;"});
}

export async function getIntroVideoWS(form_data){
    let params = {
        'codigo_innotutor': form_data.code_innot,
        'idioma': form_data.language,
        'model': form_data.model,
        'user':store.getters.getUsername,
        'force_generation':true,
        'prompt_file':'generacion_intro_guiones_producto.txt',
        'num_words':'50-70',
        'id_empresa_innotutor' : form_data.company,
    }
    try {
        return await aicoreBackendPetition('GET', "LLMs/generador-guiones/get_intro_video", params, {nlp: true });
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'LLMs/generador-guiones/get_intro_video');
    }

}


export async function getGuionVideoWS(form_data){
    let params = {
        'codigo_innotutor': form_data.code_innot,
        'idioma': form_data.language,
        'model': form_data.model,
        'user':store.getters.getUsername,
        'force_generation':true,
        'prompt_file':'intro.txt',
        'num_words':'130-170',
        'id_empresa_innotutor' : form_data.company,
    }
    try {
        return await aicoreBackendPetition('GET', "LLMs/generador-guiones/get_intro_video", params, {nlp: true });
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'LLMs/generador-guiones/get_intro_video');
    }
    
}

export async function getListCompaniesCoursesInnotutorWS(){
    try {
        return await aicoreBackendPetition('GET', "InnotutorWS/get_listado_empresas_cursos_innotutor", {}, {nlp: true });

    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'InnotutorWS/get_listado_empresas_cursos_innotutor');
    }

}


<template>
    <BaseModalTemplate
        title="Modificar Matricula"
        :open-close-dialog="displayModal"
        card_extra_classes="modal-width-xs"
        :send-form="updateEnrollment"
        :loading="loading_btn"
    >
        <template #body-modal-content>
            <v-row>
                <v-col cols="12">
                    <span class="input-title">ID Matrícula:</span>
                    <v-text-field class="text-field-secondary"
                                  v-model="form_data.id_enrollment" variant="underlined"
                                  :disabled="true" hide-details></v-text-field>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Fuerza de venta actual</span>
                    <v-autocomplete
                        class="input-error select-primary"
                        :items="items_ffvv"
                        v-model="selected_ffvv"
                        item-title="nombre"
                        item-value="id_fuerza_venta"
                        hide-details
                        variant="underlined"
                        no-data-text="No hay datos disponibles"
                        :menu-props="{ width: '400px', height: '200px'}"
                        return-object
                    ></v-autocomplete>
                    <span v-if="form_error.id_fuerza_venta"
                          class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Técnico Asociado</span>
                    <v-autocomplete
                        class="input-error select-primary"
                        :items="items_technicians"
                        v-model="form_data.name_technical"
                        :model-value="form_data.name_technical"
                        item-title="nombre"
                        item-value="id_usuario"
                        hide-details
                        variant="underlined"
                        no-data-text="No hay datos disponibles"
                        :menu-props="{ width: '400px', height: '200px'}"
                    ></v-autocomplete>
                    <span v-if="form_error.technical" class="input-error msg-error">El campo no puede estar vacío</span>
                </v-col>
                <v-col cols="12">
                    <span class="input-title">Comentarios</span>
                    <v-text-field
                        v-model="form_data.comments"
                        variant="underlined"
                        class="input-error select-primary"
                        hint=""
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
    </BaseModalTemplate>
</template>

<script>
import {getAssignableTechniciansWS, getSalesForceActivesWS, updateEnrollmentWS} from "@/utils/enrollment_ffvv";
import {manageWSResponses, objectsHasSameInfo, showNotification} from "@/utils/aux_functions";
import {onBeforeMount, ref} from "vue";
import Loading from "@/components/Generic/Loading.vue";
import * as Yup from "yup";
import BaseModalTemplate from "@/components/Generic/BaseModalTemplate.vue";


export default {
    name: "ModalEditEnrollment",
    components: {BaseModalTemplate, Loading},
    props: {
        displayModal: Function,
        getEnrollMentOutFFVV: Function,
        modified_data: Object,
        emit_filter: Object,
        loading_btn: Boolean
    },
    setup(props) {

        let general_loading = ref(false)
        let items_ffvv = ref([])
        let items_technicians = ref([])

        let form_data = ref({})
        let form_error = ref({})

        let selected_ffvv = ref({})
        //let comments = ref('')

        onBeforeMount(async () => {
            general_loading.value = true
            form_data.value = props.modified_data
            await getSalesForceActives()
            await getAssignableTechnicians()
            //comments.value = form_data.value['comentarios']
            general_loading.value = false
        })

        /*TODOS FUERZAS DE VENTAS*/
        async function getSalesForceActives() {
            const response = await manageWSResponses(getSalesForceActivesWS)

            if (response) {
                items_ffvv.value = response['data']['active']

                selected_ffvv.value = items_ffvv.value.find(element => parseInt(element.id_fuerza_venta) === form_data.value.id_fuerza_venta)
            }
        }

        /*TODOS LOS TECNICOS*/
        async function getAssignableTechnicians() {
            const response = await manageWSResponses(getAssignableTechniciansWS)

            if (response) {
                items_technicians.value = response['data']['technicians']
            }
        }

        /*OBTENER ID_INNOTUTOR*/
        function getIdInno() {
            /*
            if (form_data.value.name_technical == null) {
                form_data.value.id_inno = null;
            } else {
                items_technicians.value.forEach((tech) => {
                    if (tech.id_usuario === form_data.value.name_technical) {
                        form_data.value.id_inno = tech.id_inno
                    }
                })
            }
            */
            items_technicians.value.forEach((tech) => {
                if (tech.id_usuario === form_data.value.name_technical) {
                    form_data.value.id_inno = tech.id_inno
                }
            })
        }

        async function validateForm() {
            let correct
            form_error.value = {}
            form_data.value['id_fuerza_venta'] = selected_ffvv.value ? selected_ffvv.value.id_fuerza_venta : null

            const schemaForm = Yup.object().shape({
                id_fuerza_venta: Yup.string().required(true),
                id_technical: Yup.string().required(true),
            })


            try {
                await schemaForm.validate(form_data.value, {abortEarly: false});
                getIdInno()
                correct = true

            } catch (error) {
                error.inner.forEach((error) => {
                    form_error.value[error.path] = true;
                })
                correct = false
            }
            return {
                'validate': correct,
                'form_data': {
                    'id_enrollment': parseInt(form_data.value.id_enrollment),/*
                    'id_fuerza_venta': form_data.value.id_fuerza_venta ? parseInt(form_data.value.id_fuerza_venta) : null,
                    'technical': form_data.value.id_technical ? form_data.value.id_technical : null,
                    'id_inno': form_data.value.id_inno ? parseInt(form_data.value.id_inno) : null,
                    'comments': comments.value*/
                    'id_fuerza_venta': parseInt(form_data.value.id_fuerza_venta),
                    'technical': form_data.value.id_technical,
                    'id_inno': parseInt(form_data.value.id_inno),
                    'comments': form_data.value.comments
                }
            }
        }

        async function updateEnrollment() {
            general_loading.value = true
            let confirm_update = await validateForm()
            if (confirm_update.validate) {
                if (!objectsHasSameInfo(confirm_update.form_data, props.modified_data, true)) {
                    let response = await updateEnrollmentWS(confirm_update.form_data)
                    if (response['result']) {
                        showNotification('success', 'Matricula actualizada')
                        await props.getEnrollMentOutFFVV()
                        props.displayModal()

                    } else {
                        showNotification('error', response['errors'])
                    }
                } else {
                    showNotification('info', 'No se han registrado cambios')
                }
            }
            general_loading.value = false
        }


        return {
            updateEnrollment,

            general_loading,
            items_ffvv,
            items_technicians,
            form_data,
            form_error,
            selected_ffvv,
        }
    }
}
</script>


<style scoped lang="scss">
.loading-gif-small {
    width: 15vw;
    text-align: center;
}

@media (max-width: 600px) {
    .loading-gif-small {
        width: 30vw;
    }
}
</style>
import store from "@/store";
import {escicBackendPetition} from "@/utils/escic_petition";
import {createTokenAISEO, showNotification} from "@/utils/aux_functions";
import {aiseoBackendPetition} from "@/utils/aiseo_petition";
import {aicoreBackendPetition} from "@/utils/aicore_petition";

export async function getBaseLanguagesWS() {
    let params = {}

    try {
        return await aicoreBackendPetition('GET', 'Translator/get_translated_langs', params,{nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Translator/get_translated_langs');
    }
}

/*CARGA LOS TIPOS DE ELEMENTOS DISPONIBLES*/
export async function loadElementsTypeWS(language_id) {
    let params = {}

    try {
        return await aicoreBackendPetition('GET', `Translator/list_element_types?id_idioma=${language_id}`, params,{nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Translator/list_element_types');
    }
}

/*CARGA LOS ELEMENTOS TRADUCIDOS QUE COINCIDEN CON EL TIPO unit_type*/
export async function translatedContentByTypeWS(language_id, unit_type) {
    let params = {}

    try {
        return await aicoreBackendPetition('GET', `Translator/get_codes_by_type?id_idioma=${language_id}&tipo_unidad=${unit_type}`, params,{nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Translator/get_codes_by_type');
    }
}


/*CARGA EL CONTENIDO DEL ELEMENTO SELECCIONADO*/
export async function getElementChildWS(language_id, unit_type, element_id) {
    let params = {}

    try {
        return await aicoreBackendPetition('GET', `Translator/get_child_elements?id_idioma=${language_id}&tipo_unidad=${unit_type}&codigo_unidad=${element_id}`, params,{nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Translator/get_child_elements');
    }
}

/*CARGA EL CONTENIDO DEL ELEMENTO SELECCIONADO*/
export async function getHTMLContentWS(language_id, unit_type, element_id) {
    let params = {}

    try {
        return await aicoreBackendPetition('GET', `Translator/get_content?id_idioma=${language_id}&tipo_unidad=${unit_type}&codigo_unidad=${element_id}`, params,{nlp: true})
    } catch (e) {
        showNotification('error', process.env.VUE_APP_ERROR_SERVER + 'Translator/get_content');
    }
}
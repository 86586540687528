<template>
    <v-container>
        <v-row>
            <v-col cols="10">
                <h1> Categorización Manual de Incidencias</h1>
            </v-col>
            <v-col cols="2">
                <h1> {{ inserted }} / {{ limit }}</h1>
            </v-col>
        </v-row>
        <div class="text-center">
            <img class="loading-gif-m" v-if="loading" :src="require('@/assets/img/loading.gif')"
                 alt="Loading">
        </div>

        <div v-if="!loading&&key_now">
            <div class="container-lg">
                <div>
                    <p> Estas son incidencias sin clasificación manual. <br>
                        En la primera columna se recoge el asunto y observación de la incidencia. En la segunda se encuentra
                        la categoria
                        actual de la incidencia en la base de datos. En la última la predicción del categorizador trivial
                        que sirve de filtro
                        para que se clasifiquen, más o menos, diferentes tipos</p>
                </div>

                <table class="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">Asunto</th>
                        <th scope="col">Categoria actual</th>
                        <th scope="col">Categoria regular</th>
                    </tr>
                    </thead>
                    <tbody class="align-middle">
                    <tr>
                        <td>{{ incidences['asunto_incidencia'][key_now] }}</td>
                        <td>{{ incidences['categoria_incidencia'][key_now] }}</td>
                        <td>{{ incidences['categoria_regular'][key_now] }}</td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <p> Conforme seleccione una opción será guardado el resultado en nuestra base de datos y le saldrá una
                        nueva incidencia a clasificar </p>
                </div>
                <div style="display:flex;width:100%;flex-direction:row;justify-content: space-evenly;flex-wrap:wrap">
                    <form class="form-check form-check-inline mt-2 align-middle" v-for="cat in categories">
                        <input class='form-check-input' type="radio" :id="cat" :value="cat" v-model="picked"
                               @click="uncheck(cat)">
                        <label class='form-check-label' :for="cat">{{ cat }}</label>
                    </form>
                </div>
            </div>
        </div>
        <div class="text-center" v-else>
            <h5> Gracias por tu tiempo! </h5>
        </div>
    </v-container>
</template>

<script>

import {onBeforeMount, watch, ref, computed} from "vue";
import {useStore} from "vuex";

export default {
    name: "IncidencesManualCategorizationView",
    setup() {
        const store = useStore();

        let incidences = ref({});
        let key_now = ref('');
        let inserted = ref(0);
        let categories = ref([]);
        let loading = ref(true);
        let picked = ref('');
        let limit = ref(25);
        let aicore_token = computed(() => store.getters.getToken).value
        let username = computed(() => store.getters.getUsername).value

        const getUncategorizedIncidence = () => {
            loading.value = true
            $.ajax({
                type: 'GET',
                async: false,
                url: process.env.VUE_APP_AICORE_URL_API + 'incidenciasCategorizar/get_non_labelled?user_id=' +
                    username.value +
                    '&limit=' +
                    limit.value,
                headers: {
                    'Authorization': 'Bearer ' + aicore_token
                },
                success: function (response) {
                    try {
                        incidences.value = JSON.parse(response['data'])
                    } catch (e) {
                        inserted.value = limit.value;
                        key_now.value = null;
                    }
                    loading.value = false
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr);
                    console.log('thrownError: ' + thrownError);
                    loading.value = true
                }
            })
            try {
                key_now.value = Object.keys(incidences.value['id_incidencia'])[0];
            } catch (e) {
                key_now.value = null;
            }
        };

        const withoutProperty = (obj, property) => {
            const {[property]: unused, ...rest} = obj;

            return rest;
        }

        const insertCategorizedIncidence = () => {
            $.ajax({
                type: 'GET',
                url: process.env.VUE_APP_AICORE_URL_API + 'incidenciasCategorizar/set_manual_label?id=' +
                    incidences.value['id_incidencia'][key_now.value] +
                    '&nombre=' +
                    username.value +
                    '&categoria='
                    + picked.value,
                headers: {
                    'Authorization': 'Bearer ' + aicore_token
                },
                success: function (response) {
                    inserted.value += 1
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr);
                    console.log('thrownError: ' + thrownError);
                }
            })
            try {
                incidences.value.id_incidencia = withoutProperty(incidences.value.id_incidencia, key_now.value)
                incidences.value.asunto_incidencia = withoutProperty(incidences.value.asunto_incidencia, key_now.value)
                incidences.value.fecha_incidencia = withoutProperty(incidences.value.fecha_incidencia, key_now.value)
                incidences.value.categoria_regular = withoutProperty(incidences.value.categoria_regular, key_now.value)
                incidences.value.categoria_incidencia = withoutProperty(incidences.value.categoria_incidencia, key_now.value)
                key_now.value = Object.keys(incidences.value['id_incidencia'])[0];
            } catch (e) {
                key_now.value = null;
            }
        };

        const getCategories = () => {
            loading.value = true
            $.ajax({
                type: 'GET',
                url: process.env.VUE_APP_AICORE_URL_API + 'incidencias/get_all_classes',
                headers: {
                    'Authorization': 'Bearer ' + aicore_token
                },
                success: function (response) {
                    response.forEach((value, index) => {
                        categories.value.push(value)
                    })
                    loading.value = false
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr);
                    console.log('thrownError: ' + thrownError);
                    loading.value = true
                }
            })
        }

        const uncheck = (val) => {
            picked.value = val;
            insertCategorizedIncidence();
            picked.value = false;
            let inputs = document.querySelectorAll('.form-check-input')
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].checked = false;
            }
        }

        onBeforeMount(() => {
            getUncategorizedIncidence()
            getCategories()
            try {
                inserted.value = Object.values(incidences.value.id_incidencia).length
                inserted.value = limit.value - inserted.value
            } catch (e) {
                key_now.value = null;
            }
        });

        return {
            incidences,
            inserted,
            key_now,
            categories,
            picked,
            limit,
            loading,
            getUncategorizedIncidence,
            withoutProperty,
            insertCategorizedIncidence,
            getCategories,
            uncheck
        }
    }
}
</script>
<style lang="scss">

</style>

